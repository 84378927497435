import { EXPORT_FILE, EXPORT_FILE_ERROR, EXPORT_FILE_SUCCESS, RESET_EXPORT } from "./actionType";

export function fetchExportFile(data) {
    return {
        type: EXPORT_FILE,
        payload: data
    }
}
export function fetchExportFileSuccess(success) {
    return {
        type: EXPORT_FILE_SUCCESS,
        payload: success
    }
}
export function fetchExportFileError(error) {
    return {
        type: EXPORT_FILE_ERROR,
        payload: error
    }
}
export function resetExport() {
    return {
        type: RESET_EXPORT
    }
}
