import React from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import './style.css';
import './reponsive.css';
import { userSideDown, userSideUp, adminRoute, userSideUpLogin } from '../constant/step-component';
import { connect } from "react-redux";
import { changeRoute, changeTitle, closeSideBar } from "../redux/action/nextStepAction";
import { Utils } from "../config/utils";

const SIDEBAR_LOGO = require('../assets/images/sidebar/sidevar_logo.png');
const SIDEBAR_SMALL_LOGO = require('../assets/images/sidebar/logo_small.png');

class Sidebar extends React.Component {


    async changeRouteSidebar(side) {
        let { history, currentStep } = this.props;
        let sideRedirect = await userSideUp.filter(item => item.step === currentStep)[0];
        if (side.step > currentStep) {
            history.push(sideRedirect.currentRoute);
            this.props.changeTitle(sideRedirect.currentTitle);
        }
        else {
            history.push(side.currentRoute);
            this.props.changeTitle(side.currentTitle);
        }
    }

    closeSidebar = (e, route) => {
        // e.preventDefault();
        const {history} = this.props;
        let viewWidth = window.innerWidth;
        if (viewWidth < 992) {
            this.props.closeSidebar();
        }
        if(route === "/login") {
            Utils.logOut(history)
        } else {
            history.push(route)
        }
    }

    render() {
        const { isLogin, location, isFullSideBar, onEstimate } = this.props;
        const { pathname } = location;
        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <div className="sidebar">
                    <div className="sidebar-inner">
                        <div className="sidebar-logo bg-blue">
                            <img src={SIDEBAR_LOGO} alt=""
                                className="logo-full  d-lg-inline-block" />
                            <img src={SIDEBAR_SMALL_LOGO} alt="" className="logo-mobile d-lg-none" />
                            <span className="fas fa-arrow-circle-left color-white fs-20 d-none d-lg-none" />
                        </div>
                        {!this.props.isAdmin ?
                            <ul className="sidebar-menu nav flex-column">
                                {
                                    isLogin ?
                                        userSideUpLogin.map((side, index) => (
                                            index === 0 ? (
                                                // onEstimate === true ? (
                                                //     <div
                                                //         className={`${side.classNameLi} ${side.currentRoute === pathname && 'active'}`} key={index}>
                                                //         <div className={'sidebar-link fs-14 f_regular content-center'}>
                                                //             <span className={side.classNameSpan} />
                                                //             <span className="title color-dark-sidebar  d-lg-inline-block">{`sidebar.${side.currentTitle}`.t()}</span>
                                                //         </div>
                                                //     </div>
                                                // ) : (
                                                //         <a href={side.currentRoute} onClick={(e) => this.closeSidebar(e, side.currentRoute)}
                                                //             className={`${side.classNameLi} ${side.currentRoute === pathname && 'active'}`} key={index}>
                                                //             <div className={'sidebar-link fs-14 f_regular content-center'}>
                                                //                 <span className={side.classNameSpan} />
                                                //                 <span className="title color-dark-sidebar  d-xs-inline-block d-lg-inline-block">{`sidebar.${side.currentTitle}`.t()}</span>
                                                //             </div>
                                                //         </a>
                                                //     )
                                                <a href={side.currentRoute} onClick={(e) => this.closeSidebar(e, side.currentRoute)}
                                                    className={`${side.classNameLi} ${side.currentRoute === pathname && 'active'}`} key={index}>
                                                    <div className={'sidebar-link fs-14 f_regular content-center'}>
                                                        <span className={side.classNameSpan} />
                                                        <span className="title color-dark-sidebar  d-xs-inline-block d-lg-inline-block">{`sidebar.${side.currentTitle}`.t()}</span>
                                                    </div>
                                                </a>
                                            ) :
                                                <div
                                                    className={`${side.classNameLi} ${side.currentRoute === pathname && 'active'}`} key={index}>
                                                    <div className={'sidebar-link fs-14 f_regular content-center'}>
                                                        <span className={side.classNameSpan} />
                                                        <span className="title color-dark-sidebar  d-lg-inline-block">{`sidebar.${side.currentTitle}`.t()}</span>
                                                    </div>
                                                </div>
                                        ))
                                        :
                                        userSideUp.map((side, index) => (
                                            
                                            (index === 0 || side.currentRoute === "/login") ? (
                                                <a href={side.currentRoute} 
                                                    onClick={(e) => this.closeSidebar(e, side.currentRoute)}
                                                    className={`${side.classNameLi} ${side.currentRoute === pathname && 'active'}`} key={index}>
                                                    <div className={'sidebar-link fs-14 f_regular content-center'}>
                                                        <span className={side.classNameSpan} />
                                                        <span className="title color-dark-sidebar  d-lg-inline-block">{`sidebar.${side.currentTitle}`.t()}</span>
                                                    </div>
                                                </a>
                                            ) :
                                                <div
                                                    className={`${side.classNameLi} ${side.currentRoute === pathname && 'active'}`} key={index}>
                                                    <div className={'sidebar-link fs-14 f_regular content-center'}>
                                                        <span className={side.classNameSpan} />
                                                        <span className="title color-dark-sidebar  d-lg-inline-block">{`sidebar.${side.currentTitle}`.t()}</span>
                                                    </div>
                                                </div>
                                        ))
                                }
                                {
                                    isLogin &&
                                    <div className={'sidebar-menu setting-title'}>
                                        <div className={'nav-item sidebar-link fs-14 f_regular content-center settingbackground '}>
                                            <div className="sidebar-link fs-14 f_regular content-center">
                                                <i className="icon-holder icon-setting" />
                                                <span
                                                    className="title color-dark-sidebar  d-lg-inline-block setting">{'sidebar.setting'.t()}</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {isLogin && userSideDown.map((side, index) => (
                                    <Link to={side.currentRoute} onClick={(e) => this.closeSidebar(e, side.currentRoute)}
                                        className={`${side.classNameLi} ${side.currentRoute === pathname && 'active'}`} key={index}>
                                        <div className={'sidebar-link fs-14 f_regular content-center'}>
                                            <span className={side.classNameSpan} />
                                            <span className="title color-dark-sidebar  d-lg-inline-block">{`sidebar.${side.currentTitle}`.t()}</span>
                                        </div>
                                    </Link>
                                ))}
                            </ul>
                            :
                            <div className="sidebar-menu sidebar-admin nav flex-column">
                                <div
                                    className={`nav-item administrator content-center `}>
                                    <a className={'sidebar-link fs-14 f_regular content-center  '} >
                                        <span className={`icon-holder admin`} />
                                        <span className="title  d-lg-inline-block" style={{ color: '#fff' }}>Administrator</span>
                                    </a>
                                </div>
                                {adminRoute.map((side, index) => (
                                    <Link to={side.currentRoute} onClick={(e) => this.closeSidebar(e, side.currentRoute)}
                                        className={`${side.classNameLi} ${side.currentRoute === pathname && 'active'}`} key={index}>
                                        <a className={'sidebar-link fs-14 f_regular content-center '}>
                                            <span className={side.classNameSpan} />
                                            <span className="title color-dark-sidebar  d-lg-inline-block">{`sidebar.${side.currentTitle}`.t()}</span>
                                        </a>
                                    </Link>
                                ))}
                            </div>
                        }
                    </div>
                </div >
            </div>
        );
    }
}
const mapStateToProps = state => ({
    title: state.NextStepReducer.title,
    route: state.NextStepReducer.route,
    currentStep: state.NextStepReducer.currentStep,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    isAdmin: state.LoginReducer.isAdmin,
    isLogin: state.LoginReducer.isLogin,
    language: state.NextStepReducer.language,
    isLoadingNextStep: state.NextStepReducer.isLoadingNextStep,
    onEstimate: state.NextStepReducer.onEstimate

});
const mapDispatchToProps = dispatch => ({
    changeRoute: (route) => dispatch(changeRoute(route)),
    changeTitle: (title) => dispatch(changeTitle(title)),
    closeSidebar: () => dispatch(closeSideBar()),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sidebar))
