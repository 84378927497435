import { httpService } from "../../services/httpService";
import { API } from "../../services/APIs";
import * as responseCode from '../../constant/response-code';
import { put, takeLatest } from 'redux-saga/effects';
import { fetchExportFileError, fetchExportFileSuccess } from "./action";
import { EXPORT_FILE } from "./actionType";
import { CONST } from "../../config/const";

const BASE_URL_PROD = 'https://science-cloud.tech/api/';
// const BASE_URL_DEV = 'http://hpcs-api.arrowhitech.net/';
const BASE_URL_DEV = 'https://science-cloud.tech/api/';


const BASE_URL = process.env.NODE_ENV !== "production" ? BASE_URL_DEV : BASE_URL_PROD ;

function* exportFile(action) {
    try {
        const response = yield httpService.get(API.EXPORT + "?table_name=" + action.payload.table_name);
        yield window.location.href = BASE_URL + response.data.url_download;
        if (response.code === 200) {
            if (response.status === responseCode.SUCCESS) {
                yield put(fetchExportFileSuccess(response));
            } else {
                yield put(fetchExportFileError(response.message));
            }
        } else {
            yield put(fetchExportFileError(response.message));
        }
    } catch (e) {
        console.log({ e })
        yield put(fetchExportFileError(e))
    }
}

export function* watchExport() {
    yield takeLatest(EXPORT_FILE, exportFile)
}
