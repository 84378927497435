import {
    FETCH_UPDATE_ESTIMATE_ERROR,
    FETCH_UPDATE_ESTIMATE_SUCCESS,
    FETCH_UPDATE_ESTIMATE,
    CHANGE_TITLE,
    CHANGE_ROUTE,
    NEXT_STEP_ERROR,
    NEXT_STEP_SUCCESS,
    CHANGE_LANGUAGE,
    LOADING_NEXT_STEP,
    TOGGLE_IS_FULL_SIDEBAR,
    GET_LANGUAGE,
    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_ERROR,
    UPDATE_ON_ESTIMATE,
    CLOSE_SIDE_BAR,
    CLEAR_ESTIMATE
} from '../actionType/nextStepActionType';
import { CONST } from "../../config/const";

const DEFAULT_STATE = {
    data_update_estimate: {
        estimate_no: "",
        start_date: "",
        month: "",
        remarks: "",
        purpose_of_use: "",
        delete_flag: null,
        items: []
    },
    isLoadingNextStep: false,
    onEstimate: false,
    currentStep: 1,
    isFullSideBar: true,
    title: '',
    route: '/',
    language: '',
    languages: [],
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_UPDATE_ESTIMATE:
            return {
                ...state,
                data_update_estimate: action.payload.data
            };
        case FETCH_UPDATE_ESTIMATE_SUCCESS:
            return {
                ...state,
                data_update_estimate: { ...state.data_update_estimate, estimate_no: action.payload.estimate_no }
            };
        case FETCH_UPDATE_ESTIMATE_ERROR:
            return {
                ...state,
            };
        case CHANGE_TITLE:
            let text = action.payload.toLowerCase()
                .split(' ')
                .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                .join(' ');
            document.title = "HPCS - " + text;
            return {
                ...state,
                title: action.payload
            };
        case CHANGE_ROUTE:
            return {
                ...state,
                route: action.payload
            };
        case NEXT_STEP_SUCCESS:
            return {
                ...state,
                currentStep: state.currentStep + 1
            };
        case NEXT_STEP_ERROR:
            return {
                ...state
            };
        case CHANGE_LANGUAGE:
            return {
                ...state,
                language: action.payload
            };
        case GET_LANGUAGE:
            return {
                ...state,
            }
        case GET_LANGUAGE_SUCCESS:
            return {
                ...state,
                languages: action.payload,
                language: action.payload[0].value_code
            }
        case GET_LANGUAGE_ERROR:
            return {
                ...state,
                // languages: action.payload,
            }
        case LOADING_NEXT_STEP:
            return {
                ...state,
                isLoadingNextStep: action.payload
            }
        case TOGGLE_IS_FULL_SIDEBAR:
            return {
                ...state,
                isFullSideBar: !state.isFullSideBar
            }
        case UPDATE_ON_ESTIMATE:
            return {
                ...state,
                onEstimate: action.payload
            }
        case CLOSE_SIDE_BAR:
            return {
                ...state,
                isFullSideBar: true
            }
        case CLEAR_ESTIMATE: 
            return {
                ...state,
                data_update_estimate: {
                    estimate_no: "",
                    start_date: "",
                    month: "",
                    remarks: "",
                    purpose_of_use: "",
                    delete_flag: null,
                    items: []
                },
                isLoadingNextStep: false,
                onEstimate: false,
                currentStep: 1,
            }
        default:
            return state;
    }
}
