import { connect } from "react-redux";
import UseApplication from "./UseApplication";
import {
    fetchClassification,
    fetchSoftware,
    fetchSoftwareVersion,
    nextStepUseApplication,
    clearListSoftwareVersion,
    changeClassification,
    changeProcessor,
    addSoftwareActive,
    removeSoftwareActive,
    updateSearchKeyWord,
    updateSoftwareVersion,
    loadingListSoftware
} from './action';
import { fetchUpdateEstimate } from '../../redux/action/nextStepAction';

const mapStateToProps = state => ({
    classification_data: state.UseApplicationReducer.classification_data,
    softwares: state.UseApplicationReducer.softwares,
    isLoading: state.UseApplicationReducer.isLoading,
    softwareVersion: state.UseApplicationReducer.softwareVersion,
    data_update_estimate: state.NextStepReducer.data_update_estimate,
    estimate_no: state.NextStepReducer.data_update_estimate.estimate_no,
    listActiveSoftware: state.UseApplicationReducer.listActiveSoftware,
    listSoftwareVersion: state.UseApplicationReducer.listSoftwareVersion,
    listActiveSoftwareCode: state.UseApplicationReducer.listActiveSoftwareCode,
    classificationActive: state.UseApplicationReducer.classificationActive,
    searchForm: state.UseApplicationReducer.searchForm,
    currentStep: state.NextStepReducer.currentStep,
    isLoadingListSoftware: state.UseApplicationReducer.isLoadingListSoftware,
    language: state.NextStepReducer.language,
    isFullSideBar: state.NextStepReducer.isFullSideBar
})

const mapDispatchToProps = dispatch => ({
    getClassification: (type, processor, language, key_word, classification_code) => dispatch(fetchClassification(type, processor, language, key_word, classification_code)),
    getSoftware: (data) => dispatch(fetchSoftware(data)),
    getSoftwareVersion: (softwareName) => dispatch(fetchSoftwareVersion(softwareName)),
    nextStepUseApplication: (data, title, route, history, isBack) => dispatch(fetchUpdateEstimate(data, title, route, history, isBack)),
    changeClassification: (index) => dispatch(changeClassification(index)),
    changeProcessor: (processor) => dispatch(changeProcessor(processor)),
    saveListSoftwareActive: (data) => dispatch(nextStepUseApplication(data)),
    addSoftwareActive: (data) => dispatch(addSoftwareActive(data)),
    removeSoftwareActive: (data) => dispatch(removeSoftwareActive(data)),
    updateSearchKeyword: (keyword) => dispatch(updateSearchKeyWord(keyword)),
    clearListSoftwareVersion: (data) => dispatch(clearListSoftwareVersion(data)),
    updateSoftwareVersion: (softwareUpdate) => dispatch(updateSoftwareVersion(softwareUpdate)),
    loadingListSoftware: (isLoading) => dispatch(loadingListSoftware(isLoading))
})
export default connect(mapStateToProps, mapDispatchToProps)(UseApplication)
