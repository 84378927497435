import { connect } from "react-redux";
import Estimate from "./Estimate";
import { fetchEstimate, updateEstimateFile, updateEstimateFileStatus, getPdfInfo } from './action';
import { changeRoute, changeTitle, clearEstimate } from '../../redux/action/nextStepAction';

const mapStateToProps = state => ({
    estimateData: state.EstimateReducer.estimateData,
    estimate_no: state.NextStepReducer.data_update_estimate.estimate_no,
    currentStep: state.NextStepReducer.currentStep,
    update_estimate_file_success: state.EstimateReducer.update_estimate_file_success,
    isLoadingEstimate: state.EstimateReducer.isLoadingEstimate,
    updateEstimateFileStatus: state.EstimateReducer.update_estiamte_file_status,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    success: state.EstimateReducer.success,
    account_detail: state.AccountDetailReducer.account_detail,
    data_confirm: state.RequestAccountReducer.data_confirm,
    pdfInfo: state.EstimateReducer.pdfInfo
})

const mapDispatchToProps = dispatch => ({
    onGetEstimate: (estimateNo) => dispatch(fetchEstimate(estimateNo)),
    onChangeTitle: (title) => dispatch(changeTitle(title)),
    onChangeRoute: (route) => dispatch(changeRoute(route)),
    updateEstimateFile: (data, history) => dispatch(updateEstimateFile(data, history)),
    getPdfInfo: (estimateNo) => dispatch(getPdfInfo(estimateNo)),
    closeModal: () => dispatch(updateEstimateFileStatus(null)),
    clearEstimate: () => dispatch(clearEstimate())
})
export default connect(mapStateToProps, mapDispatchToProps)(Estimate)