export const CONST = {
    LANGUAGE: 'LANGUAGE',
    TOKEN: 'TOKEN',
    I18N_LANGUAGE: [
        { language: 'English', value: 'en', icon: require('../assets/images/sidebar/icon_translate.png') },
        { language: 'Japanese', value: 'jp', icon: require('../assets/images/sidebar/japan.png') }
    ],
    USER_CODE: 'USER_CODE',
    USER_TYPE: 'USER_TYPE',
    USER_NAME: 'USER_NAME',
    USER_NAME_JP: 'USER_NAME_JP',
    ADMIN: '1',
    USER: '0',
    STATUS_ESTIMATE_SUCCESS: 'STATUS_ESTIMATE_SUCCESS',
    STATUS_ESTIMATE_ERROR: 'STATUS_ESTIMATE_ERROR',
    TIMEOUT: 5000
};
