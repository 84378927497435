import { FETCH_IMPORT, FETCH_IMPORT_ERROR, FETCH_IMPORT_SUCCESS, RESET_REDUCER_IMPORT } from "./actionType";

const DEFAULT_STATE = {
    error_file: [],
    error: '',
    status: null
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_IMPORT:
            return {
                ...state
            };
        case FETCH_IMPORT_SUCCESS:
            return {
                ...state,
                error_file: action.payload,
                status: action.payload.status
            };
        case FETCH_IMPORT_ERROR:
            return {
                ...state,
                error: action.payload,
                error_file: action.data
            };
        case RESET_REDUCER_IMPORT:
            return {
                ...state,
                error_file: [],
                status: ""
            }
        default:
            return state;
    }
}
