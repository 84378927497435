import {
    CHANGE_ROUTE,
    CHANGE_TITLE,
    FETCH_UPDATE_ESTIMATE,
    FETCH_UPDATE_ESTIMATE_SUCCESS,
    FETCH_UPDATE_ESTIMATE_ERROR,
    NEXT_STEP_SUCCESS,
    NEXT_STEP_ERROR,
    CHANGE_LANGUAGE,
    GET_LANGUAGE,
    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_ERROR,
    LOADING_NEXT_STEP,
    TOGGLE_IS_FULL_SIDEBAR,
    UPDATE_ON_ESTIMATE,
    CLOSE_SIDE_BAR,
    CLEAR_ESTIMATE
} from "../actionType/nextStepActionType";
import Alert from 'react-s-alert';

export function fetchUpdateEstimate(data, title, route, history, isBack) {
    return {
        type: FETCH_UPDATE_ESTIMATE,
        payload: {
            data,
            title,
            route,
            history,
            isBack
        }
    }
}
export function fetchUpdateEstimateSuccess(success) {
    return {
        type: FETCH_UPDATE_ESTIMATE_SUCCESS,
        payload: success
    }
}

export function fetchUpdateEstimateError(error) {
    Alert.warning(error, {
        position: 'top-right',
        effect: 'scale',
        timeout: require("../../config/const").TIMEOUT,
        offset: -10
    })
    return {
        type: FETCH_UPDATE_ESTIMATE_ERROR,
        payload: error,

    }
}

export function nextStepSuccess(currentStepComponent) {
    return {
        type: NEXT_STEP_SUCCESS,
        payload: currentStepComponent
    }
}

export function nextStepError(currentStepComponent) {
    return {
        type: NEXT_STEP_ERROR,
        payload: currentStepComponent
    }
}

export function changeTitle(title) {
    return {
        type: CHANGE_TITLE,
        payload: title
    }
}
export function changeRoute(route) {
    return {
        type: CHANGE_ROUTE,
        payload: route
    }
}

export function changeLanguage(language) {
    return {
        type: CHANGE_LANGUAGE,
        payload: language
    }
}

export function getLanguage() {
    return {
        type: GET_LANGUAGE,
    }
}

export function getLanguageError(languages) {
    return {
        type: GET_LANGUAGE_ERROR,
        payload: languages
    }
}
export function getLanguageSuccess(languages) {
    return {
        type: GET_LANGUAGE_SUCCESS,
        payload: languages
    }
}

export function changeLoadingNextStep(isLoading) {
    return {
        type: LOADING_NEXT_STEP,
        payload: isLoading
    }
}

export function toggleIsFullSidebar() {
    return {
        type: TOGGLE_IS_FULL_SIDEBAR
    }
}

export function updateOnEstimate(onEstimate) {
    return {
        type: UPDATE_ON_ESTIMATE,
        payload: onEstimate
    }
}

export function closeSideBar() {
    return {
        type: CLOSE_SIDE_BAR
    }
}

export function clearEstimate() {
    console.log("CLEARRRRRRRRRRRRRRR");
    return {
        type: CLEAR_ESTIMATE
    }
}