import { EXPORT_FILE_SUCCESS, EXPORT_FILE, EXPORT_FILE_ERROR, RESET_EXPORT } from "./actionType";

const DEFAULT_STATE = {
    data: null,
    disableLog: true,
    message_error: null,
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case EXPORT_FILE_SUCCESS:
            return {
                ...state,
                data: action.payload,

            };
        case EXPORT_FILE_ERROR:
            return {
                ...state,
                disableLog: false,
                message_error: action.payload
            }
        case RESET_EXPORT:
            return {
                ...state,
                data: null,
            }
        default:
            return state;
    }
}
