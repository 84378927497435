const FETCH_UPDATE_ESTIMATE = 'FETCH_UPDATE_ESTIMATE';
const FETCH_UPDATE_ESTIMATE_SUCCESS = 'FETCH_UPDATE_ESTIMATE_SUCCESS';
const FETCH_UPDATE_ESTIMATE_ERROR = 'FETCH_UPDATE_ESTIMATE_ERROR';

const CHANGE_TITLE = 'CHANGE_TITLE';
const CHANGE_ROUTE = 'CHANGE_ROUTE';

const NEXT_STEP_SUCCESS = 'NEXT_STEP_SUCCESS';
const NEXT_STEP_ERROR = 'NEXT_STEP_ERROR';

const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
const GET_LANGUAGE = "GET_LANGUAGE"
const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS"
const GET_LANGUAGE_ERROR = "GET_LANGUAGE_ERROR"
const LOADING_NEXT_STEP = 'LOADING_NEXT_STEP';

const TOGGLE_IS_FULL_SIDEBAR = 'TOGGLE_IS_FULL_SIDEBAR';

const UPDATE_ON_ESTIMATE = 'UPDATE_ON_ESTIMATE';

const CLOSE_SIDE_BAR = 'CLOSE_SIDE_BAR';

const CLEAR_ESTIMATE = 'CLEAR_ESTIMATE';

export {
    FETCH_UPDATE_ESTIMATE, FETCH_UPDATE_ESTIMATE_ERROR, FETCH_UPDATE_ESTIMATE_SUCCESS,
    CHANGE_TITLE, CHANGE_ROUTE,
    NEXT_STEP_SUCCESS, NEXT_STEP_ERROR,
    CHANGE_LANGUAGE,
    GET_LANGUAGE,
    GET_LANGUAGE_SUCCESS,
    GET_LANGUAGE_ERROR,
    LOADING_NEXT_STEP,
    TOGGLE_IS_FULL_SIDEBAR,
    UPDATE_ON_ESTIMATE,
    CLOSE_SIDE_BAR,
    CLEAR_ESTIMATE
}
