const FETCHING = 'FETCHING';
const FETCHING_VERSION = 'FETCHING_VERSION';

const FETCH_CLASSIFICATION = 'FETCH_CLASSIFICATION';
const FETCH_CLASSIFICATION_SUCCESS = 'FETCH_CLASSIFICATION_SUCCESS';
const FETCH_CLASSIFICATION_ERROR = 'FETCH_CLASSIFICATION_ERROR';

const FETCH_SOFTWARE = 'FETCH_SOFTWARE';
const FETCH_SOFTWARE_SUCCESS = 'FETCH_SOFTWARE_SUCCESS';
const FETCH_SOFTWARE_ERROR = 'FETCH_SOFTWARE_ERROR';

const FETCH_SOFTWARE_VERSION = 'FETCH_SOFTWARE_VERSION';
const FETCH_SOFTWARE_VERSION_SUCCESS = 'FETCH_SOFTWARE_VERSION_SUCCESS';
const FETCH_SOFTWARE_VERSION_ERROR = 'FETCH_SOFTWARE_VERSION_ERROR';

const CHANGE_CLASSIFICATION = 'CHANGE_CLASSIFICATION';
const CHANGE_PROCESSOR = 'CHANGE_PROCESSOR';
const ADD_SOFTWARE_ACTIVE = 'ADD_SOFTWARE_ACTIVE';
const REMOVE_SOFTWARE_ACTIVE = 'REMOVE_SOFTWARE_ACTIVE';
const UPDATE_SEARCH_KEYWORD_SOFTWARE = 'UPDATE_SEARCH_KEYWORD_SOFTWARE';
const UPDATE_SOFTWARE_VERSION = 'UPDATE_SOFTWARE_VERSION';

const NEXT_STEP_USE_APPLICATION = 'NEXT_STEP_USE_APPLICATION';

const CLEAR_LIST_SOFTWARE_VERSION = 'CLEAR_LIST_SOFTWARE_VERSION';
const CLEAR_LIST_SOFTWARE_VERSION_SUCCESS = 'CLEAR_LIST_SOFTWARE_VERSION_SUCCESS';
const LOADING_LIST_SOFTWARE = 'LOADING_LIST_SOFTWARE';

export {
    FETCHING,
    FETCHING_VERSION,
    FETCH_CLASSIFICATION,
    FETCH_CLASSIFICATION_SUCCESS,
    FETCH_CLASSIFICATION_ERROR,
    FETCH_SOFTWARE,
    FETCH_SOFTWARE_SUCCESS,
    FETCH_SOFTWARE_ERROR,
    FETCH_SOFTWARE_VERSION,
    FETCH_SOFTWARE_VERSION_SUCCESS,
    FETCH_SOFTWARE_VERSION_ERROR,
    CHANGE_CLASSIFICATION,
    CHANGE_PROCESSOR,
    NEXT_STEP_USE_APPLICATION,
    CLEAR_LIST_SOFTWARE_VERSION,
    ADD_SOFTWARE_ACTIVE,
    REMOVE_SOFTWARE_ACTIVE,
    UPDATE_SEARCH_KEYWORD_SOFTWARE,
    CLEAR_LIST_SOFTWARE_VERSION_SUCCESS,
    UPDATE_SOFTWARE_VERSION,
    LOADING_LIST_SOFTWARE
}