import { CLEAR_ESTIMATE } from '../../redux/actionType/nextStepActionType';
import {
    SEARCH_USER_SUCCESS,
    SEARCH_SOFTWARE_SUCCESS,
    SEARCH_HARDWARE_SUCCESS,
    SEARCH_SUPPORT_SUCCESS,
    UPDATE_SEARCH_FORM,
    UPDATE_USER_NAME, GET_ADMIN_USAGE_SUCCESS, UPDATE_ORDERS, RESET_SEARCH
} from './actionType';

const DEFAULT_STATE = {
    list_user: [],
    list_software: [],
    list_hardware: [],
    list_support: [],
    user_name: '',
    formSearch: {   
        "estimate_no": "",
        "first_name": "",
        "last_name": "",
        "software_code": "",
        "hardware_code": "",
        "support_code": "",
        "contracted_flag": "",
        "start_date": "",
        "end_date": "",
        "language": "GB",
        "name": "",
        "user_code": ""
    },
    // "page": 1,
    orders: []
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case SEARCH_USER_SUCCESS:
            return {
                ...state,
                list_user: action.payload
            }
        case SEARCH_SOFTWARE_SUCCESS:
            return {
                ...state,
                list_software: action.payload,
            }
        case SEARCH_HARDWARE_SUCCESS:
            return {
                ...state,
                list_hardware: action.payload
            }
        case SEARCH_SUPPORT_SUCCESS:
            return {
                ...state,
                list_support: action.payload
            }
        case UPDATE_SEARCH_FORM:
            return {
                ...state,
                formSearch: {
                    ...state.formSearch,
                    [action.payload.key]: action.payload.value
                }
            }
        case UPDATE_USER_NAME:
            return {
                ...state,
                user_name: action.payload
            }
        case GET_ADMIN_USAGE_SUCCESS:
            return {
                ...state,
                orders: action.payload
            }
        case UPDATE_ORDERS:
            const new_orders = [...state.orders];
            new_orders.forEach((e, i) => {
                if (i === action.payload.index) {
                    e.contracted_flag = action.payload.order.contracted_flag;
                }
            });
            return {
                ...state,
                orders: new_orders
            }
        case CLEAR_ESTIMATE:
            return {
                ...state,
                formSearch: {   
                    "estimate_no": "",
                    "first_name": "",
                    "last_name": "",
                    "software_code": "",
                    "hardware_code": "",
                    "support_code": "",
                    "contracted_flag": "",
                    "start_date": "",
                    "end_date": "",
                    "language": "GB",
                    "name": "",
                    "user_code": ""
                },
                orders: [],
                user_name: ''
            }
        case RESET_SEARCH: 
            return {
                ...state,
                formSearch: {   
                    "estimate_no": "",
                    "first_name": "",
                    "last_name": "",
                    "software_code": "",
                    "hardware_code": "",
                    "support_code": "",
                    "contracted_flag": "",
                    "start_date": "",
                    "end_date": "",
                    "language": "GB",
                    "name": "",
                    "user_code": ""
                },
                user_name: '',
                orders: []
            }
        default:
            return state;
    }
}
