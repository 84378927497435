import { connect } from "react-redux";
import { fetchCalculate, fetchListSupport, updateListSupport, updateEndMonth, updateStartDate, updatePurposeOfUse, resetIsNext, updateOrderDay } from "./action";
import ApplicationDetail from "./ApplicationDetail";
import { changeRoute, changeTitle, fetchUpdateEstimate } from '../../redux/action/nextStepAction';


const mapStateToProps = state => ({
    loading: state.ApplicationDetailReducer.loading,
    loading_price: state.ApplicationDetailReducer.loading_price,
    list_supports: state.ApplicationDetailReducer.list_supports,
    support_code: state.ApplicationDetailReducer.support_code,
    is_next: state.ApplicationDetailReducer.is_next,
    currentStep: state.NextStepReducer.currentStep,
    data_update_estimate: state.NextStepReducer.data_update_estimate,
    start_date: state.ApplicationDetailReducer.start_date,
    month: state.ApplicationDetailReducer.month,
    purpose_of_use: state.ApplicationDetailReducer.purpose_of_use,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    listSupportActive: state.ApplicationDetailReducer.list_supports_active
});

const mapDispatchToProps = dispatch => ({
    getListSupport: () => dispatch(fetchListSupport()),
    updateListSupport: (index) => dispatch(updateListSupport(index)),
    calculateSupportPrice: (data, index) => dispatch(fetchCalculate(data, index)),
    onChangeTitle: (title) => dispatch(changeTitle(title)),
    onChangeRoute: (route) => dispatch(changeRoute(route)),
    updateEstimate: (data, title, route, history, isBack) => dispatch(fetchUpdateEstimate(data, title, route, history, isBack)),
    updateEndMonth: (month) => dispatch(updateEndMonth(month)),
    updateStartDate: (date) => dispatch(updateStartDate(date)),
    updatePurposeOfUse: (text) => dispatch(updatePurposeOfUse(text)),
    resetIsNext: () => dispatch(resetIsNext()),
    updateOrderDay: (data) => dispatch(updateOrderDay(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetail)
