const FETCH_ACCOUNT_DETAIL = 'FETCH_ACCOUNT_DETAIL';
const FETCH_ACCOUNT_ERROR = 'FETCH_ACCOUNT_ERROR';
const FETCH_ACCOUNT_SUCCESS = 'FETCH_ACCOUNT_SUCCESS';

const POST_REQUEST_ACCOUNT_CONFIRM = 'POST_REQUEST_ACCOUNT_CONFIRM';
const POST_REQUEST_ACCOUNT_SUCCESS = 'POST_REQUEST_ACCOUNT_SUCCESS';
const POST_REQUEST_ACCOUNT_FAILED = 'POST_REQUEST_ACCOUNT_FAILED';

export {
    FETCH_ACCOUNT_DETAIL, FETCH_ACCOUNT_ERROR, FETCH_ACCOUNT_SUCCESS,
    POST_REQUEST_ACCOUNT_CONFIRM, POST_REQUEST_ACCOUNT_SUCCESS, POST_REQUEST_ACCOUNT_FAILED
}
