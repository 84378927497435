import React from 'react'
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'

const mapStateToProps = state => ({
    title: state.NextStepReducer.title,
    isAdmin: state.LoginReducer.isAdmin,
    isLogin: state.LoginReducer.isLogin,
    currentStep: state.NextStepReducer.currentStep,
    language: state.NextStepReducer.language,
    isLoadingNextStep: state.NextStepReducer.isLoadingNextStep,
});

const mapDispatchToProps = dispatch => ({
    // changeTitle: (title) => dispatch(changeTitle(title)),
    // changeRoute: (route) => dispatch(changeRoute(route)),
    // toggleIsFullSidebar: () => dispatch(toggleIsFullSidebar())
});

const AdminRoute = connect(mapStateToProps, mapDispatchToProps)(
    ({ component: Component, ...rest }) => {
        const redirect = (props) => {
            props.history.push('/login');
        };
        return (
            <Route
                {...rest}
                render={props => {
                    if (rest.isLogin && rest.isAdmin) {
                        return <Component {...props} />;
                    } else {
                        redirect(props)
                    }
                }}
            />
        )
    }
)

export default AdminRoute
