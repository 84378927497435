import { takeLatest, put } from 'redux-saga/effects';

import { FETCH_SUMMARY } from './actionType';
import {
    fetchSummarySuccess,
    fetchSummaryError,
    updateIsLoadingSummary
} from './action';
import { API } from '../../services/APIs';
import { httpService } from '../../services/httpService';
import * as responseCode from '../../constant/response-code';
import { changeLoadingNextStep } from '../../redux/action/nextStepAction';
import { CONST } from '../../config/const';

function* getSummary(action) {
    try {
        yield put(updateIsLoadingSummary(true));
        let language = localStorage.getItem(CONST.LANGUAGE);
        const url = API.GET_SUMMARY + `?estimate_no=${action.payload}&language=${language}`;
        // const res = yield httpService.getWithoutToken(url);
        const res = yield httpService.get(url);

        if (res.status === responseCode.SUCCESS) {
            yield put(fetchSummarySuccess(res.data));
        }
        if (res.code === responseCode.NOT_FOUND) {
            yield put(fetchSummaryError(res.message));
        }
        yield put(updateIsLoadingSummary(false))
    } catch (e) {
        yield put(updateIsLoadingSummary(false))
        console.log(e);
    }
}

export function* watchGetSummary() {
    yield takeLatest(FETCH_SUMMARY, getSummary)
}