import React, { Component } from 'react';
import './style.css'
import { Utils } from "../../config/utils";
import Alert from 'react-s-alert';
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { STEP_APPLICATION_DETAILS } from "../../constant/step-component";
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { CONST } from '../../config/const';
import debounce from 'lodash/debounce';
import { tr } from '../../config/utils'

const typeChangeOrderDay = {
    INPUT: 'INPUT',
    INCREMENT: 'INCREMENT',
    DECREASE: 'DECREASE'
}

const InputCount = ({item, handleChangeOrderDay}) => {

    const handleChangeInput = (type, e) => {
        e.preventDefault();
        console.log({value: e.target.value});
        const currentOrderDay = parseInt(item.days) || 0;
        if(type === typeChangeOrderDay.INPUT) {
            const regexNumber = /^[0-9]*$/;
            if( tr(e.target.value) !== "" && !regexNumber.test(parseInt(tr(e.target.value)))) {
                Alert.warning('notification.Please enter the number of days'.t(), {
                    position: 'top-right',
                    effect: 'scale',
                    timeout: require("../../config/const").TIMEOUT,
                    offset: -10
                });
                return;
            }
            handleChangeOrderDay({
                ...item, 
                days: parseInt(e.target.value) ? parseInt(e.target.value) : e.target.value,
                checked: currentOrderDay > 0,
                price: parseInt(item.defaultPrice) * parseInt(tr(e.target.value)),
            })
        }
        if(type === typeChangeOrderDay.INCREMENT) {
            handleChangeOrderDay({
                ...item, 
                checked: currentOrderDay + 1 > 0,
                days: currentOrderDay + 1 , 
                price: parseInt(item.defaultPrice) * (currentOrderDay + 1),
            })
        }
        if(type === typeChangeOrderDay.DECREASE) {
            handleChangeOrderDay({
                ...item, 
                checked: currentOrderDay - 1 > 0,
                days: currentOrderDay - 1, 
                price: parseInt(item.defaultPrice) * (currentOrderDay - 1),
            })
        }
    }

    return (
        <div className="d-flex justify-content-end align-items-center">
            <span className="pr-1">{Utils.formatCurrency(parseInt(item.price))} { !item.days && checkPrice(item.fixed_amount)}</span>
            <input 
                value={item.days} 
                className="mr-1" 
                style={{width: 50, padding: '0 5px'}} 
                type="number"
                step={"1"}
                onChange={(e) => handleChangeInput(typeChangeOrderDay.INPUT, e)}
            />
            <div className="increment d-flex flex-column">
                <button 
                    type="button"
                    className="pr-1 pl-1" 
                    style={{height: 22, marginBottom: 6}}
                    onClick={(e) => handleChangeInput(typeChangeOrderDay.INCREMENT, e)}
                >+</button>
                <button 
                    type="button"
                    className="pr-1 pl-1" 
                    style={{height: 22}}
                    onClick={(e) => handleChangeInput(typeChangeOrderDay.DECREASE, e)}
                >-</button>
            </div>
        </div>
    )
}


function checkPrice(fixed_amount) {
    switch (fixed_amount) {
        case '1':
            return '';
        case '2':
            return '/day'.t()
        case '3':
            return '/month'.t()
        case '4':
            return '/year'.t()
        default:
            return ''
    }
}

const type = {
    start_date: "start date",
    end_date: "end_date",
    support: "support",
    support_business_trip: "support_business_trip"
}

const Overview = ({overview}) => {

    const regexLink = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    
    if(!overview) {
        return null;
    }

    const overviewArr = overview.split(' ');
    var newWindowURI = '';

    const windowOpen = () => {
        window.open(newWindowURI,'_blank', 'location=yes,height=1080,width=720,scrollbars=yes,status=yes');
    }


    const checkRenderLink = (text, index) => {
        if(regexLink.test(text)) {
            console.log({text})
            // return <a key={index} href={text} target={'blank'}>{text}</a>
            newWindowURI = text;
            return <a onClick={windowOpen}  key={index}>{text}</a>
        } else {
            return <span key={index}>{text}</span>
        }
    }

    return (
        <span>
            {
                overviewArr.map((item, index) => (
                    checkRenderLink(item, index)
                ))
            }
        </span>
    );
}

const ListSupport = ({ list_supports, onChecked, loading_price, handleChangeOrderDay, handleCheckedBusinessTrip }) => {
    console.log({list_supports});
    if (!list_supports) return <div className="text-center">{'application details.Does not have any support'.t()}</div>
    return list_supports.map((item, index) => (
        <tr key={index} className={item.checked ? 'checked' : null}>
            <td className="custom-checkbox t-center col-select"
                data-th="Select:">
                <input 
                    type="checkbox" id={index.toString()} 
                    name={index.toString()} checked={item.checked} className="custom-control-input" 
                    // onChange={() => onChecked(index, type.support)} />
                    onChange={() => {item.fixed_amount === '2' ? handleCheckedBusinessTrip(index): onChecked(index, type.support)}} />
                <label className="custom-control-label" htmlFor={index.toString()}>&nbsp;</label>
            </td>
            <td className="col-support col-support-one" data-th="Support:">
                {item.classification_name}
            </td>
            <td className="col-support col-support-two">
                {item.support_name}
            </td>
            <td className="col-overview long-words" data-th="Overview:">
                {/* {item.support_overview} */}
                <Overview overview={item.support_overview}/>

            </td>
            <td className="t-right col-price" data-th="Price:">
                {item.fixed_amount === '2' ?
                    <InputCount item={item} handleChangeOrderDay={handleChangeOrderDay}/>
                    :
                    <span>{Utils.formatCurrency(parseInt(item.price))} {checkPrice(item.fixed_amount)}</span>
                }
            </td>
        </tr>
    ))
}

class ApplicationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            start_date: '',
            month: '',
            support_code: '',
            purpose_of_use: '',
        }
        // this.changeEndMonth = this.changeEndMonth.bind(this);
        // this.changeStartDate = this.changeStartDate.bind(this);
        this.handleEstimate = this.handleEstimate.bind(this);
    }


    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.props.getListSupport();
    }

    changeStartDate(e) {
        const { month } = this.props;
        if (month && e > month) {
            Alert.warning('notification.start date must be smaller than end month'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            })
        } else {
            this.props.updateStartDate(e);
        }
    }

    changeEndMonth(e) {
        const { start_date } = this.props;
        if (start_date && e < start_date) {
            Alert.warning('notification.start date must be smaller than end month'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            })
        } else {
            this.props.updateEndMonth(e);
        }
    }

    changePurposeOfUse = (e) => {
        this.props.updatePurposeOfUse(e.target.value);
    }

    prevStep = () => {
        this.props.history.push(STEP_APPLICATION_DETAILS.prevRoute);
        this.props.onChangeRoute(STEP_APPLICATION_DETAILS.prevRoute);
        this.props.onChangeTitle(STEP_APPLICATION_DETAILS.prevTitle);
    }

    handleCheckedBusinessTrip = (index) => {
        const { start_date } = this.props;
        if(Utils.getStartDate(start_date ? start_date.toString() : "") === "Invalid date") {
            Alert.warning('notification.please enter desired period'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            })
            return;
        }
        this.props.updateListSupport(index)
    }

    handleChangeOrderDay = (item) => {
        console.log({item});
        const { start_date } = this.props;
        if(Utils.getStartDate(start_date ? start_date.toString() : "") === "Invalid date") {
            Alert.warning('notification.please enter desired period'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            })
            return;
        };
        if(item.days <= 0) {
            this.props.updateOrderDay({
                ...item,
                days: '',
                price: item.defaultPrice,
                checked: false
            })
            // this.props.getListSupport();
        } else {
            this.props.updateOrderDay(item)
        }
    }

    nextStep = () => {
        let { data_update_estimate, list_supports, history, currentStep } = this.props;
        let { start_date, month, purpose_of_use } = this.props;
        start_date = Utils.getStartDate(start_date);
        // month = Utils.getEndMonth(month);
        const sp = list_supports.filter(e => e.checked);
        if(sp.find(item => item.fixed_amount === "2" && !item.days)) {
            Alert.warning('notification.Please enter the number of days'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            })
            return;
        }
        let isBack = currentStep > STEP_APPLICATION_DETAILS.step;
        sp.forEach(e => {
            e.product_category = 3;
            e.product_code = e.support_code;
            e.quantity = 1
        });
        const res = data_update_estimate.items.filter(e => e.product_category !== 3); // lấy ra mảng items không có list support.
        const data = {
            ...data_update_estimate,
            step: "3",
            items: res.concat(sp), // add support đã chọn.
            start_date,
            month,
            purpose_of_use
        };

        if (start_date === "Invalid date" || !start_date) {

            Alert.warning('notification.please enter desired period'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            })
        }else if( !month) {
            Alert.warning('notification.Please enter number of months'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            })
        } else {
            if (month <= 0) {
                Alert.warning('notification.Please enter a number greater than 0 in the number of months'.t(), {
                    position: 'top-right',
                    effect: 'scale',
                    timeout: require("../../config/const").TIMEOUT,
                    offset: -10
                })
            } else this.props.updateEstimate(data, STEP_APPLICATION_DETAILS.nextTitle, STEP_APPLICATION_DETAILS.nextRoute, history, isBack)
        }
    };

    handleEstimate(data, option) {
        let { start_date, month, support_code, list_supports } = this.props;
        let index;
        let dataPost;
        if (option === type.start_date) {
            // if (month && Utils.getStartDate(data) >= Utils.getEndDate(month)) {
            //     Alert.warning('notification.start date must be smaller than end month'.t(), {
            //         position: 'top-right',
            //         effect: 'scale',
            //         timeout: require("../../config/const").TIMEOUT,
            //         offset: -10
            //     });
            //     return;
            // } else {
            //     dataPost = { start_date: data, month, support_code }
            //     this.props.updateStartDate(data);
            // }
            dataPost = { start_date: data, month, support_code }
            this.props.updateStartDate(data);
        }
        else if (option === type.end_date) {
            // if (start_date && Utils.getEndDate(data) <= Utils.getStartDate(start_date)) {
            //     Alert.warning('notification.start date must be smaller than end month'.t(), {
            //         position: 'top-right',
            //         effect: 'scale',
            //         timeout: require("../../config/const").TIMEOUT,
            //         offset: -10
            //     });
            //     return;
            // } =
            if(parseInt(data.target.value) <= 0) {
                Alert.warning('notification.Please enter a number greater than 0 in the number of months'.t(), {
                    position: 'top-right',
                    effect: 'scale',
                    timeout: require("../../config/const").TIMEOUT,
                    offset: -10
                });
                return;
            }
            else {
                dataPost = {
                    start_date,
                    month: data.target.value,
                    support_code,
                }
                this.props.updateEndMonth(parseInt(data.target.value))
            }
        }
        else {
            dataPost = {
                start_date,
                month,
                support_code: support_code.concat(list_supports[data].support_code)
            }
            index = data;
            console.log({data});
            // this.props.updateListSupport(data);
        }
        dataPost.language = localStorage.getItem(CONST.LANGUAGE);
        dataPost.start_date = Utils.getStartDate(dataPost.start_date ? dataPost.start_date.toString() : "");
        dataPost.month = parseInt(dataPost.month);
        // dataPost.month = Utils.getEndMonth(dataPost.month ? dataPost.month.toString() : "");
        if (dataPost.support_code.length > 0) {
            // if (dataPost.start_date === "Invalid date" || dataPost.month === "Invalid date" || !dataPost.start_date || !dataPost.month) {
                console.log({dataPost});
            if (dataPost.start_date === "Invalid date" || !dataPost.start_date) {
                Alert.warning('notification.please enter desired period'.t(), {
                    position: 'top-right',
                    effect: 'scale',
                    timeout: require("../../config/const").TIMEOUT,
                    offset: -10
                })
            } 
            else if (!dataPost.month || dataPost.month <= 0) {
                Alert.warning('notification.Please enter number of months'.t(), {
                    position: 'top-right',
                    effect: 'scale',
                    timeout: require("../../config/const").TIMEOUT,
                    offset: -10
                })
            }
            // else if (dataPost.start_date > dataPost.month.concat("/31")) {
            //     Alert.warning('notification.start date must be smaller than end month'.t(), {
            //         position: 'top-right',
            //         effect: 'scale',
            //         timeout: require("../../config/const").TIMEOUT,
            //         offset: -10
            //     })
            // }
            else {
                // const data = { start_date, month, support_code };
                Utils.debounceCalculate(() => {

                    this.props.calculateSupportPrice(dataPost, index);
                })
            }
        }
    }

    render() {
        const { list_supports, loading_price, start_date, month, } = this.props;
        const { purpose_of_use, isFullSideBar } = this.props;
        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <main className={'main-container summary'}>
                    <Alert stack={{ limit: 1 }} />
                    <div>
                        <div className="step-app prev-step" />
                        <div className="step-app current-step">
                            <div className="pannel-wrapper application-details">
                                <legend className="legend">* {'application details.Required'.t()}</legend>
                                {/*<hr />*/}
                                <form action="">
                                    <div className="fields application-detail">
                                        <label className="label">{'application details.Desired Period'.t()} <em>*</em></label>
                                        <div className="multiple-fields">
                                            <div className="field field-custom">
                                                <div className="control">
                                                    <DatePicker
                                                        selected={start_date ? new Date(start_date) : ''}
                                                        minDate={new Date()}
                                                        onChange={date => this.handleEstimate(date, type.start_date)}
                                                        dateFormat="yyyy/MM/dd"
                                                        placeholderText={`application details.Start Date`.t() + " YYYY/MM/DD"}
                                                        id="start-date"
                                                        autoComplete={'off'}
                                                        customInput={
                                                            <MaskedInput
                                                                type="tel"
                                                                mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                            />
                                                        }
                                                    />
                                                    {/*<input type="month" onChange={date => this.changeEndMonth(date)}></input>*/}
                                                    <div className="mage-error">{'application details.You must enter a text'.t()}</div>
                                                </div>
                                                <div className="mage-error">{'application details.You must enter a text'.t()}</div>
                                            </div>
                                            <div className="field field-custom" style={{marginBottom: 0}}>
                                                <div className="control">
                                                    {/* <DatePicker
                                                        // selected={month ? new Date(month) : new Date()}
                                                        selected={month && new Date(month)}
                                                        minDate={Utils.disableOldMonth(new Date())}
                                                        onChange={date => this.handleEstimate(date, type.end_date)}
                                                        dateFormat="yyyy/MM"
                                                        placeholderText={`application details.End Date`.t() + " YYYY/MM"}
                                                        id="end-month"
                                                        showMonthYearPicker
                                                        autoComplete={'off'}
                                                        customInput={
                                                            <MaskedInput
                                                                mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/]}
                                                                keepCharPositions={true}
                                                                guide={true}
                                                            />
                                                        }
                                                    /> */}
                                                    <input 
                                                        type="number" className="form-control" 
                                                        placeholder={`application details.End Date`.t()}
                                                        onChange={event => this.handleEstimate(event, type.end_date)}
                                                        // onChange={event => this.debounceChangeMonth(event.target.value, type.end_date)}
                                                        value={month}
                                                    ></input>
                                                    {/*<input type="month" onChange={date => this.changeEndMonth(date)}></input>*/}
                                                    <div className="mage-error">{'application details.You must enter a text'.t()}</div>
                                                </div>
                                                <div style={{fontSize: '13px', marginTop: '6px'}}>{'application details.Warning number of month'.t()}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="field">
                                        <label className="label">{'application details.Support Menu'.t()}</label>
                                        <div className="control box-radius-top">
                                            <table
                                                className="table vertical-top table-border-solid table-border-all table-responsive-list table-support-menu">
                                                <div className={`loading-caculation ${loading_price && 'on-loading-caculation'}`}>
                                                    <div className="loading-caculation-image">
                                                        <img className="w-100 h-100" src={require('../../assets/images/loading-next-step.gif')} />
                                                    </div>
                                                </div>
                                                <thead>
                                                    <tr className="">
                                                        <th className="t-center col-select">{'application details.Select'.t()}</th>
                                                        <th className="col-support" colSpan="2">{"application details.Support".t()}</th>
                                                        <th className="col-overview">{'application details.Overview'.t()}</th>
                                                        <th className="t-right col-price">{'application details.Price'.t()}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ListSupport 
                                                        list_supports={list_supports} 
                                                        onChecked={this.handleEstimate.bind(this)} 
                                                        loading_price={loading_price} 
                                                        handleChangeOrderDay={this.handleChangeOrderDay}
                                                        handleCheckedBusinessTrip={this.handleCheckedBusinessTrip}
                                                    />
                                                </tbody>
                                            </table>

                                            <div>
                                                {list_supports.length > 0 ?
                                                    list_supports.map((item, index) =>
                                                        <div key={index} className="list-support-item">
                                                            <div className="custom-checkbox t-center col-select col-field">
                                                                <span style={{ fontWeight: '600' }}>{'application details.Select'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                                <span>
                                                                    <input type="checkbox" id={index.toString()} name={index.toString()} checked={item.checked} className="custom-control-input" onChange={() => this.onChecked(index, type.support)} />
                                                                    <label className="custom-control-label" htmlFor={index.toString()}>&nbsp;</label>
                                                                </span>
                                                            </div>
                                                            <div className="custom-checkbox t-center col-select col-field">
                                                                <p style={{ fontWeight: '600' }}>{"application details.Support".t()}:&nbsp;&nbsp;</p>
                                                                <p>{item.classification_name} </p>
                                                                <p>{item.support_name}</p>
                                                            </div>
                                                            <div className="col-overview long-words col-field">
                                                                <p style={{ fontWeight: '600' }}>{'application details.Overview'.t()}:&nbsp;&nbsp;</p>
                                                                <p> <Overview overview={item.support_overview}/> </p>
                                                            </div>
                                                            <div className="t-right col-price col-field">
                                                                <p style={{ fontWeight: '600' }}>{'application details.Price'.t()}:&nbsp;&nbsp;</p>
                                                                <p>{Utils.formatCurrency(parseInt(item.price))} {checkPrice(item.fixed_amount)}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">{'application details.Purpose of use'.t()}</label>
                                        <div className="control">
                                            <textarea name="purpose" value={purpose_of_use} onChange={e => this.changePurposeOfUse(e)} />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="application-step" className="application-step-actions tw-buttons">
                                <div className="next-step text-center">
                                    <button id="back-application" className="btn btn-secondary" onClick={this.prevStep} type="button">{'application details.Back'.t()}</button>
                                    <button className="btn btn-primary" type="button" onClick={this.nextStep}>{'application details.Next'.t()}</button>
                                    {/* {is_next ?
                                        <button className="btn btn-primary" type="button" onClick={this.nextStep}>{'application details.Next'.t()}</button>
                                        :
                                        <button className="btn btn-primary" type="button" onClick={this.handleEstimate}>{'application details.Estimate'.t()}</button>
                                    } */}
                                </div>
                            </div>
                        </div>
                        <div className="step-app next-step" />
                    </div>
                </main>
            </div>
        )
    }
}
export default withTranslation()(withRouter(ApplicationDetail))
