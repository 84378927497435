import {
    FETCH_ESTIMATE,
    FETCH_ESTIMATE_SUCCESS,
    FETCH_ESTIMATE_ERROR,
    UPDATE_ESTIME_FILE,
    UPDATE_ESTIME_FILE_SUCCESS,
    UPDATE_ESTIME_FILE_ERROR,
    UPDATE_IS_LOADING_ESTIMATE,
    UPDATE_ESTIMATE_FILE_STATUS,
    GET_PDF_INFO,
    GET_PDF_INFO_ERROR,
    GET_PDF_INFO_SUCCESS
} from './actionType';

export function fetchEstimate(estimateNo) {
    return {
        type: FETCH_ESTIMATE,
        payload: estimateNo
    }
}

export function fetchEstimateSuccess(data) {
    return {
        type: FETCH_ESTIMATE_SUCCESS,
        payload: data
    }
}

export function fetchEstimateError(message) {
    return {
        type: FETCH_ESTIMATE_ERROR,
        payload: message
    }
}
export function updateEstimateFile(data, history) {
    return {
        type: UPDATE_ESTIME_FILE,
        payload: {
            data, history
        }
    }
}

export function updateEstimateFileSuccess() {
    return {
        type: UPDATE_ESTIME_FILE_SUCCESS,
    }
}

export function updateEstimateFileError() {
    return {
        type: UPDATE_ESTIME_FILE_ERROR,
    }
}

export function updateIsLoadingEstimate(isLoading) {
    return {
        type: UPDATE_IS_LOADING_ESTIMATE,
        payload: isLoading
    }
}

export function updateEstimateFileStatus(status) {
    return {
        type: UPDATE_ESTIMATE_FILE_STATUS,
        payload: status
    }
}

export function getPdfInfo(estimateNo) {
    return {
        type: GET_PDF_INFO,
        payload: estimateNo
    }
}

export function getPdfInfoSuccess(data) {
    return {
        type: GET_PDF_INFO_SUCCESS,
        payload: data
    }
}

export function getPdfInfoError(data) {
    return {
        type: GET_PDF_INFO_SUCCESS,
    }
}