import React, { Component, useRef } from 'react'
import './style.css'
import { withTranslation } from "react-i18next";
import Alert from 'react-s-alert';
import Pages from "../../components/Pages";
import { withRouter, Route } from "react-router-dom";
import { STEP_HARDWARE_SETTING } from "../../constant/step-component";
import debounce from 'lodash/debounce';
import { Utils } from "../../config/utils";
import { tr } from '../../config/utils'

const ListHardware = ({ data, quantity, idx, onChecked, onChangeQty }) => {
    const refInput = useRef();
    if (!data || data.length === 0) return (
        <td className={'no-data'} colSpan={7}>
            {/* <img className={'img-nodata'} src={require('../../assets/images/icon/no-data.svg')} alt="no-data" /> */}
            <p>{'notification.There is no hardware available at this time'.t()}</p>
        </td>
    )

    console.log({data})

    return data.map((hardware, index) => (
        <tr key={index}>
            <td data-th="Instance: "><span className="hardware-field-mobile">{'hardware setting.Instance'.t()}: </span> {hardware.classification_name}</td>
            <td data-th="CPU: "><span className="hardware-field-mobile">GPU/CPU: </span>{hardware.cpu_frequency}&nbsp;{hardware.cpu_number_of_cores}</td>
            <td data-th="MEM: "><span className="hardware-field-mobile">MEM: </span>{hardware.memory_type}&nbsp;{hardware.memory_size}x{hardware.memory_qty}</td>
            <td data-th="Storage: "><span className="hardware-field-mobile">{'hardware setting.Storage'.t()}: </span>{hardware.storage_size}&nbsp;{hardware.storage_type}x{hardware.storage_qty}&nbsp;(RAID {hardware.storage_raid})</td>
            <td data-th="Quantity: ">
                <span className="hardware-field-mobile">{'hardware setting.Quantity'.t()}: </span>
                <input type="number" className="form-control heig-40"
                    ref={refInput}
                    value={hardware.quantity}
                    onChange={e => onChangeQty(e, hardware, refInput)} />
            </td>
            <td className="custom-radio t-center" data-th="Choose: ">
                <span className="hardware-field-mobile">{'hardware setting.Choose'.t()}: &nbsp; </span>
                <input
                    className="custom-control-input choice-hardware-input"
                    type="checkbox"
                    id={index.toString()}
                    checked={hardware.checked}
                    onChange={(event) => onChecked(hardware, event)}
                />
                <label className="custom-control-label hardware-setting" htmlFor={index.toString()}>&nbsp;</label>
            </td>
        </tr>
    ))
};

class HardwareSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isChange: false,
            search_keyword: "",
            page: 1,
            quantity: '',
            idx: null,
            checked: false,
            is_require: false,
            quantities: [],
            code: null,
            ref: null,
        };
        this.onChecked = this.onChecked.bind(this);
        this.onChangeQty = this.onChangeQty.bind(this);
        this.onCheckHardware = this.onCheckHardware.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }


    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        const { page } = this.state;
        const { list_hardware, data_update_estimate, language, hardware_active, search_keyword } = this.props;
        const processor = data_update_estimate.items.filter(item => item.product_category === 2)[0].software_processor
        this.props.onGetListHardware({ 
            processor, 
            search_keyword, 
            items: hardware_active.map(item => item.primary_key),
            estimate_no: this.props.estimate_no
        });
    };

    onChecked(hardware, event) {
        this.setState({ isChange: true });
        hardware.checked = event.target.checked;

        console.log({hardware})

        if (event.target.checked && hardware.quantity !== "") {
            this.onCheckHardware(hardware);
        } else {
            this.props.updateListHardware(hardware)
        }

    };

    onChangeQty(event, hardware, ref) {
        let quantity = event.target.value;
        this.setState({ isChange: true, ref });
        if (parseInt(tr(quantity)) <= 0 || parseInt(tr(quantity)) === 'NaN') {
            Alert.warning('notification.please enter a number greater than 0'.t(), {
                timeout: require("../../config/const").TIMEOUT,
                effect: 'scale',
                offset: -10
            })
            return;
        }
        if (tr(quantity) !== '') {
            hardware.quantity = quantity;
            hardware.checked = true;
            // this.props.updateListHardware(hardware);
            this.props.checkHardwareSuccess(hardware);
            this.props.loadingHardware(true)
            Utils.debounceSearch(hardware, this.onCheckHardware);
            return;
        } else {
            hardware.quantity = '';
            hardware.checked = false;
            this.props.loadingHardware(false)
            this.props.updateListHardware(hardware);
        }
    };

    onCheckHardware(hardware, isNext = false) {
        const { list_hardware, history, currentStep, estimate_no } = this.props;
        let isBack = currentStep > STEP_HARDWARE_SETTING.step;
        let hardwares = { ...hardware, estimate_no: this.props.estimate_no }
        let { ref } = this.state
        console.log({ hardware})
        // console.log(/^\d*$/.test(tr(hardware.quantity.trim())))
        if (parseInt(tr(hardware.quantity)) <= 0 || !tr(hardware.quantity)) {
            Alert.warning('notification.please enter a number greater than 0'.t(), {
                timeout: require("../../config/const").TIMEOUT,
                effect: 'scale',
                offset: -10
            })
            ref.current.blur();
            return;
        }
        if (Number.isInteger(parseFloat(tr(hardware.quantity))) === false || /^\d*$/.test(tr(hardware.quantity)) === false) {
            Alert.warning('hardware setting.Please enter an integer number'.t(), {
                timeout: require("../../config/const").TIMEOUT,
                effect: 'scale',
                offset: -10
            })
            ref.current.blur();
            this.props.checkHardwareError(hardware);
            return;
        }
        else {
            this.props.onCheckHardware({ data: list_hardware, hardware: hardwares, history, isBack }, isNext, ref);
        }
    }

    async onChangeSearch(e) {
        if (e.target.value === '') {
            const { page } = this.state;
            const { data_update_estimate, hardware_active } = this.props;
            const processor = data_update_estimate.items.filter(item => item.product_category === 2)[0].software_processor
            // await this.setState({ search_keyword: '' });
            await this.props.updateSearchKeyWord('');
            this.props.onGetListHardware({ processor, search_keyword: "", items: hardware_active.map(item => item.primary_key), page })
        } else {
            this.props.updateSearchKeyWord(e.target.value);
            this.setState({ search_keyword: e.target.value })
        }
    }

    async onSearch(e) {
        e.preventDefault();
        const { page } = this.state;
        const { list_hardware, data_update_estimate, search_keyword, hardware_active } = this.props;
        const processor = data_update_estimate.items.filter(item => item.product_category === 2)[0].software_processor

        if (search_keyword) {
            await this.setState({ is_require: false })
            this.props.onGetListHardware({ processor, search_keyword: search_keyword.trim(), items: hardware_active.map(item => item.primary_key), page })
        } else {
            this.setState({ is_require: true })
        }
    }

    onChangePage(page) {
        // const { search_keyword } = this.state;
        const { list_hardware, data_update_estimate, search_keyword, hardware_active } = this.props;
        const processor = data_update_estimate.items.filter(item => item.product_category === 2)[0].software_processor

        this.setState({ page }, () => {
            this.props.onGetListHardware({ processor, search_keyword, items: hardware_active.map(item => item.primary_key), page })
        })
    }

    handlePrev(history) {
        history.push(STEP_HARDWARE_SETTING.prevRoute);
        this.props.changeRoute(STEP_HARDWARE_SETTING.prevRoute);
        this.props.changeTitle(STEP_HARDWARE_SETTING.prevTitle);
    }

    handleNext() {
        const { data_update_estimate, list_hardware, currentStep, history } = this.props;
        const { isChange } = this.state;
        let hardware;
        let isBack = currentStep > STEP_HARDWARE_SETTING.step
        hardware = list_hardware.filter(e => e.checked === true);

        if (hardware.length === 0) {
            Alert.warning('notification.please choose a hardware'.t(), {
                timeout: require("../../config/const").TIMEOUT,
                effect: 'scale',
                offset: -10
            })
        }
        // else if (hardware[0].quantity === 0 || !hardware[0].quantity) {
        //     Alert.warning('notification.please enter quantity of hardware'.t(), {
        //         timeout: require("../../config/const").TIMEOUT,
        //         effect: 'scale',
        //         offset: -10
        //     })
        // }
        else if (hardware.find(hw => hw.quantity === '')) {
            Alert.warning('notification.please enter quantity of hardware'.t(), {
                timeout: require("../../config/const").TIMEOUT,
                effect: 'scale',
                offset: -10
            })
        }
        else {
            let items = [...data_update_estimate.items].filter(item => item.product_category !== 1);

            hardware.forEach(hw => {
                hw.product_category = 1;
                hw.product_code = hw.hardware_code;
            })

            items = items.concat(hardware);
            data_update_estimate.items = items;
            data_update_estimate.step = "1";

            // if (isBack === true && isChange === false) {
            //     history.push(STEP_HARDWARE_SETTING.nextRoute);
            // } else {
            //     // this.props.onCheckHardware({
            //     //     data: data_update_estimate,
            //     //     qty: hardware[0].quantity,
            //     //     code: hardware[0].classification_code,
            //     //     hardware: hardware[0],
            //     //     history,
            //     //     isBack
            //     // }, true)
            //     this.props.fetchUpdateEstimate(data_update_estimate, "Summary", "/summary", history, isBack);
            // }
            this.props.fetchUpdateEstimate(data_update_estimate, "Summary", "/summary", history, isBack);
        }
    }

    render() {
        const { list_hardware, total_page, isFullSideBar, hardware_active, isLoading, search_keyword } = this.props;
        const { quantity, idx, checked, quantities, page, is_require } = this.state;
        console.log({ hardware_active });

        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <main>
                    <Alert stack={{ limit: 1 }} />
                    <div className="main-container hardware">
                        <div className="step-app prev-step" />
                        <div className="step-app current-step">
                            <input id="old-choice-app" type="hidden" value="" name="old-choice-app" />
                            <input id="current-choice-app" type="hidden" value="" name="current-choice-app" />
                            <div className="hardware-wrapper">
                                <div className="hardware-main">
                                    <div className="no-background hardware-search">
                                        <form className="form-hardware-search">
                                            <div className="form-group div-search">
                                                <input type="tel" className="form-control input-search" name="search"
                                                    id="seach"
                                                    placeholder={'hardware setting.Enter your key word'.t()}
                                                    value={search_keyword}
                                                    onChange={this.onChangeSearch}
                                                />
                                                <div className="msg-error">{is_require && 'hardware setting.You must enter a keyword'.t()}</div>
                                            </div>
                                            <button id="submit" type="submit" className="btn btn-primary no-uppercase"
                                                onClick={this.onSearch}>
                                                {'hardware setting.Search'.t()}
                                            </button>
                                        </form>
                                    </div>
                                    <div className="pannel-wrapper hardware-information">
                                        <table className="table table-border-solid table-responsive-list table-rl-inline">
                                            <thead>
                                                <tr className="title">
                                                    <th className="col-instance">{'hardware setting.Instance'.t()}</th>
                                                    <th className="col-cpu">GPU/CPU</th>
                                                    <th className="col-mem">{'hardware setting.MEM'.t()}</th>
                                                    <th className="col-storage">{'hardware setting.Storage'.t()}</th>
                                                    <th className="col-quantity">{'hardware setting.Quantity'.t()}</th>
                                                    <th className="custom-radio t-center">{'hardware setting.Choose'.t()}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ListHardware
                                                    data={list_hardware}
                                                    quantity={quantity}
                                                    onChangeQty={this.onChangeQty}
                                                    onChecked={this.onChecked}
                                                    idx={idx}
                                                    checked={checked}
                                                    quantities={quantities}
                                                    isLoading={isLoading}
                                                />
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="toolbar-step hardware-toolbar">*/}
                            {/*<ul className="pages-items">*/}
                            {/*{*/}
                            {/*page > 1 &&*/}
                            {/*<li className="item pages-item-previous" onClick={this.onChangePage.bind(this, page - 1)}>*/}
                            {/*<a className="action previous"><span>Previous</span></a>*/}
                            {/*</li>*/}
                            {/*}*/}
                            {/*<Pages total_page={total_page} page={page} onChangePage={this.onChangePage}/>*/}
                            {/*{*/}
                            {/*page < total_page &&*/}
                            {/*<li className="item pages-item-next" onClick={this.onChangePage.bind(this, page + 1)}>*/}
                            {/*<a className="action next">*/}
                            {/*</a>*/}
                            {/*</li>*/}
                            {/*}*/}
                            {/*</ul>*/}

                            {/*</div>*/}
                            <div className="application-step-actions tw-buttons">
                                <div className="next-step text-center">
                                    <Route
                                        render={({ history }) => (
                                            <button id="next-summary"
                                                className="btn btn-secondary"
                                                type="button"
                                                onClick={this.handlePrev.bind(this, history)}
                                            >
                                                {'hardware setting.Back'.t()}
                                            </button>
                                        )}
                                    />
                                    <Route
                                        render={() => (
                                            <button id="next-summary"
                                                disabled={isLoading}
                                                className={`btn btn-primary btn-next-hardware ${isLoading ? 'btn-loading' : ''}`}
                                                type="button"
                                                onClick={this.handleNext.bind(this)}
                                            >
                                                <div>{'hardware setting.Next'.t()}</div>
                                                <img style={{ width: 20, height: 20 }} src={require('../../assets/images/loading-next-step.gif')} />
                                            </button>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="step-app next-step" />
                    </div>
                </main>
            </div>
        )
    }
}

export default withTranslation()(withRouter(HardwareSetting))
