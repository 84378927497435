const FETCH_LIST_SUPPORT = 'FETCH_LIST_SUPPORT';
const FETCH_LIST_SUPPORT_SUCCESS = 'FETCH_LIST_SUPPORT_SUCCESS';
const FETCH_LIST_SUPPORT_ERROR = 'FETCH_LIST_SUPPORT_ERROR';

const CALCULATING = 'CALCULATING';
const CALCULATE_SUPPORT_PRICE = 'CALCULATE_SUPPORT_PRICE';
const CALCULATE_SUPPORT_PRICE_SUCCESS = 'CALCULATE_SUPPORT_PRICE_SUCCESS';
const CALCULATE_SUPPORT_PRICE_ERROR = 'CALCULATE_SUPPORT_PRICE_ERROR';

const UPDATE_LIST_SUPPORTS = 'UPDATE_LIST_SUPPORTS';

const UPDATE_START_DATE = 'UPDATE_START_DATE';
const UPDATE_END_MONTH = 'UPDATE_END_MOUNT';
const UPDATE_PURPOSE_OF_USE = 'UPDATE_PURPOSE_OF_USE';
const RESET_IS_NEXT = 'RESET_IS_NEXT';

const UPDATE_ORDER_DAY = 'UPDATE_ORDER_DAY';
export {
    FETCH_LIST_SUPPORT, FETCH_LIST_SUPPORT_ERROR, FETCH_LIST_SUPPORT_SUCCESS,
    CALCULATE_SUPPORT_PRICE, CALCULATE_SUPPORT_PRICE_ERROR, CALCULATE_SUPPORT_PRICE_SUCCESS,
    UPDATE_LIST_SUPPORTS, CALCULATING,
    UPDATE_START_DATE,
    UPDATE_END_MONTH,
    UPDATE_PURPOSE_OF_USE,
    RESET_IS_NEXT,
    UPDATE_ORDER_DAY
}
