import {
    FETCH_USAGE_AMOUNT,
    FETCH_USAGE_AMOUNT_SUCCESS,
    FETCH_USAGE_AMOUNT_ERROR
} from './actionType';

export function fetchUsageAmount(estimate_no) {
    return {
        type: FETCH_USAGE_AMOUNT,
        payload: estimate_no
    }
}

export function fetchUsageAmountSuccess(data) {
    return {
        type: FETCH_USAGE_AMOUNT_SUCCESS,
        payload: data
    }
}

export function fetchUsageAmountError(message) {
    return {
        type: FETCH_USAGE_AMOUNT_ERROR,
        payload: message
    }
}