import { connect } from "react-redux";
import Summary from "./Summary";
import { fetchSummary } from './action';
import { changeRoute, changeTitle, nextStepSuccess } from '../../redux/action/nextStepAction';

const mapStateToProps = state => ({
    // classification_data: state.UseApplicationReducer.classification_data,
    summary_data: state.SummaryReducer.summary_data,
    estimate_no: state.NextStepReducer.data_update_estimate.estimate_no,
    currentStep: state.NextStepReducer.currentStep,
    isLoadingSummary: state.NextStepReducer.isLoadingSummary,
    isFullSideBar: state.NextStepReducer.isFullSideBar
})

const mapDispatchToProps = dispatch => ({
    // onGetClassification: (type) => dispatch(fetchClassification(type)),
    onGetSummary: (estimateNo) => dispatch(fetchSummary(estimateNo)),
    onChangeTitle: (title) => dispatch(changeTitle(title)),
    onChangeRoute: (route) => dispatch(changeRoute(route)),
    nextStepSuccess: () => dispatch(nextStepSuccess())

})
export default connect(mapStateToProps, mapDispatchToProps)(Summary)