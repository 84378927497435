import {
    CHECK_HARDWARE, CHECK_HARDWARE_ERROR, CHECK_HARDWARE_SUCCESS,
    FETCH_LIST_HARDWARE,
    FETCH_LIST_HARDWARE_ERROR, UPDATE_SEARCH_KEYWORD,
    FETCH_LIST_HARDWARE_SUCCESS, UPDATE_LIST_HARDWARE, LOADING_HARDWARE
} from "./actionType";
import Alert from 'react-s-alert';

export function fetchListHardware(data, language) {
    return {
        type: FETCH_LIST_HARDWARE,
        payload: data,
        language: language
    }
}
export function fetchListHardwareSuccess(data) {
    return {
        type: FETCH_LIST_HARDWARE_SUCCESS,
        payload: data
    }
}
export function fetchListHardwareError(error) {
    return {
        type: FETCH_LIST_HARDWARE_ERROR,
        payload: error
    }
}

export function updateListHardware(data) {
    return {
        type: UPDATE_LIST_HARDWARE,
        payload: data
    }
}

//check hardware
export function checkHardware(data, isNext, ref) {
    return {
        type: CHECK_HARDWARE,
        payload: data,
        isNext,
        ref
    }
}
export function checkHardwareSuccess(hardware) {
    return {
        type: CHECK_HARDWARE_SUCCESS,
        payload: hardware
    }
}

export function checkHardwareError(hardware) {
    return {
        type: CHECK_HARDWARE_ERROR,
        payload: hardware
    }
}

export function loadingHardware(loading) {
    return {
        type: LOADING_HARDWARE,
        payload: loading
    }
}

export function updateSearchKeyWord(keyWord) {
    return {
        type: UPDATE_SEARCH_KEYWORD,
        payload: keyWord
    }
}