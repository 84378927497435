const POST_REQUEST_ACCOUNT = 'POST_REQUEST_ACCOUNT';
const POST_REQUEST_ACCOUNT_SUCCESS = 'POST_REQUEST_ACCOUNT_SUCCESS';
const POST_REQUEST_ACCOUNT_FAILED = 'POST_REQUEST_ACCOUNT_FAILED';
const UPDATE_DATA = 'UPDATE_DATA';
const UPDATE_DATA_CONFIRM = 'UPDATE_DATA_CONFIRM';

const GET_TERM = 'GET_TERM';
const GET_TERM_SUCCESS = 'GET_TERM_SUCCESS';
const GET_TERM_ERROR = 'GET_TERM_ERROR';

const GET_PERSONAL_INFORMATION = 'GET_PERSONAL_INFORMATION';
const GET_PERSONAL_INFORMATION_SUCCESS = 'GET_PERSONAL_INFORMATION_SUCCESS';
const GET_PERSONAL_INFORMATION_ERROR = 'GET_PERSONAL_INFORMATION_ERROR';
const RESET_REQUEST_ACCOUNT = 'RESET_REQUEST_ACCOUNT';

const CHANGE_DATA = 'CHANGE_DATA';

export {
    POST_REQUEST_ACCOUNT,
    POST_REQUEST_ACCOUNT_SUCCESS,
    POST_REQUEST_ACCOUNT_FAILED,
    UPDATE_DATA,
    GET_TERM,
    GET_TERM_SUCCESS,
    GET_TERM_ERROR,
    GET_PERSONAL_INFORMATION,
    GET_PERSONAL_INFORMATION_SUCCESS,
    GET_PERSONAL_INFORMATION_ERROR,
    UPDATE_DATA_CONFIRM,
    CHANGE_DATA,
    RESET_REQUEST_ACCOUNT
}
