const SEARCH_HISTORY_INQUIRY_ADMIN = 'SEARCH_HISTORY_INQUIRY_ADMIN';
const SEARCH_HISTORY_INQUIRY_ADMIN_SUCCESS = 'SEARCH_HISTORY_INQUIRY_ADMIN_SUCCESS';
const SEARCH_HISTORY_INQUIRY_ADMIN_ERROR = 'SEARCH_HISTORY_INQUIRY_ADMIN_ERROR';

const SEARCH_USER = 'SEARCH_USER';
const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
const SEARCH_USER_ERROR = 'SEARCH_USER_ERROR';

const SEARCH_SOFTWARE = 'SEARCH_SOFTWARE';
const SEARCH_SOFTWARE_SUCCESS = 'SEARCH_SOFTWARE_SUCCESS';
const SEARCH_SOFTWARE_ERROR = 'SEARCH_SOFTWARE_ERROR';

const SEARCH_HARDWARE = 'SEARCH_HARDWARE';
const SEARCH_HARDWARE_SUCCESS = 'SEARCH_HARDWARE_SUCCESS';
const SEARCH_HARDWARE_ERROR = 'SEARCH_HARDWARE_ERROR';

const SEARCH_SUPPORT = 'SEARCH_SUPPORT';
const SEARCH_SUPPORT_SUCCESS = 'SEARCH_SUPPORT_SUCCESS';
const SEARCH_SUPPORT_ERROR = 'SEARCH_SUPPORT_ERROR';

const UPDATE_SEARCH_FORM = 'UPDATE_SEARCH_FORM';
const UPDATE_USER_NAME = 'UPDATE_USER_NAME';

const GET_ADMIN_USAGE = 'GET_ADMIN_USAGE';
const GET_ADMIN_USAGE_SUCCESS = 'GET_ADMIN_USAGE_SUCCESS';
const GET_ADMIN_USAGE_ERROR = 'GET_ADMIN_USAGE_ERROR';

const UPDATE_ORDERS = 'UPDATE_ORDERS';
const RESET_SEARCH = 'RESET_SEARCH';

const UPDATE_ESTIMATE_STATUS = 'UPDATE_ESTIMATE_STATUS';
const UPDATE_ESTIMATE_STATUS_SUCCESS = 'UPDATE_ESTIMATE_STATUS_SUCCESS';
const UPDATE_ESTIMATE_STATUS_ERROR = 'UPDATE_ESTIMATE_STATUS_ERROR';

export {
    SEARCH_HISTORY_INQUIRY_ADMIN,
    SEARCH_HISTORY_INQUIRY_ADMIN_SUCCESS,
    SEARCH_HISTORY_INQUIRY_ADMIN_ERROR,
    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_ERROR,
    SEARCH_SOFTWARE,
    SEARCH_SOFTWARE_ERROR,
    SEARCH_SOFTWARE_SUCCESS,
    SEARCH_HARDWARE,
    SEARCH_HARDWARE_SUCCESS,
    SEARCH_HARDWARE_ERROR,
    SEARCH_SUPPORT,
    SEARCH_SUPPORT_ERROR,
    SEARCH_SUPPORT_SUCCESS,
    UPDATE_SEARCH_FORM,
    UPDATE_USER_NAME,
    GET_ADMIN_USAGE,
    GET_ADMIN_USAGE_SUCCESS,
    GET_ADMIN_USAGE_ERROR,
    UPDATE_ORDERS,
    UPDATE_ESTIMATE_STATUS,
    UPDATE_ESTIMATE_STATUS_SUCCESS,
    UPDATE_ESTIMATE_STATUS_ERROR,
    RESET_SEARCH
}
