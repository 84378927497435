import { CLEAR_ESTIMATE } from '../../redux/actionType/nextStepActionType';
import {
    FETCH_USAGE_AMOUNT,
    FETCH_USAGE_AMOUNT_SUCCESS,
    FETCH_USAGE_AMOUNT_ERROR
} from './actionType';

const DEFAULT_STATE = {
    usage_amount_data: [],
    error: ''
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_USAGE_AMOUNT:
            return {
                ...state
            }
        case FETCH_USAGE_AMOUNT_SUCCESS: 
            return {
                ...state,
                usage_amount_data: action.payload
            }
        case FETCH_USAGE_AMOUNT_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case CLEAR_ESTIMATE:
            return {
                ...state,
                usage_amount_data: [],
                error: ''
            }
        default:
            return state;
    }
}