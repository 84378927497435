import { takeLatest, put } from 'redux-saga/effects';

import { GET_PERSONAL_INFORMATION, GET_TERM, POST_REQUEST_ACCOUNT } from './actionType';
import { API } from '../../services/APIs';
import { httpService } from '../../services/httpService';
import * as responseCode from '../../constant/response-code';
import {
    getPersonalInforSuccess,
    getTermSuccess,
    postRequestAccountFailed,
    postRequestAccountSuccess,
    updateData,
    updateDataConfirm,
} from "./action";
import { CONST } from "../../config/const";
import { nextStepSuccess, changeLoadingNextStep, changeRoute, changeTitle } from '../../redux/action/nextStepAction';

function* postRequestAccount(action) {
    try {
        yield put(changeLoadingNextStep(true));
        console.log({ data: action.payload })
        const url = API.VALIDATE_ACCOUNT_INFO;
        const res = yield httpService.postWithoutToken(url, action.payload.data);

        if (res.status === responseCode.SUCCESS) {
            console.log({ res });
            // yield localStorage.setItem(CONST.USER_CODE, res.data.user_code);
            yield put(updateData(action.payload.dataSave));
            yield put(updateDataConfirm(action.payload.data));
            yield put(nextStepSuccess("aaa"));
            yield action.payload.history.push('/confirm-new-registration');
        } else {
            yield put(postRequestAccountFailed(res.message))
        }
        yield put(changeLoadingNextStep(false));
    } catch (error) {
        yield put(postRequestAccountFailed(error.message));
        yield put(changeLoadingNextStep(false));
        console.log(error);
    }
    // console.log({ data: action.payload })
    // yield localStorage.setItem(CONST.USER_CODE, action.payload.data.user_code);
    // yield put(updateData(action.payload.dataSave));
    // yield put(updateDataConfirm(action.payload.data));
    // yield put(nextStepSuccess("aaa"));
    // yield action.payload.history.push('/confirm-new-registration');
}

export function* watchNextStepRequestAccount() {
    yield takeLatest(POST_REQUEST_ACCOUNT, postRequestAccount);
}

function* getTerm() {
    try {
        let language = localStorage.getItem(CONST.LANGUAGE);
        const url = API.TERM_OF_SERVICE + `?control_code=002&value_code=${language}`;
        const response = yield httpService.get(url);
        if (response.status === responseCode.SUCCESS) {
            yield put(getTermSuccess(response.data))
        }
    } catch (e) {
        console.log({ e })
    }
}

export function* watchGetTerm() {
    yield takeLatest(GET_TERM, getTerm)
}

function* getPersonal() {
    try {
        let language = localStorage.getItem(CONST.LANGUAGE);
        const url = API.PERSONAL_INFORMATION + `?control_code=003&value_code=${language}`;
        const response = yield httpService.get(url);
        if (response.status === responseCode.SUCCESS) {
            yield put(getPersonalInforSuccess(response.data))
        }
    } catch (e) {
        console.log({ e })
    }
}

export function* watchGetPersonal() {
    yield takeLatest(GET_PERSONAL_INFORMATION, getPersonal)
}
