import React, { Component } from 'react';
import './style.css'
import { withTranslation } from "react-i18next";
import CONSTANTS from '../../constant/const-helper';
import { Route, withRouter } from 'react-router-dom';
import { tr } from '../../config/utils'
import Alert from 'react-s-alert';
import { STEP_REQUEST_ACCOUNT } from '../../constant/step-component';
import { CONST } from '../../config/const';
class RequestAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidePass: false,
            hideConfirmPass: false,
            user_code: '',
            password: '',
            confirmation_password: '',
            re_application: '',
            first_name: '',
            last_name: '',
            first_name_kana: '',
            last_name_kana: '',
            company: '',
            company_kana: '',
            department: '',
            position: '',
            phone_1: '',
            phone_2: '',
            phone_3: '',
            email: '',
            postalcode_1: '',
            postalcode_2: '',
            address: '',
            fax_1: '',
            fax_2: '',
            fax_3: '',
            country_code: "",
            notify_user_code: '',
            notify_email: '',
            notify_company: '',
            notify_company_kana: '',
            notify_department: '',
            notify_first_name: '',
            notify_last_name: '',
            notify_first_name_kana: '',
            notify_last_name_kana: '',
            notify_password: '',
            notify_confirmPassword: '',
            notify_re_application: '',
            notify_phone: '',
            notify_postalCode: '',
            notify_fax: ''
        }
        this.inputPhone1 = React.createRef();
        this.inputPhone2 = React.createRef();
        this.inputPhone3 = React.createRef();
        this.inputEmail = React.createRef();
        this.inputPostal1 = React.createRef();
        this.inputPostal2 = React.createRef();
        this.inputAddress = React.createRef();
        this.inputFax1 = React.createRef();
        this.inputFax2 = React.createRef();
        this.inputFax3 = React.createRef();
        this.inputFake = React.createRef();
        this.user_code = React.createRef();
        this.password = React.createRef();
        this.confirmation_password = React.createRef();
        this.re_application = React.createRef();
        this.first_name = React.createRef();
        this.last_name = React.createRef();
        this.first_name_kana = React.createRef();
        this.last_name_kana = React.createRef();
        this.company = React.createRef();
        this.company_kana = React.createRef();
        this.department = React.createRef();
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.setState({
            notify_user_code: '',
            notify_email: '',
            notify_company: '',
            notify_company_kana: '',
            notify_department: '',
            notify_first_name: '',
            notify_last_name: '',
            notify_first_name_kana: '',
            notify_last_name_kana: '',
            notify_password: '',
            notify_confirmPassword: '',
            notify_re_application: '',
            notify_phone: '',
            notify_postalCode: '',
            notify_fax: ''
        })
        // this.props.updateData(datas);
        const { user_code, data } = this.props;
        if (data !== null) {
            this.setState(data);
        }
        this.props.getTerm();
        this.props.getPersonalInfor();
    }
    componentWillUnmount() {
        this.setState({
            notify_user_code: '',
            notify_email: '',
            notify_company: '',
            notify_company_kana: '',
            notify_department: '',
            notify_first_name: '',
            notify_last_name: '',
            notify_first_name_kana: '',
            notify_last_name_kana: '',
            notify_password: '',
            notify_confirmPassword: '',
            notify_re_application: '',
            notify_phone: '',
            notify_postalCode: '',
            notify_fax: ''
        })
    }
    onChange = (e) => {
        let target = e.target;
        if(target.name === "fax_3" && target.value.length <=4) {
            console.log({[target.name]: target.value, length: target.value.length});
            this.setState({
                // [target.name]: tr(target.value)
                [target.name]: target.value
            })
            this.props.onChangeData({ ...this.props.data, [target.name]: tr(target.value) })
            return;
        } 
        if(target.name !== "fax_3") {
            this.setState({
                [target.name]: tr(target.value)
            })
            this.props.onChangeData({ ...this.props.data, [target.name]: tr(target.value) })
        }
    };

    onChangePhone = (e, size) => {
        let value = e.target.value;
        switch (size) {
            case 1:
                if(value.length <= 5) {
                    this.setState({ phone_1: tr(value) });
                    this.props.onChangeData({ ...this.props.data, phone_1: tr(value) })
                }
                if (value.length === 5) {
                    this.inputPhone2.current.focus();
                }
                break;
            case 2:
                if(value.length <= 4) {
                    this.setState({ phone_2: tr(value) });
                    this.props.onChangeData({ ...this.props.data, phone_2: tr(value) })
                }  
                if (value.length === 4) {
                    this.inputPhone3.current.focus();
                }
                break;
            case 3:
                if(value.length <= 4) {
                    this.setState({ phone_3: tr(value) });
                    this.props.onChangeData({ ...this.props.data, phone_3: tr(value) })
                }
                if (value.length === 4) {
                    this.inputEmail.current.focus();
                }
                break;
            case 4:
                if(value.length <= 3) {
                    this.setState({ postalcode_1: tr(value) });
                    this.props.onChangeData({ ...this.props.data, postalcode_1: tr(value) })
                }
                if (value.length === 3) {
                    this.inputPostal2.current.focus();
                }
                break;
            case 5:
                if(value.length <= 4) {
                    this.setState({ postalcode_2: tr(value) });
                    this.props.onChangeData({ ...this.props.data, postalcode_2: tr(value) })
                }
                if (value.length === 4) {
                    this.inputAddress.current.focus();
                }
                break;
            case 6:
                if(value.length <=5 ) {
                    console.log({fax_1: value, length: value.length});
                    this.setState({ fax_1: tr(value) });
                    this.props.onChangeData({ ...this.props.data, fax_1: tr(value) })
                } 
                if (value.length === 5) {
                    this.inputFax2.current.focus();
                }
                break;
            case 7:
                if(value.length <= 4) {
                    this.setState({ fax_2: tr(value) });
                    this.props.onChangeData({ ...this.props.data, fax_2: tr(value) })
                    console.log({fax_2: value});
                }
                if (value.length === 4) {
                    this.inputFax3.current.focus();
                }
                break;
            default:
                break;
        }
    };

    validateEmail = (email) => {
        return CONSTANTS.REGEX_EMAIL.test(String(email).toLowerCase());
    };

    validatePassword = (password) => {
        return CONSTANTS.REGEX_PASSWORD.test(password);
    };

    validationPhone = (data) => {
        let { phone_1, phone_2, phone_3 } = data;
        // let reg_phone1 = /^[0-9]{5}$/;
        // let reg_phone2 = /^[0-9]{4}$/;
        // let reg_phone3 = /^[0-9]{4}$/;
        let reg_phone1 = /^[0-9]{1,5}$/;
        let reg_phone2 = /^[0-9]{1,4}$/;
        let reg_phone3 = /^[0-9]{1,4}$/;
        if (phone_1 === "" && phone_2 === "" && phone_3 === "") {
            return false;
        } else {
            return (reg_phone1.test(phone_1) && reg_phone2.test(phone_2) && reg_phone3.test(phone_3));
        }
        // else return false
    };

    validationFax = (data) => {
        let { fax_1, fax_2, fax_3 } = data;
        // let reg_fax_1 = /^[0-9]{5}$/;
        // let reg_fax_2 = /^[0-9]{4}$/;
        // let reg_fax_3 = /^[0-9]{4}$/;
        let reg_fax_1 = /^[0-9]{1,5}$/;
        let reg_fax_2 = /^[0-9]{1,4}$/;
        let reg_fax_3 = /^[0-9]{1,4}$/;
        if (fax_1 === "" && fax_2 === "" && fax_3 === "") {
            return true;
        }
        else {
            return (reg_fax_1.test(fax_1) && reg_fax_2.test(fax_2) && reg_fax_3.test(fax_3));
        }
        // if (!data)
        // else return true
    };

    validationPostal = (data) => {

        let { postalcode_1, postalcode_2 } = data;
        let reg_phone1 = /^[0-9]{1,3}$/;
        let reg_phone2 = /^[0-9]{1,4}$/;
        if (postalcode_1 === "" && postalcode_2 === "") {
            return true;
        } else {
            console.log({reg_phone1: reg_phone1.test(postalcode_1),reg_phone2: reg_phone2.test(postalcode_2)});
            return (reg_phone1.test(postalcode_1) && reg_phone2.test(postalcode_2));
        }
        // if (data)
        // else return true
    };

    prevStep = () => {
        let data = {
            ...this.state,
            notify_user_code: '',
            notify_email: '',
            notify_company: '',
            notify_company_kana: '',
            notify_department: '',
            notify_first_name: '',
            notify_last_name: '',
            notify_first_name_kana: '',
            notify_last_name_kana: '',
            notify_password: '',
            notify_confirmPassword: '',
            notify_re_application: '',
            notify_phone: '',
            notify_postalCode: '',
            notify_fax: ''
        }
        this.props.updateData(data);
        this.props.history.push(STEP_REQUEST_ACCOUNT.prevRoute);
        this.props.onChangeRoute(STEP_REQUEST_ACCOUNT.prevRoute);
        this.props.onChangeTitle(STEP_REQUEST_ACCOUNT.prevTitle);
    }

    TogglePass = () => {
        const { hidePass, } = this.state
        this.setState({ hidePass: !hidePass })
    }
    ToggleConfirmPass = () => {
        const { hideConfirmPass, } = this.state
        this.setState({ hideConfirmPass: !hideConfirmPass })
    }
    onFocus() {
        let phone_number;
        let fax_number;
        let number_postalcode;
        let {
            notify_company,
            user_code,
            notify_re_application,
            password,
            confirmation_password,
            address,
            company,
            company_kana,
            country_code,
            department,
            email,
            fax_1,
            fax_2,
            fax_3,
            first_name,
            first_name_kana,
            last_name,
            last_name_kana,
            phone_1,
            phone_2,
            phone_3,
            re_application,
            postalcode_1,
            postalcode_2,
            position,
        } = this.state;

        if (!this.validateEmail(user_code)) {
            this.user_code.current.focus()
            return;
        }
        // password
        if (!this.validatePassword(password)) {
            this.password.current.focus()
            return;
        }
        //comfirm pass
        if (confirmation_password !== password) {
            this.confirmation_password.current.focus()
            return;
        }
        let regex_input = /\S/
        //keyword for re-app
        if (!regex_input.test(re_application)) {
            this.re_application.current.focus();
            return;
        }
        //name
        if (!regex_input.test(first_name)) {
            this.first_name.current.focus();
            return;
        }
        if (!regex_input.test(last_name)) {
            this.last_name.current.focus()
            return;
        }
        //name (kana)
        if (!regex_input.test(first_name_kana)) {
            this.first_name_kana.current.focus()
            return;
        }
        if (!regex_input.test(last_name_kana)) {
            this.last_name_kana.current.focus()
            return;
        }
        //company
        if (!regex_input.test(company)) {
            this.company.current.focus()
            return;
        }
        //company (kana)
        if (!regex_input.test(company_kana)) {
            this.company_kana.current.focus()
            return;
        }
        //department
        // if (!regex_input.test(department)) {
        //     this.department.current.focus()
        //     return;
        // }

        //phone
        if (!this.validationPhone({ phone_1, phone_2, phone_3 })) {

            // let reg_phone1 = /^[0-9]{5}$/;
            // let reg_phone2 = /^[0-9]{4}$/;
            // let reg_phone3 = /^[0-9]{4}$/;
            let reg_phone1 = /^[0-9]{1,5}$/;
            let reg_phone2 = /^[0-9]{1,4}$/;
            let reg_phone3 = /^[0-9]{1,4}$/;
            if(!phone_1 || !reg_phone1.test(phone_1)) {
                this.inputPhone1.current.focus();
                this.inputPhone1.current.className += ' border border-danger' 
                console.log({current: this.inputPhone1.current});
                return;
            } else {
                this.inputPhone1.current.className = 'form-control' 
            }
            if(!phone_2 || !reg_phone2.test(phone_2)) {
                this.inputPhone2.current.focus();
                this.inputPhone2.current.className += ' border border-danger' 
                return;
            } else {
                this.inputPhone2.current.className = 'form-control' 
            }
            if(!phone_3 || !reg_phone3.test(phone_3)) {
                this.inputPhone3.current.focus();
                this.inputPhone3.current.className += ' border border-danger' 
                return;
            } else {
                this.inputPhone3.current.className = 'form-control' 
            }
        } else {
            this.inputPhone1.current.className = 'form-control' ;
            this.inputPhone2.current.className = 'form-control' ;
            this.inputPhone3.current.className = 'form-control' ;
        }

        //email user
        if (email && !this.validateEmail(email)) {
            this.inputEmail.current.focus()
            return
        } else {
            if (user_code === email) {
                this.inputEmail.current.focus()
                return;
            }
        }
        // postal
        if (!this.validationPostal({ postalcode_1, postalcode_2 })) {
            let reg_postal1 = /^[0-9]{1,3}$/;
            let reg_postal2 = /^[0-9]{1,4}$/;
            if (!reg_postal1.test(postalcode_1)) {
                this.inputPostal1.current.focus()
                return;
            } else if (!reg_postal2.test(postalcode_2)) {
                this.inputPostal2.current.focus()
                return;
            }
            // else {
            //     this.inputPostal1.current.focus()
            //     return;
            // }
        }

        // fax
        if (!this.validationFax({ fax_1, fax_2, fax_3 })) {
            // let reg_fax1 = /^[0-9]{5}$/;
            // let reg_fax2 = /^[0-9]{4}$/;
            // let reg_fax3 = /^[0-9]{4}$/;
            let reg_fax1 = /^[0-9]{1,5}$/;
            let reg_fax2 = /^[0-9]{1,4}$/;
            let reg_fax3 = /^[0-9]{1,4}$/;
            if (!reg_fax1.test(fax_1)) {
                this.inputFax1.current.focus();
                this.inputFax1.current.className += ' border border-danger';
                return;
            } else {
                this.inputFax1.current.className = 'form-control'; 
            }
            if (!reg_fax2.test(fax_2)) {
                this.inputFax2.current.focus();
                this.inputFax2.current.className += ' border border-danger';
                return;
            } else {
                this.inputFax2.current.className = 'form-control'; 
            }
            if ( !reg_fax3.test(fax_3)) {
                this.inputFax3.current.focus();
                this.inputFax3.current.className += ' border border-danger';
                return;
            } else {
                this.inputFax3.current.className = 'form-control'; 
            }
        } else {
            this.inputFax1.current.className = 'form-control'; 
            this.inputFax2.current.className = 'form-control'; 
            this.inputFax3.current.className = 'form-control'; 
        }

    }
    onSubmitRequestAccount = async (e, history) => {
        e.preventDefault();
        let phone_number;
        let fax_number;
        let number_postalcode;
        let {
            notify_company,
            user_code,
            notify_re_application,
            password,
            confirmation_password,
            address,
            company,
            company_kana,
            country_code,
            department,
            email,
            fax_1,
            fax_2,
            fax_3,
            first_name,
            first_name_kana,
            last_name,
            last_name_kana,
            phone_1,
            phone_2,
            phone_3,
            re_application,
            postalcode_1,
            postalcode_2,
            position,
        } = this.state;
        // email acc
        if (this.validateEmail(user_code)) {
            this.setState({ notify_user_code: '' })
        } else {
            user_code ?
                this.setState({ notify_user_code: 'request account.Email not correct format'.t() }) :
                this.setState({ notify_user_code: 'notification.Please fill out this field'.t() })
        }
        // password
        if (this.validatePassword(password)) {
            this.setState({ notify_password: '' })
        } else {
            password ?
                this.setState({ notify_password: 'change password.wrong valid password'.t() }) :
                this.setState({ notify_password: 'notification.Please fill out this field'.t() })
        }
        //comfirm pass
        if (confirmation_password && confirmation_password === password) {
            this.setState({ notify_confirmPassword: '' })
        } else {
            confirmation_password ?
                this.setState({ notify_confirmPassword: 'request account.Password does not match'.t() })
                : this.setState({ notify_confirmPassword: 'notification.Please fill out this field'.t() })
        }
        //keyword for re-app
        let regex_input = /\S/
        if (regex_input.test(re_application)) {
            this.setState({ notify_re_application: '' })
        } else {
            this.setState({ notify_re_application: 'notification.Please fill out this field'.t() });
        }
        //name
        if (regex_input.test(first_name)) {
            this.setState({ notify_first_name: '' })
        } else {
            this.setState({ notify_first_name: 'notification.Please fill out this field'.t() })
        }
        if (regex_input.test(last_name)) {
            this.setState({ notify_last_name: '' })
        } else {
            this.setState({ notify_last_name: 'notification.Please fill out this field'.t() })
        }
        //name (kana)
        if (regex_input.test(first_name_kana)) {
            this.setState({ notify_first_name_kana: '' })
        } else {
            this.setState({ notify_first_name_kana: 'notification.Please fill out this field'.t() })
        }
        if (regex_input.test(last_name_kana)) {
            this.setState({ notify_last_name_kana: '' })
        } else {
            this.setState({ notify_last_name_kana: 'notification.Please fill out this field'.t() })
        }
        //company
        if (regex_input.test(company)) {
            this.setState({ notify_company: '' })
        } else {
            this.setState({ notify_company: 'notification.Please fill out this field'.t() })
        }
        //company (kana)
        if (regex_input.test(company_kana)) {
            this.setState({ notify_company_kana: '' })
        } else {
            this.setState({ notify_company_kana: 'notification.Please fill out this field'.t() })
        }
        //department
        // if (regex_input.test(department)) {
        //     this.setState({ notify_department: '' })
        // } else {
        //     this.setState({ notify_department: 'notification.Please fill out this field'.t() })
        // }

        //phone
        if(!phone_1 && !phone_2 && !phone_3) {
            this.setState({ notify_phone: 'notification.Please enter a number'.t() })
        }
        else if (this.validationPhone({ phone_1, phone_2, phone_3 })) {
            phone_number = '+81' + phone_1 + phone_2 + phone_3;
            this.setState({ notify_phone: '' });
        } else {
            console.log({ phone_1, phone_2, phone_3 });
            if(phone_1.length < 1) {
                this.setState({ notify_phone: 'request account.Please enter a number up to 5 digits'.t() })
            } else if (phone_2.length < 1 || phone_3.length < 1) {
                this.setState({ notify_phone: 'request account.phone number not correct format'.t() })
            }
        }
        //email user
        if (email && email !== "") {
            if (!this.validateEmail(email)) {
                this.setState({ notify_email: 'request account.Email not correct format'.t() });
            } else {
                if (user_code.toUpperCase() !== email.toUpperCase()) {
                    this.setState({ notify_email: '' })
                }
                else {
                    this.setState({ notify_email: 'request account.Email address must different from you login ID'.t() });
                }
            }
        }

        // postal
        if (postalcode_1 !== "" || postalcode_2 !== "") {
            if (this.validationPostal({ postalcode_1, postalcode_2 })) {
                number_postalcode = postalcode_1 + postalcode_2;
                this.setState({ notify_postalCode: '' });
            } else {
                this.setState({ notify_postalCode: 'request account.Postalcode not correct format'.t() })
            }
        } else {
            this.setState({ notify_postalCode: '' });
        }
        //fax
        if (fax_1 !== "" || fax_2 !== "" || fax_3 !== "") {
            if (this.validationFax({ fax_1, fax_2, fax_3 })) {
                fax_number = '+81' + fax_1 + fax_2 + fax_3;
                this.setState({ notify_fax: '' });
            } else {
                if(fax_1.length < 1) {
                    this.setState({ notify_fax: 'request account.Please enter a number up to 5 digits'.t() })
                } else if (fax_2.length < 1 || fax_3.length < 1) {
                    this.setState({ notify_fax: 'request account.fax number not correct format'.t() })
                }
            }
        } else {
            this.setState({ notify_fax: '' });
        }

        this.onFocus()

        if (
            regex_input.test(re_application) 
            && regex_input.test(first_name) 
            && regex_input.test(last_name) 
            && regex_input.test(first_name_kana) 
            && regex_input.test(last_name_kana) 
            && regex_input.test(company) 
            && regex_input.test(company_kana) 
            // && regex_input.test(department)
            && this.validateEmail(user_code) 
            && (email && this.validateEmail(email) || email === "") 
            && this.validatePassword(password) 
            && (confirmation_password === password) 
            && re_application 
            && this.validationPhone({ phone_1, phone_2, phone_3 }) 
            && user_code.toUpperCase() !== email.toUpperCase()
            && this.validationFax({ fax_1, fax_2, fax_3 })
            && this.validationPostal({ postalcode_1, postalcode_2 })) {
            let data = {
                street_address1: address,
                street_address2: null,
                street_address3: null,
                street_address4: null,
                company_name: company,
                company_kana,
                country_code,
                department,
                user_first_name: first_name,
                user_first_kana: first_name_kana,
                user_last_name: last_name,
                user_last_kana: last_name_kana,
                keyword: re_application,
                position,
                delete_flaf: "",
                user_code,
                password,
                "password-confirmation": confirmation_password,
                mail_address: email,
                company_phone_number: phone_number,
                contact_phone_number: null,
                fax_number,
                postal_code1: postalcode_1,
                postal_code2: postalcode_2,
                is_update: this.state.user_code === this.props.user_code ? 1 : 0
            }
            // if (this.state.user_code !== this.props.user_code) {
            //     this.props.onRequestAccount({ data, history, dataSave: this.state });
            // }
            // else {
            //     this.props.nextStepSuccess();
            //     this.props.history.push(STEP_REQUEST_ACCOUNT.nextRoute);
            // }
            // console.log({ dataSave: this.state });
            let dataSave = {
                ...this.state,
                notify_user_code: '',
                notify_email: '',
                notify_company: '',
                notify_company_kana: '',
                notify_department: '',
                notify_first_name: '',
                notify_last_name: '',
                notify_first_name_kana: '',
                notify_last_name_kana: '',
                notify_password: '',
                notify_confirmPassword: '',
                notify_re_application: '',
                notify_phone: '',
                notify_postalCode: '',
                notify_fax: ''
            }

            // console.log("SEND");
            this.props.onRequestAccount({ data, history, dataSave });
        }
    }

    render() {
        const { isFullSideBar, term, personal_information, data } = this.props; 
        const windowOpen1 = () => {
            window.open('/Science_Cloud_Terms_Of_Service.pdf','_blank', 'fullscreen=yes')
          }
          const windowOpen2 = () => {
            window.open('/About_The_Handing_Of_Personal_Information.pdf','_blank', 'fullscreen=yes')
          }
        const language = localStorage.getItem(CONST.LANGUAGE)

        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <main className={'main-container summary'}>
                    <Alert stack={{ limit: 1 }} />
                    <div>
                        <div className="step-app prev-step" />
                        <div className="step-app current-step">
                            <div className="">
                                <Route
                                    render={({ history }) => (
                                        <form className="form-request-account" onSubmit={e => this.onSubmitRequestAccount(e, history)}>
                                            <div className="pannel-wrapper panel-request-account">
                                                <legend className="legend">* {'request account.Required'.t()}</legend>
                                                <hr />
                                                <div className="list-form-fields">
                                                    <div className="fields">
                                                        <label className="label">{'request account.Login ID'.t()} <em >*</em></label>
                                                        <div className="multiple-fields">
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input type="email" className="form-control" name="user_code" value={data && data.user_code} onChange={this.onChange}
                                                                        id="login-id" placeholder={"request account.Email Address".t()} autoComplete="off" ref={this.user_code} />
                                                                    <div className="mage-error">{'request account.You must enter e-mail address'.t()}</div>
                                                                    <div className="notice" style={{ color: 'red' }}>{this.state.notify_user_code}</div>
                                                                </div>
                                                            </div>
                                                            <div className="field box-tooltip">
                                                                <div className="control">
                                                                    <span className="notice">{'request account.Please enter your e-mail address'.t()}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <div className="multiple-fields">
                                                            <div className="field">
                                                                <label className="label">{'request account.Password'.t()} <em>*</em></label>
                                                                <div className="control ">
                                                                    <input type={this.state.hidePass ? "text" : "password"} className="form-control input-pass" value={data && data.password} name="password"
                                                                        id="password" onChange={this.onChange} ref={this.password}
                                                                        placeholder={'request account.note password'.t()} autoComplete="off" />
                                                                    <a className="eye"  ><i onClick={this.TogglePass} className={`fas  ${this.state.hidePass ? "fa-eye fa-eye-slash" : "fa-eye"}`}></i></a>

                                                                    <div className="mage-error">{'request account.You must enter password'.t()}</div>
                                                                    <div className="notice" style={{ color: 'red' }}>{this.state.notify_password}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <div className="multiple-fields">
                                                            <div className="field">
                                                                <label className="label">{'request account.Password Confirmation'.t()} <em>*</em></label>
                                                                <div className="control">
                                                                    <input type={this.state.hideConfirmPass ? "text" : "password"} className="form-control input-pass" value={data && data.confirmation_password} onChange={this.onChange}
                                                                        name="confirmation_password" id="confirmation-password" ref={this.confirmation_password}
                                                                        placeholder={'request account.note password'.t()} autoComplete="off" />
                                                                    <a className="eye"><i onClick={this.ToggleConfirmPass} className={`fas  ${this.state.hideConfirmPass ? "fa-eye fa-eye-slash" : "fa-eye"}`}></i></a>
                                                                    <div className="mage-error">{'request account.You must enter password'.t()}</div>
                                                                    <div className="notice" style={{ color: 'red' }}>{this.state.notify_confirmPassword}</div>
                                                                </div>
                                                            </div>
                                                            <div className="field">
                                                                <label className="label">{'request account.Keyword for re-application'.t()} <em>*</em></label>
                                                                <div className="control">
                                                                    <input type="text" className="form-control" value={data && data.re_application} onChange={this.onChange}
                                                                        name="re_application" id="re-application" autoComplete="off" ref={this.re_application} />
                                                                    <div className="mage-error">{'request account.You must enter re-application'.t()}</div>
                                                                    <div className="notice" style={{ color: 'red' }}>{this.state.notify_re_application}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <label className="label">{'request account.Name'.t()} <em>*</em></label>
                                                        {/* Swap input name on JP */}
                                                        {
                                                            language === "JP" ? 
                                                            <div className="multiple-fields">
                                                                <div className="field name">
                                                                <div className="control">
                                                                        <input type="text" className="form-control" name="last_name" value={data && data.last_name} onChange={this.onChange}
                                                                            id="last-name" placeholder={"request account.Last Name".t()} autoComplete="off" ref={this.last_name} />
                                                                        <div className="mage-error">{'request account.You must enter Last Name'.t()}</div>
                                                                        <div className="notice" style={{ color: 'red' }}>{this.state.notify_last_name}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="field name">
                                                                    <div className="control">
                                                                        <input type="text" className="form-control" name="first_name" value={data && data.first_name} onChange={this.onChange}
                                                                            id="first-name" placeholder={"request account.First Name".t()} autoComplete="off" ref={this.first_name} />
                                                                        <div className="mage-error">{'request account.You must enter First Name'.t()}</div>
                                                                        <div className="notice" style={{ color: 'red' }}>{this.state.notify_first_name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : 
                                                            <div className="multiple-fields">
                                                                <div className="field name">
                                                                    <div className="control">
                                                                        <input type="text" className="form-control" name="first_name" value={data && data.first_name} onChange={this.onChange}
                                                                            id="first-name" placeholder={"request account.First Name".t()} autoComplete="off" ref={this.first_name} />
                                                                        <div className="mage-error">{'request account.You must enter First Name'.t()}</div>
                                                                        <div className="notice" style={{ color: 'red' }}>{this.state.notify_first_name}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="field name">
                                                                    <div className="control">
                                                                        <input type="text" className="form-control" name="last_name" value={data && data.last_name} onChange={this.onChange}
                                                                            id="last-name" placeholder={"request account.Last Name".t()} autoComplete="off" ref={this.last_name} />
                                                                        <div className="mage-error">{'request account.You must enter Last Name'.t()}</div>
                                                                        <div className="notice" style={{ color: 'red' }}>{this.state.notify_last_name}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="fields">
                                                        <label className="label">{'request account.Name (Kana)'.t()} <em>*</em></label>
                                                        {/* Swap input name(kana) on JP */}
                                                        {
                                                            language === "JP" ? 
                                                            <div className="multiple-fields">
                                                                <div className="field name">
                                                                    <div className="control">
                                                                        <input type="text" className="form-control" name="last_name_kana" value={data && data.last_name_kana} onChange={this.onChange}
                                                                            id="last-name-kana" placeholder={"request account.Last Name".t() + " " + "request account.(Kana)".t()} autoComplete="off" ref={this.last_name_kana} />
                                                                        <div className="mage-error">{'request account.You must enter Last Name'.t()}</div>
                                                                        <div className="notice" style={{ color: 'red' }}>{this.state.notify_last_name_kana}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="field name">
                                                                <div className="control">
                                                                        <input type="text" className="form-control" name="first_name_kana" value={data && data.first_name_kana} onChange={this.onChange}
                                                                            id="first-name-kana" placeholder={"request account.First Name".t() + " " + "request account.(Kana)".t()} autoComplete="off" ref={this.first_name_kana} />
                                                                        <div className="mage-error">{'request account.You must enter First Name'.t()}</div>
                                                                        <div className="notice" style={{ color: 'red' }}>{this.state.notify_first_name_kana}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                             :
                                                            <div className="multiple-fields">
                                                                <div className="field name">
                                                                    <div className="control">
                                                                        <input type="text" className="form-control" name="first_name_kana" value={data && data.first_name_kana} onChange={this.onChange}
                                                                            id="first-name-kana" placeholder={"request account.First Name".t() + " " + "request account.(Kana)".t()} autoComplete="off" ref={this.first_name_kana} />
                                                                        <div className="mage-error">{'request account.You must enter First Name'.t()}</div>
                                                                        <div className="notice" style={{ color: 'red' }}>{this.state.notify_first_name_kana}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="field name">
                                                                    <div className="control">
                                                                        <input type="text" className="form-control" name="last_name_kana" value={data && data.last_name_kana} onChange={this.onChange}
                                                                            id="last-name-kana" placeholder={"request account.Last Name".t() + " " + "request account.(Kana)".t()} autoComplete="off" ref={this.last_name_kana} />
                                                                        <div className="mage-error">{'request account.You must enter Last Name'.t()}</div>
                                                                        <div className="notice" style={{ color: 'red' }}>{this.state.notify_last_name_kana}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="fields">
                                                        <label className="label">{'request account.Company Name'.t()} <em>*</em></label>
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="company" id="company" value={data && data.company} onChange={this.onChange} autoComplete="off" ref={this.company} />
                                                            <div className="mage-error">{'request account.You must enter company name'.t()}</div>
                                                            <div className="notice" style={{ color: 'red' }}>{this.state.notify_company}</div>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <label className="label">{'request account.Company Name Kana'.t()} <em>*</em></label>
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="company_kana" onChange={this.onChange}
                                                                id="company-kana" value={data && data.company_kana} autoComplete="off" ref={this.company_kana} />
                                                            <div className="mage-error">{'request account.You must enter company name'.t()}</div>
                                                            <div className="notice" style={{ color: 'red' }}>{this.state.notify_company_kana}</div>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <label className="label">{'request account.Department'.t()} </label>
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="department" onChange={this.onChange}
                                                                id="department" value={data && data.department} autoComplete="off" ref={this.department} />
                                                            <div className="mage-error">{'request account.You must enter department'.t()}</div>
                                                            <div className="notice" style={{ color: 'red' }}>{this.state.notify_department}</div>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <div className="multiple-fields">
                                                            <div className="field">
                                                                <label className="label">{'request account.Position'.t()}</label>
                                                                <div className="control">
                                                                    <input type="text" className="form-control" value={data && data.position} name="position" onChange={this.onChange}
                                                                        id="position" autoComplete="off" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fields fields-in-field">
                                                        <label className="label">{'request account.Phone Number'.t()} <em>*</em></label>
                                                        <div className="multiple-fields">
                                                            <div className="field phone">
                                                                <div className="multiple-fields three-fields line-between">
                                                                    <div className="field">
                                                                        <div className="control">
                                                                            <input type="number" minLength="5" maxLength="5" className="form-control" value={data && data.phone_1} name="phone_1" onChange={(e) => this.onChangePhone(e, 1)}
                                                                                id="phone-1" autoComplete="off" ref={this.inputPhone1} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="field">
                                                                        <div className="control">
                                                                            <input type="number" className="form-control" value={data && data.phone_2} ref={this.inputPhone2} name="phone_2" maxLength={4} minLength={4} onChange={(e) => this.onChangePhone(e, 2)}
                                                                                id="phone-2" autoComplete="off" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="field">
                                                                        <div className="control">
                                                                            <input type="number" maxLength="4" minLength="4" className="form-control" value={data && data.phone_3} ref={this.inputPhone3} name="phone_3" onChange={(e) => this.onChangePhone(e, 3)}
                                                                                id="phone-3" autoComplete="off" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="notice" style={{ color: 'red' }}>{this.state.notify_phone}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <label className="label">{'request account.Email Address'.t()}</label>
                                                        <div className="multiple-fields">
                                                            <div className="field">
                                                                <div className="control">
                                                                    <input type="email" className="form-control" name="email" ref={this.inputEmail}
                                                                        onChange={this.onChange} id="login-id" defaultValue={data && data.email} autoComplete="off" />
                                                                </div>
                                                                <div className="notice" style={{ color: 'red' }}>{this.state.notify_email}</div>
                                                            </div>
                                                            <div className="field box-tooltip">
                                                                <div className="control">
                                                                    <span className="notice">{'request account.Only enter if it is different from your login ID'.t()}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fields fields-in-field">
                                                        <label className="label">{'request account.Postal Code'.t()}</label>
                                                        <div className="multiple-fields">
                                                            <div className="fields portal">
                                                                <div className="multiple-fields line-between">
                                                                    <div className="field " style={{ paddingRight: '31px', width: '50%' }}>
                                                                        <div className="control" >
                                                                            <input type="tel" className="form-control"
                                                                                name="postalcode_1" value={data && data.postalcode_1} onChange={e => this.onChangePhone(e, 4)} ref={this.inputPostal1} minLength="3" maxLength="3" id="postalcode-1" autoComplete="off" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="field " style={{ paddingLeft: '31px', width: '50%' }}>
                                                                        <div className="control">
                                                                            <input maxLength="4" minLength="4" type="tel" className="form-control"
                                                                                name="postalcode_2" value={data && data.postalcode_2} onChange={e => this.onChangePhone(e, 5)} ref={this.inputPostal2} id="postalcode-2" autoComplete="off" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="notice" style={{ color: 'red' }}>{this.state.notify_postalCode}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fields">
                                                        <div className="multiple-fields">
                                                            <div className="field">
                                                                <label className="label">{'request account.Address'.t()}</label>
                                                                <div className="control">
                                                                    <textarea className="textaria-address" name="address" ref={this.inputAddress} value={data && data.address} onChange={this.onChange} autoComplete="off" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="fields fields-in-field">
                                                        <label className="label">{'request account.FAX Number'.t()} </label>
                                                        <div className="multiple-fields">
                                                            <div className="field fax">
                                                                <div className="multiple-fields three-fields line-between">
                                                                    <div className="field">
                                                                        <div className="control">
                                                                            <input type="tel" value={data && data.fax_1} minLength="5" maxLength="5" className="form-control" ref={this.inputFax1} name="fax_1" onChange={e => this.onChangePhone(e, 6)}
                                                                                id="fax-1" autoComplete="off" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="field">
                                                                        <div className="control">
                                                                            <input type="tel" value={data && data.fax_2} minLength="4" maxLength="4" className="form-control" ref={this.inputFax2} name="fax_2" onChange={e => this.onChangePhone(e, 7)}
                                                                                id="fax-2" autoComplete="off" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="field">
                                                                        <div className="control">
                                                                            <input type="tel" value={data && data.fax_3} minLength="4" maxLength="4" className="form-control" ref={this.inputFax3} name="fax_3" onChange={this.onChange}
                                                                                id="fax-3" autoComplete="off" />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="notice" style={{ color: 'red' }}>{this.state.notify_fax}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul className="links">
                                                        <li className="item-link">
                                                            {/* <a data-toggle="modal" data-target="#terms-of-service">{'request account.Terms of service'.t()}</a> */}
                                                            <a  onClick={windowOpen1}>{'request account.Terms of service'.t()}</a>
                                                        </li>
                                                        <li className="item-link">
                                                            {/* <a data-toggle="modal" data-target="#handling-of-person-info">{'request account.About the handing of personal information'.t()}</a> */}
                                                            <a onClick={windowOpen2}>{'request account.About the handing of personal information'.t()}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="application-step-actions tw-buttons">
                                                <div className="next-step text-center">
                                                    <button id="back-usage-amount" className="btn btn-secondary" type="button" onClick={this.prevStep}>
                                                        {"request account.Back".t()}
                                                    </button>
                                                    <button id="next-confirm-new-registration" className="btn btn-primary" type="button"
                                                        onClick={e => this.onSubmitRequestAccount(e, history)}
                                                    >
                                                        {"request account.Next".t()}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                />
                                <div id="terms-of-service" className="modal fade " role="dialog" >
                                    <div className="modal-dialog" >
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close"
                                                    data-dismiss="modal">&times;</button>
                                                <h4 className="modal-title text-center">{'request account.Terms of service'.t()}</h4>
                                            </div>
                                            <div className="modal-body">
                                                <pre className="content-text">{term}</pre>
                                            </div>
                                            <div className="modal-footer text-center">
                                                <button type="button" className="btn btn-primary btn-lg"
                                                    data-dismiss="modal">{"request account.OK".t()}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div id="handling-of-person-info" className="modal fade" role="dialog">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close"
                                                    data-dismiss="modal">&times;</button>
                                                <h4 className="modal-title text-center">{'request account.About the handing of personal information'.t()}</h4>
                                            </div>
                                            <div className="modal-body">
                                                <pre className="content-text">{personal_information}</pre>
                                            </div>
                                            <div className="modal-footer text-center">
                                                <button type="button" className="btn btn-primary btn-lg"
                                                    data-dismiss="modal">{"request account.OK".t()}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="step-app next-step" />
                    </div>
                </main>
            </div>
        )
    }
}
export default withTranslation()(withRouter(RequestAccount))

