import { CONST } from "../../config/const";
import { FETCHING } from "../use-application/actionType";
import { CHECK_AUTH } from "./actionType";
import jwtDecode from 'jwt-decode';

let content = sessionStorage.getItem(CONST.TOKEN);

let isAdmin = false;
if (content) {
    isAdmin = jwtDecode(content).user_claims.role === CONST.ADMIN;
}

const DEFAULT_STATE = {
    token: sessionStorage.getItem(CONST.TOKEN),
    user_type: sessionStorage.getItem(CONST.USER_TYPE),
    error: null,
    fetching: false,
    // isAdmin: localStorage.getItem(CONST.USER_TYPE) === CONST.ADMIN,
    isAdmin,
    isLogin: !!sessionStorage.getItem(CONST.TOKEN),
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCHING:
            return {
                ...state,
                fetching: action.payload
            };
        case CHECK_AUTH:
            return {
                ...state,
                isAdmin: action.payload.isAdmin,
                isLogin: action.payload.isLogin
            }
        default:
            return state;
    }
}
