const FETCH_SUMMARY = 'FETCH_SUMMARY';
const FETCH_SUMMARY_SUCCESS = 'FETCH_SUMMARY_SUCCESS';
const FETCH_SUMMARY_ERROR = 'FETCH_SUMMARY_ERROR';
const UPDATE_IS_LOADING_SUMMARY = 'UPDATE_IS_LOADING_SUMMARY';

export {
    FETCH_SUMMARY,
    FETCH_SUMMARY_SUCCESS,
    FETCH_SUMMARY_ERROR,
    UPDATE_IS_LOADING_SUMMARY
}