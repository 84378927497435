import { put, takeLatest } from 'redux-saga/effects';
import { httpService } from "../../services/httpService";
import { API } from "../../services/APIs";
import * as responseCode from '../../constant/response-code';
import { fetchImportError, fetchImportSuccess } from "./action";
import { FETCH_IMPORT } from "./actionType";
import Alert from 'react-s-alert';

function* uploadImport(action) {
    try {
        const res = yield httpService.uploadFile(API.IMPORT, action.payload);
        if (res.status === responseCode.SUCCESS) {
            if (!res.data) {
                Alert.success('notification.When import data successful'.t(), {
                    offset: -10,
                    timeout: require('../../config/const').TIMEOUT,
                    effect: 'scale'
                });
            }
            yield put(fetchImportSuccess(res.data || []))
        } else {
            console.log({ res });
            Alert.error(res.message, {
                offset: -10,
                timeout: require('../../config/const').TIMEOUT,
                effect: 'scale'
            });

            yield put(fetchImportError(res.message, res.data || []))
        }
    } catch (e) {
        Alert.error(e.message, {
            offset: -10,
            timeout: require('../../config/const').TIMEOUT,
            effect: 'scale'
        });
    }
}

export function* watchImport() {
    yield takeLatest(FETCH_IMPORT, uploadImport)
}
