import { connect } from "react-redux";
import AdminImport from "./AdminImport";
import { fetchImport, resetReducer } from "./action";

const mapStateToProps = state => ({
    error_file: state.AdminImportReducer.error_file,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    status: state.AdminImportReducer.status,
});

const mapDispatchToProps = dispatch => ({
    uploadFile: (formData) => dispatch(fetchImport(formData)),
    clearReducer: () => dispatch(resetReducer())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminImport)
