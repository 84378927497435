import { CONST } from "../config/const";
import { persistor } from '../redux/store';

export const httpService = {
    get: async (url) => {
        const token = await sessionStorage.getItem(CONST.TOKEN);
        const lang = await localStorage.getItem(CONST.LANGUAGE);
        const options = {
            method: 'get',
            headers: {
                Accept: 'Application/json',
                "Content-type": "Application/json",
                Authorization: "Bearer " + token,
                locale: lang
            }
        }
        let response = fetch(url, options);
        return (await response).json();
        if ((await response).ok) {
            return (await response).json();
        } else {
            await sessionStorage.removeItem(CONST.TOKEN);
            await sessionStorage.removeItem(CONST.USER_CODE);
            await sessionStorage.removeItem(CONST.USER_NAME);
            await sessionStorage.removeItem(CONST.USER_NAME_JP);
            await sessionStorage.removeItem(CONST.USER_TYPE);
            persistor.purge();
            window.location = `${window.origin}/login`;
            return "server no response";
        }
        // return fetch(url, options).then(res => res.json())
    },
    getWithoutToken: async (url) => {
        const lang = await localStorage.getItem(CONST.LANGUAGE);

        const options = {
            method: 'get',
            headers: {
                Accept: 'Application/json',
                "Content-type": "Application/json",
                locale: lang

            }
        }
        let response = fetch(url, options);
        if ((await response).ok) {
            return (await response).json();
        } else {
            window.location = `${window.origin}/login`;
            await sessionStorage.removeItem(CONST.TOKEN);
            await sessionStorage.removeItem(CONST.USER_CODE);
            await sessionStorage.removeItem(CONST.USER_NAME);
            await sessionStorage.removeItem(CONST.USER_NAME_JP);
            await sessionStorage.removeItem(CONST.USER_TYPE);
            persistor.purge();
            return "server no response";
        }
        // return fetch(url, options).then(res => res.json())
    },
    post: async (url, data) => {
        const token = await sessionStorage.getItem(CONST.TOKEN);
        const lang = await localStorage.getItem(CONST.LANGUAGE);

        const options = {
            method: 'post',
            headers: {
                Accept: 'Application/json',
                "Content-type": "Application/json",
                Authorization: "Bearer " + token,
                locale: lang

            },
            body: JSON.stringify(data)
        }
        let response = fetch(url, options);
        if ((await response).ok) {
            return (await response).json()
        } else {
            window.location = `${window.origin}/login`;
            await sessionStorage.removeItem(CONST.TOKEN);
            await sessionStorage.removeItem(CONST.USER_CODE);
            await sessionStorage.removeItem(CONST.USER_NAME);
            await sessionStorage.removeItem(CONST.USER_NAME_JP);
            await sessionStorage.removeItem(CONST.USER_TYPE);
            persistor.purge();
            return "server no response";
        }
        // return fetch(url, options).then(res => res.json()).catch(err => err)
    },
    postWithoutToken: async (url, data) => {
        const lang = await localStorage.getItem(CONST.LANGUAGE);

        const options = {
            method: 'post',
            headers: {
                Accept: 'Application/json',
                "Content-type": "Application/json",
                locale: lang
            },
            body: JSON.stringify(data)
        }
        let response = fetch(url, options);
        if ((await response).ok) {
            return (await response).json();
        } else {
            window.location = `${window.origin}/login`;
            await sessionStorage.removeItem(CONST.TOKEN);
            await sessionStorage.removeItem(CONST.USER_CODE);
            await sessionStorage.removeItem(CONST.USER_NAME);
            await sessionStorage.removeItem(CONST.USER_NAME_JP);
            await sessionStorage.removeItem(CONST.USER_TYPE);
            persistor.purge();
            return "server no response";
        }
        // return fetch(url, options).then(res => res.json())
    },

    uploadFile: async (url, data) => {
        const token = await sessionStorage.getItem(CONST.TOKEN);
        const lang = await localStorage.getItem(CONST.LANGUAGE);

        let options = {
            method: 'POST',
            headers: {
                Authorization: "Bearer " + token,
                locale: lang

            },
            body: data,
        };
        let response = fetch(url, options);
        if ((await response).ok) {
            return (await response).json();
        } else {
            window.location = `${window.origin}/login`;
            await sessionStorage.removeItem(CONST.TOKEN);
            await sessionStorage.removeItem(CONST.USER_CODE);
            await sessionStorage.removeItem(CONST.USER_NAME);
            await sessionStorage.removeItem(CONST.USER_NAME_JP);
            await sessionStorage.removeItem(CONST.USER_TYPE);
            persistor.purge();
            return "server no response";
        }
        // return fetch(url, options).then(res => res.json())
    }
};
