import { FETCH_LOGIN, FETCH_LOGIN_ERROR, FETCH_LOGIN_SUCCESS, CHECK_AUTH } from "./actionType";
import Alert from 'react-s-alert'
export function fetchLogin(data) {
    return {
        type: FETCH_LOGIN,
        payload: data
    }
}

export function fetchLoginSuccess(data) {
    return {
        type: FETCH_LOGIN_SUCCESS,
        payload: data
    }
}

export function fetchLoginError(error) {
    Alert.error(error, { timeout: require("../../config/const").TIMEOUT });
    return {
        type: FETCH_LOGIN_ERROR,
        payload: error
    }
}

export function checkAuth(data) {
    return {
        type: CHECK_AUTH,
        payload: data
    }
}
