import { takeEvery, put, takeLatest } from 'redux-saga/effects';

import {
    FETCH_CLASSIFICATION,
    FETCH_SOFTWARE,
    FETCH_SOFTWARE_VERSION,
    CLEAR_LIST_SOFTWARE_VERSION
}
    from './actionType';
import { API } from '../../services/APIs';
import { httpService } from '../../services/httpService';
import {
    fetchClassificationSuccess,
    fetchClassificationError,
    fetchSoftwareSuccess,
    fetching,
    fetchingVersion,
    fetchSoftwareError,
    fetchSoftwareVersionSuccess,
    clearListSoftwareVersionSuccess
}
    from './action';
import * as responseCode from '../../constant/response-code';
import { CONST } from '../../config/const';

// const language = localStorage.getItem(CONST.LANGUAGE);

function* getClassification(action) {
    try {
        console.log({ action: action })
        const url = API.GET_CLASSIFICATION + `?product_category=${action.payload.type}&language=${action.payload.language}`;
        // const res = yield httpService.getWithoutToken(url);
        const res = yield httpService.get(url);
        if (res.status === responseCode.SUCCESS) {
            // res.data.unshift({
            //     classification_code: '',
            //     classification_name: 'ALL Application'
            // });
            yield put(fetchClassificationSuccess(res.data));
            res.data.map(item => {
                item.softwareVersion = [];
            })
            let data = {
                payload: {
                    processor: action.payload.processor,
                    search_keyword: action.payload.key_word,
                    classification_code: action.payload.classification_code,
                    // page : 1
                }
            }
            yield getSoftware(data, action.payload.language);
        }
        if (res.code === responseCode.NOT_FOUND) {
            yield put(fetchClassificationError(res.status));
        }
    } catch (e) {
        yield put(fetchClassificationError(e));
    }
}

function* getSoftware(action, language) {
    try {
        let url = API.GET_LIST_SOFTWARE;
        let data;
        if (language) {
            data = { ...action.payload, language }
        }
        else {
            data = { ...action.payload, language: localStorage.getItem(CONST.LANGUAGE) }
        }
        console.log({action})
        yield put(fetching(true));

        // const res = yield httpService.postWithoutToken(url, data);
        const res = yield httpService.post(url, data);
        if (res.status == responseCode.SUCCESS) {
            yield put(fetchSoftwareSuccess(res.data));
        }
        if (res.code === responseCode.NOT_FOUND) {
            yield put(fetchSoftwareError(res.message));
        }
        yield put(fetching(false));
    } catch (e) {
        console.log(e);
        yield put(fetching(false));
    }
}

function* getSoftwareVersion(action) {
    try {
        yield put(fetchingVersion(true));
        let language = localStorage.getItem(CONST.LANGUAGE);
        const url = API.GET_SOFTWARE_VERSION + `?software_name=${action.payload}&language=${language}`;
        const res = yield httpService.get(url);
        if (res.status === responseCode.SUCCESS) {
            yield put(fetchSoftwareVersionSuccess(res.data));
        }
        if (res.code === responseCode.NOT_FOUND) {
            yield put(fetchClassificationError(res.message));
        }
        yield put(fetchingVersion(false));
    } catch (e) {
        console.log(e);
        yield put(fetchingVersion(false));
    }
}

function* updateListSoftwareVersion(action) {
    let data = action.payload;
    let language = localStorage.getItem(CONST.LANGUAGE);
    const url = API.GET_SOFTWARE_VERSION + `?software_name=${action.payload.software_name}&language=${language}`;
    const res = yield httpService.get(url);
    yield put(clearListSoftwareVersionSuccess(data));
    if (res.status === responseCode.SUCCESS) {
        yield put(fetchSoftwareVersionSuccess(res.data));
    }
    if (res.code === responseCode.NOT_FOUND) {
        yield put(fetchClassificationError(res.message));
    }
}

export function* watchGetClassification() {
    yield takeLatest(FETCH_CLASSIFICATION, getClassification);
    yield takeLatest(FETCH_SOFTWARE, getSoftware);
    yield takeEvery(FETCH_SOFTWARE_VERSION, getSoftwareVersion);
    yield takeLatest(CLEAR_LIST_SOFTWARE_VERSION, updateListSoftwareVersion);
}
