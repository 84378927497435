import { call, all, fork } from 'redux-saga/effects';
import { watchGetClassification } from '../../views/use-application/saga';
import { watchCheckHardware, watchGetListHardware } from "../../views/hardware-setting/saga";
import { watchGetSummary } from '../../views/summary/saga';
import { watchGetEstimate } from '../../views/estimate/saga';
import { watchGetAccountDetail, watchPostRequestAccount } from '../../views/confirm-new-registration/saga';
import { watchCalculateSupportPrice, watchGetListSupport } from "../../views/application-detail/saga";
import { watchGetPersonal, watchGetTerm, watchNextStepRequestAccount } from "../../views/request-account/saga";
import { watchUpdateEstimate, watchGetLanguage } from "./updateEstimateSaga";
import { watchFetchUsageAmount } from '../../views/usage-amount/saga';
import { watchImport } from "../../views/admin-import/saga";
import { watchLogin } from "../../views/login/saga";
import { watchFetchUsageHistory } from '../../views/usage-history-inquiry/saga';
import { watchExport } from "../../views/admin-export/saga";
import { watchFetchHistoryInquiryAdmin } from '../../views/admin-usage-history-inquiry/saga';

function* rootSaga() {
    yield all([
        watchGetClassification(),
        watchGetListHardware(),
        watchCheckHardware(),
        watchGetListSupport(),
        watchCalculateSupportPrice(),
        watchGetSummary(),
        watchNextStepRequestAccount(),
        watchPostRequestAccount(),
        watchGetEstimate(),
        watchUpdateEstimate(),
        watchGetAccountDetail(),
        watchImport(),
        watchFetchUsageAmount(),
        watchLogin(),
        watchFetchUsageHistory(),
        watchExport(),
        watchFetchHistoryInquiryAdmin(),
        watchGetTerm(),
        watchGetPersonal(),
        watchGetLanguage(),
    ]);
}
export default rootSaga;
