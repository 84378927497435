import { createStore, applyMiddleware, compose } from 'redux';
import { put } from 'redux-saga/effects';
import { clearEstimate } from '../action/nextStepAction';
import rootReducer from '../reducer';
// redux persist
import { persistStore, persistReducer, purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import expireReducer from 'redux-persist-expire';

//redux saga
import createSagaMiddleware from 'redux-saga';
import { CONST } from '../../config/const';
import jwtDecode from 'jwt-decode';

let timeoutClear;

const timeClearStorage = 86400;
const timeoutSession = 600

export const sagaMiddleware = createSagaMiddleware({
    sagaMonitor: {
        effectResolved: async (id, result) => {
            if(timeoutClear) {
                clearTimeout(timeoutClear);
            }
            const token = await sessionStorage.getItem(CONST.TOKEN);
            if(token) {
                const isAdmin = jwtDecode(token).user_claims.role === CONST.ADMIN;
                if(!isAdmin) {
                    timeoutClear = setTimeout(async () => {
                        window.location.href = window.location.href;
                        // sessionStorage.removeItem(CONST.USER_NAME);
                        // sessionStorage.removeItem(CONST.TOKEN);
                        // sessionStorage.removeItem(CONST.USER_CODE);
                    }, timeoutSession * 1000);
                }
            }   
        }
    }
}); 


const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['HistoryInquiryAdminReducer', 'AdminExportReducer', 'AdminImportReducer', 'LoginReducer'],
    transforms: [
        expireReducer("UseApplicationReducer", {
            expireSeconds: timeClearStorage,
            autoExpire: true
        }),
        expireReducer("HardwareSettingReducer", {
            expireSeconds: timeClearStorage,
            autoExpire: true
        }),
        expireReducer("ApplicationDetailReducer", {
            expireSeconds: timeClearStorage,
            autoExpire: true
        }),
        expireReducer("SummaryReducer", {
            expireSeconds: timeClearStorage,
            autoExpire: true
        }),
        expireReducer("EstimateReducer", {
            expireSeconds: timeClearStorage,
            autoExpire: true
        }),
        expireReducer("UsageAmountReducer", {
            expireSeconds: timeClearStorage,
            autoExpire: true
        }),
        expireReducer("NextStepReducer", {
            expireSeconds: timeClearStorage,
            autoExpire: true
        }),
    ]
}

const persistedReducers = persistReducer(persistConfig, rootReducer);

export const store = createStore(
    persistedReducers,
    applyMiddleware(sagaMiddleware)
);

export const persistor = persistStore(store);
