import React from "react";
import {
    BrowserRouter,
    Switch,
    Route,

} from "react-router-dom";
// import { routes } from './route';
// import { routesAdmin } from './route-admin';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { history } from "../config/history";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
// import { toggleIsFullSidebar } from "../redux/action/nextStepAction";
import Login from "../views/login/container";

import AuthenticatedRoute from "./AuthenticateRoute";
import AdminRoute from "./AdminRoute";
import StepRoute from "./StepRoute";

import UseApplication from "../views/use-application/container";
import HardwareSetting from "../views/hardware-setting/container";
import Summary from "../views/summary/container";
import ApplicationDetail from "../views/application-detail/container";
import UsageAmount from "../views/usage-amount/container";
import RequestAccount from "../views/request-account/container";
import ConfirmNewRegistration from "../views/confirm-new-registration/container";
import Estimate from "../views/estimate/container";
import UsageHistoryInquiry from "../views/usage-history-inquiry/container";
import ChangePassword from "../views/change-password/ChangePassword";
import ForgotPassword from "../views/forgot-password/ForgotPassword";
import AdminImport from "../views/admin-import/container";
import AdminExport from "../views/admin-export/container";
import AdminUsageHistoryInquiry from "../views/admin-usage-history-inquiry/container";
import PDFView from '../views/pdf';
import {
    STEP_USE_APPLICATION,
    STEP_HARDWARE_SETTING,
    STEP_SUMMARY,
    STEP_APPLICATION_DETAILS,
    STEP_USAGE_AMOUNT,
    STEP_REQUEST_ACCOUNT,
    STEP_CONFIRM_NEW_REGISTRATION,
    STEP_ESTIMATE_LOGIN,
    // USAGE_HISTORY_INQUIRY_USER,
    // CHANGE_PASSWORD,
    // FORGOT_PASSWORD,
    // STEP_USAGE_AMOUNT_LOGIN,
    STEP_ACCOUNT_INFORMATION,
    // STEP_ESTIMATE_LOGIN,
    USAGE_HISTORY_INQUIRY_ADMIN,
    ADMIN_IMPORT,
    ADMIN_EXPORT,
} from '../constant/step-component';
import { NotFound } from "./NotFound";

const LoadingStep = connect(state => ({
    isLoadingNextStep: state.NextStepReducer.isLoadingNextStep,
}))(
    (props) => (
        <div className={`loading-next-step ${props.isLoadingNextStep && 'on-loading-next-step'}`}>
            <div className="loading-next-step-image">
                <img className="w-100 h-100" src={require('../assets/images/loading-next-step.gif')} />
            </div>
        </div>
    )
)


class MainApp extends React.Component {

    render() {
        const { isLogin } = this.props;
        return (
            <BrowserRouter history={history}>
                <Switch>
                    <Route exact path={'/forgot-password'} component={ForgotPassword} history={history} />
                    <Route exact path={'/login'} component={Login} />
                    <Route exact path={'/pdf'} component={PDFView} />
                    <Route component={({ history, location }) => (
                        <div style={{ display: "flex" }} ref={view => { this.view = view }}>
                            <LoadingStep></LoadingStep>
                            <Sidebar history={history} location={location} />
                            <div style={{ flex: 1 }}>
                                <Header history={history} location={location} />
                                <Switch>
                                    <StepRoute exact
                                        path={'/'}
                                        component={isLogin ? UseApplication : RequestAccount}
                                        stepComponent={isLogin ? STEP_USE_APPLICATION: STEP_REQUEST_ACCOUNT}
                                    />
                                    <StepRoute path={'/hardware-setting'}
                                        component={HardwareSetting}
                                        stepComponent={STEP_HARDWARE_SETTING}
                                    />
                                    <StepRoute path={'/summary'}
                                        component={Summary}
                                        stepComponent={STEP_SUMMARY}
                                    />
                                    <StepRoute path={'/application-details'}
                                        component={ApplicationDetail}
                                        stepComponent={STEP_APPLICATION_DETAILS}
                                    />
                                    {/* <StepRoute path={'/usage-amount'}
                                        component={UsageAmount}
                                        stepComponent={STEP_USAGE_AMOUNT}
                                    /> */}
                                    {/* <StepRoute path={`/account-information`}
                                        component={ConfirmNewRegistration}
                                        stepComponent={STEP_ACCOUNT_INFORMATION}
                                    /> */}
                                    <StepRoute path={'/estimate'}
                                        component={Estimate}
                                        stepComponent={STEP_ESTIMATE_LOGIN}
                                    />
                                    <AuthenticatedRoute path={'/usage-history-inquiry'} component={UsageHistoryInquiry} />
                                    <AuthenticatedRoute path={'/change-password'} component={ChangePassword} />
                                    <AdminRoute path={'/history-inquiry-admin'}
                                        component={AdminUsageHistoryInquiry}
                                        stepComponent={USAGE_HISTORY_INQUIRY_ADMIN}
                                    />
                                    <AdminRoute path={'/admin-import'}
                                        component={AdminImport}
                                        stepComponent={ADMIN_IMPORT}
                                    />
                                    <AdminRoute path={'/admin-export'}
                                        component={AdminExport}
                                        stepComponent={ADMIN_EXPORT}
                                    />
                                    {/* <StepRoute path={''}
                                        component={RequestAccount}
                                        stepComponent={STEP_REQUEST_ACCOUNT}
                                        /> */}
                                    <StepRoute path={`/confirm-new-registration`}
                                        component={ConfirmNewRegistration}
                                        stepComponent={STEP_CONFIRM_NEW_REGISTRATION}
                                    />
                                </Switch>
                                <Route component={NotFound} />
                                <Footer />
                            </div>
                        </div>
                    )} />
                    <Route component={() => <p>not found</p>} />
                </Switch>
            </BrowserRouter>
        );
    }
}
const mapStateToProps = state => ({
    isLogin: state.LoginReducer.isLogin,
});

const mapDispatchToProps = dispatch => ({
});
const app = withTranslation()(MainApp);
export default connect(mapStateToProps, mapDispatchToProps)(app)