const FETCH_ESTIMATE = 'FETCH_ESTIMATE';
const FETCH_ESTIMATE_SUCCESS = 'FETCH_ESTIMTE_SUCCESS';
const FETCH_ESTIMATE_ERROR = 'FETCH_ESTIMATE_ERROR';

const UPDATE_ESTIME_FILE = 'UPDATE_ESTIME_FILE';
const UPDATE_ESTIME_FILE_SUCCESS = 'UPDATE_ESTIME_FILE_SUCCESS';
const UPDATE_ESTIME_FILE_ERROR = 'UPDATE_ESTIME_FILE_ERROR';

const UPDATE_IS_LOADING_ESTIMATE = 'UPDATE_IS_LOADING_ESTIMATE';
const UPDATE_ESTIMATE_FILE_STATUS = 'UPDATE_ESTIMATE_FILE_STATUS';

const GET_PDF_INFO = 'GET_PDF_INFO';
const GET_PDF_INFO_SUCCESS = 'GET_PDF_INFO_SUCCESS';
const GET_PDF_INFO_ERROR = 'GET_PDF_INFO_ERROR';

export {
    FETCH_ESTIMATE,
    FETCH_ESTIMATE_SUCCESS,
    FETCH_ESTIMATE_ERROR,
    UPDATE_ESTIME_FILE,
    UPDATE_ESTIME_FILE_SUCCESS,
    UPDATE_ESTIME_FILE_ERROR,
    UPDATE_IS_LOADING_ESTIMATE,
    UPDATE_ESTIMATE_FILE_STATUS,
    GET_PDF_INFO,
    GET_PDF_INFO_SUCCESS,
    GET_PDF_INFO_ERROR
}