import { takeLatest, put } from 'redux-saga/effects';
import { FETCH_USAGE_HISTORY } from './actionType';
import { fetchUsageHistorySuccess, fetchUsageHistoryError } from './action';
import { API } from '../../services/APIs';
import { httpService } from '../../services/httpService';
import * as responseCode from '../../constant/response-code';
import { Utils } from '../../config/utils';
import { CONST } from '../../config/const';

function* getUsageHistory(action) {
    try {
        const lang = localStorage.getItem(CONST.LANGUAGE);
        const res = yield httpService.post(API.GET_USAGE_HISTORY, { language: lang });
        if (res === "server no response") {
            action.payload.push('/login');
        }
        else if (res.code === 200) {
            if (res.status === responseCode.SUCCESS) {
                yield put(fetchUsageHistorySuccess(res.data || []));
            }
            else {
                yield put(fetchUsageHistoryError(res.message));
            }
        }
        else if (res.code === responseCode.UNAUTHORIZED) {
            Utils.logOut(action.payload);
        }
    } catch (e) {
        console.log(e);
    }
}

export function* watchFetchUsageHistory() {
    yield takeLatest(FETCH_USAGE_HISTORY, getUsageHistory)
}
