import * as actionTypes from './actionType';
import { CONST } from "../../config/const";

const DEFAULT_STATE = {
    data: null,
    data_confirm: null,
    msg: '',
    user_code: localStorage.getItem(CONST.USER_CODE),
    term: '',
    personal_information: '',
    datachange: {}
}
export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.POST_REQUEST_ACCOUNT:
            return {
                ...state
            }
        case actionTypes.POST_REQUEST_ACCOUNT_SUCCESS:
            return {
                ...state,
                user_code: action.payload
            }
        case actionTypes.POST_REQUEST_ACCOUNT_FAILED:
            return {
                ...state,
                msg: action.payload
            }
        case actionTypes.UPDATE_DATA:
            return {
                ...state,
                data: action.payload
            }
        case actionTypes.UPDATE_DATA_CONFIRM:
            return {
                ...state,
                data_confirm: action.payload
            }
        case actionTypes.GET_TERM_SUCCESS:
            return {
                ...state,
                term: action.payload
            };
        case actionTypes.GET_PERSONAL_INFORMATION_SUCCESS:
            return {
                ...state,
                personal_information: action.payload
            };
        case actionTypes.CHANGE_DATA:
            return {
                ...state,
                data: action.payload
            }
        case actionTypes.RESET_REQUEST_ACCOUNT:
            return {
                ...state,
                data: null,
                data_confirm: null,
                msg: '',
            }
        default:
            return state;
    }
}
