import { checkHardware, fetchListHardware, updateListHardware, checkHardwareError, checkHardwareSuccess, loadingHardware, updateSearchKeyWord } from "./action";
import { connect } from "react-redux";
import HardwareSetting from "./HardwareSetting";
import { changeTitle, changeRoute, fetchUpdateEstimate } from "../../redux/action/nextStepAction";

const mapStateTopProps = state => ({
    list_hardware: state.HardwareSettingReducer.list_hardware,
    total_page: state.HardwareSettingReducer.total_page,
    data_update_estimate: state.NextStepReducer.data_update_estimate,
    currentStep: state.NextStepReducer.currentStep,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    isLoading: state.HardwareSettingReducer.isLoading,
    hardware_active: state.HardwareSettingReducer.hardware_active,
    estimate_no: state.NextStepReducer.data_update_estimate.estimate_no,
    search_keyword: state.HardwareSettingReducer.search_keyword,
});

const mapDispatchToProps = dispatch => ({
    onGetListHardware: (data) => dispatch(fetchListHardware(data)),
    onCheckHardware: ({ data, hardware, history, isBack }, isNext, ref) => dispatch(checkHardware({ data, hardware, history, isBack }, isNext, ref)),
    changeRoute: (route) => dispatch(changeRoute(route)),
    changeTitle: (title) => dispatch(changeTitle(title)),
    updateListHardware: (hardware) => dispatch(updateListHardware(hardware)),
    checkHardwareError: (hardware) => dispatch(checkHardwareError(hardware)),
    checkHardwareSuccess: (hardware) => dispatch(checkHardwareSuccess(hardware)),
    fetchUpdateEstimate: (data, title, route, history, isBack) => dispatch(fetchUpdateEstimate(data, title, route, history, isBack)),
    loadingHardware: (loading) => dispatch(loadingHardware(loading)),
    updateSearchKeyWord: (keyWord) => dispatch(updateSearchKeyWord(keyWord))
});

export default connect(mapStateTopProps, mapDispatchToProps)(HardwareSetting);
