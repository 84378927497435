import React from 'react';
export const NotFound = ({...rest}) => {
    console.log({rest});
    return(
        <div>
            <p>Not found</p>
            <p>Not found</p>
            <p>Not found</p>
            <p>Not found</p>
            <p>Not found</p>
            <p>Not found</p>
        </div>
    )
}
