import { connect } from "react-redux";
import UsageHistoryInquiry from "./UsageHistoryInquiry";
import {
    fetchUsageHistory
} from './action';

const mapStateToProps = state => ({
    usage_history_data: state.UsageHistoryReducer.usage_history_data,
    isFullSideBar: state.NextStepReducer.isFullSideBar
})

const mapDispatchToProps = dispatch => ({
    fetchUsageHistory: (history) => dispatch(fetchUsageHistory(history)),
})
export default connect(mapStateToProps, mapDispatchToProps)(UsageHistoryInquiry)
