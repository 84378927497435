import React, { Component } from 'react';
import { withTranslation } from "react-i18next";

import './style.css'
import { withRouter, Route } from "react-router-dom";

import { STEP_SUMMARY } from '../../constant/step-component';

class Summary extends Component {

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.props.onGetSummary(this.props.estimate_no);
    }

    prevStep = (history) => {
        history.push(STEP_SUMMARY.prevRoute);
        this.props.onChangeTitle(STEP_SUMMARY.prevTitle);
        this.props.onChangeRoute(STEP_SUMMARY.prevRoute);
    }

    nextStep = () => {
        let isBack = this.props.currentStep > STEP_SUMMARY.step
        this.props.history.push(STEP_SUMMARY.nextRoute);
        this.props.onChangeTitle(STEP_SUMMARY.nextTitle);
        this.props.onChangeRoute(STEP_SUMMARY.nextRoute);
        if (isBack === false) {
            this.props.nextStepSuccess();
        }
    }

    render() {
        let software, hardware;
        let { isLoadingSummary, summary_data, isFullSideBar } = this.props;
        if (summary_data) {
            software = this.props.summary_data.software
        }
        if (summary_data) {
            hardware = this.props.summary_data.hardware
        }
        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <main className={'main-container summary '}>
                    <div>
                        <div className={`loading-summary ${isLoadingSummary && 'on-loading-summary'}`}>
                            <div className="loading-summary-image">
                                <img className="w-100 h-100" src={require('../../assets/images/loading-next-step.gif')} />
                            </div>
                        </div>
                        <div className="step-app prev-step"></div>
                        <div className="step-app current-step">
                            <div className="summary-warning">{'summary.Warning'.t()}</div>
                            <div className="pannel-wrapper application-sumary">
                                <div className="table-data data-software">
                                    <h3 className="title">{'summary.Software'.t()}</h3>
                                    <table className="table table-border-dots table-responsive-list table-software">
                                        <tbody>
                                            {typeof software !== 'undefined' ?
                                                (
                                                    software.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <h5 className="fs-14 f_semibold color-black summary-software-name">{item.software_name}
                                                                    <span style={{ color: '#999' }} className="date-time f_regular">&nbsp;&nbsp;-&nbsp;&nbsp;</span><span
                                                                        style={{ color: '#999' }} className="f_regular">{item.software_version}</span></h5>
                                                                <p className="summary-overview">{item.overview}</p>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) :
                                                (
                                                    <tr>
                                                        <td className="text-center">{'notification.does not have any software'.t()}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <hr style={{ margin: 0 }} />
                                <div className="table-data data-hardware">
                                    <h3 className="title">{'summary.Hardware'.t()}</h3>
                                    <table className="table table-border-dots table-responsive-list" >
                                        {typeof hardware !== 'undefined' ?
                                            (
                                                <tbody >
                                                    <div>
                                                        <tr>
                                                            <td>{'summary.Instance'.t()}</td>
                                                            {hardware.map((e, index) => (
                                                                <td>{e.classification_name}</td>
                                                            ))}
                                                        </tr>
                                                        {/* <tr>
                                                            td>{'summary.Machine'.t()}</td>
                                                            <td>{hardware?.hardware_name}</td>
                                                            </tr> */}
                                                        <tr>
                                                            <td>{'summary.Quantity'.t()}</td>
                                                            {hardware.map((e, index) => (
                                                                <td>{e.quantity}</td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td>CPU</td>
                                                            {hardware.map((e, index) => (
                                                                <td>{e.cpu_frequency} {e.cpu_number_of_cores} {e.name}</td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td>{'summary.Memory'.t()}</td>
                                                            {hardware.map((e, index) => (
                                                                <td>{e.memory_type} {e.memory_qty} {e.memory_size}</td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td>{'summary.Storage'.t()}</td>
                                                            {hardware.map((e, index) => (
                                                                <td>{e.storage_size} {e.storage_type}x{e.storage_qty} (RAID {e.storage_raid})</td>
                                                            ))}
                                                        </tr>
                                                    </div>
                                                </tbody>
                                            ) :
                                            (
                                                <tbody>
                                                    <tr>
                                                        <td className="text-center" colSpan={5}>{'notification.does not have any hardware'.t()}</td>
                                                    </tr>
                                                </tbody>
                                            )
                                        }
                                    </table>
                                </div>
                            </div>
                            <div className="application-step-actions tw-buttons summary-application-actions">
                                <div className="next-step text-center">
                                    {/* <button id="back-hardware" className="btn btn-secondary" type="button">{'summary.Back'.t()}</button>
                                <button id="sumary-next" className="btn btn-primary">{'summary.Next'.t()}</button> */}
                                    <Route render={({ history }) => (
                                        <button
                                            type='button'
                                            className="btn btn-secondary btn-lg"
                                            onClick={() => this.prevStep(history)}
                                        >
                                            {'summary.Back'.t()}
                                        </button>
                                    )} />
                                    <button
                                        type='button'
                                        className="btn btn-primary btn-lg"
                                        onClick={() => this.nextStep()}
                                    >
                                        {'summary.Next'.t()}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="step-app next-step"></div>
                    </div>
                </main>
            </div>
        )
    }
}

export default withTranslation()(withRouter(Summary))
