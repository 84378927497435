import { connect } from "react-redux";
import UsageAmount from "./UsageAmount";
import { fetchUsageAmount } from './action';
import { changeRoute, changeTitle, nextStepSuccess } from '../../redux/action/nextStepAction';

const mapStateToProps = state => ({
    // classification_data: state.UseApplicationReducer.classification_data,
    usage_amount_data: state.UsageAmountReducer.usage_amount_data,
    estimate_no: state.NextStepReducer.data_update_estimate.estimate_no,
    currentStep: state.NextStepReducer.currentStep,
    isLogin: state.LoginReducer.isLogin,
    isFullSideBar: state.NextStepReducer.isFullSideBar
})

const mapDispatchToProps = dispatch => ({
    // onGetClassification: (type) => dispatch(fetchClassification(type)),
    onChangeTitle: (title) => dispatch(changeTitle(title)),
    onChangeRoute: (route) => dispatch(changeRoute(route)),
    onFetchUsageAmount: (estimate_no) => dispatch(fetchUsageAmount(estimate_no)),
    nextStepSuccess: () => dispatch(nextStepSuccess())
})
export default connect(mapStateToProps, mapDispatchToProps)(UsageAmount)