import {
    CALCULATE_SUPPORT_PRICE_SUCCESS, CALCULATING,
    FETCH_LIST_SUPPORT,
    FETCH_LIST_SUPPORT_ERROR,
    FETCH_LIST_SUPPORT_SUCCESS,
    CALCULATE_SUPPORT_PRICE_ERROR,
    UPDATE_LIST_SUPPORTS,
    UPDATE_END_MONTH,
    UPDATE_START_DATE,
    UPDATE_PURPOSE_OF_USE,
    RESET_IS_NEXT,
    UPDATE_ORDER_DAY
} from "./actionType";
import { Utils } from '../../config/utils';
import { CLEAR_ESTIMATE } from '../../redux/actionType/nextStepActionType';

const DEFAULT_STATE = {
    loading: false,
    loading_price: false,
    list_supports: [],
    list_supports_active: [],
    support_code: [],
    error: null,
    is_next: false,
    start_date: '',
    month: '',
    purpose_of_use: ''
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_LIST_SUPPORT:
            return {
                ...state,
                loading: action.payload
            };
        case FETCH_LIST_SUPPORT_SUCCESS:
            let list_supports_active = [...state.list_supports_active];
            let _list_supports_update;
            if (list_supports_active.length > 0) {
                _list_supports_update = Utils.updateValue(list_supports_active, action.payload, "support_code");
            } else {
                _list_supports_update = action.payload
            }
            return {
                ...state,
                list_supports: _list_supports_update,
            };
        case FETCH_LIST_SUPPORT_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case UPDATE_LIST_SUPPORTS:
            const index = action.payload;
            const list_supports = [...state.list_supports];
            list_supports[index].checked = !list_supports[index].checked;
            const list_support_active = list_supports.filter(e => e.checked);
            const items = list_supports.filter(e => e.checked && e.fixed_amount !== "2");
            const support_code = [];
            items.forEach(e => support_code.push(e.support_code));
            return {
                ...state,
                list_supports,
                support_code,
                list_supports_active: list_support_active,
                is_next: false
            };
        case CALCULATING:
            return {
                ...state,
                loading_price: true
            };
        case CALCULATE_SUPPORT_PRICE_SUCCESS:
            const items_update_price = action.payload;
            const _list_supports = [...state.list_supports];
            _list_supports.forEach(e => {
                items_update_price.forEach(j => {
                    if (j.support_code === e.support_code) {
                        e.price = j.price;
                        e.fixed_amount = j.fixed_amount
                    }
                })
            });
            console.log({ _list_supports });

            let _list_supports_active = _list_supports.filter(sp => sp.checked)
            return {
                ...state,
                list_supports: _list_supports,
                list_supports_active: _list_supports_active,
                is_next: true,
                loading_price: false
            };
        case CALCULATE_SUPPORT_PRICE_ERROR:
            return {
                ...state,
                loading_price: false
            }
        case UPDATE_START_DATE:
            return {
                ...state,
                start_date: action.payload,
                is_next: false
            }
        case UPDATE_PURPOSE_OF_USE:
            return {
                ...state,
                purpose_of_use: action.payload,
                is_next: false
            }
        case UPDATE_END_MONTH:
            return {
                ...state,
                month: action.payload,
                is_next: false
            }
        case RESET_IS_NEXT:
            return {
                ...state,
                is_next: false
            }
        case UPDATE_ORDER_DAY:
            console.log({action: action.payload});
            const new_list_supports = [...state.list_supports].map(item => {
                if(item.support_code === action.payload.support_code) {
                    return action.payload
                }
                return item
            })
            const supports_active = new_list_supports.filter(e => e.checked);
            return {
                ...state,
                list_supports: new_list_supports,
                list_supports_active: supports_active
            }
        case CLEAR_ESTIMATE:
            return {
                ...state,
                loading: false,
                loading_price: false,
                list_supports: [],
                list_supports_active: [],
                support_code: [],
                error: null,
                is_next: false,
                start_date: '',
                month: '',
                purpose_of_use: ''
            }
        default:
            return state;
    }
}
