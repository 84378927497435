import React, { Component } from 'react';
import './admin-usage-history-inquiry.css';
import { withTranslation } from "react-i18next";
import { Utils } from '../../config/utils';
import DatePicker from "react-datepicker";
import Alert from 'react-s-alert';
import moment from 'moment';
import { CONST } from '../../config/const';
import MaskedTextInput from "react-text-mask";


class AdminUsageHistoryInquiry extends Component {

    constructor(props) {
        super(props);
        this.getAdminUsageHistory = this.getAdminUsageHistory.bind(this);
        this.clearSelect = this.clearSelect.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.selectOrder = this.selectOrder.bind(this);
        this.confirmEstimate = this.confirmEstimate.bind(this);
        this.changeRaw = this.changeRaw.bind(this);
    }

    componentDidMount() {
        const { history } = this.props;
        const lang = localStorage.getItem(CONST.LANGUAGE)
        this.props.searchUser(history);
        this.props.searchSoftware();
        this.props.searchHardware();
        this.props.searchSupport();
        let { formSearch } = this.props;
        formSearch = {
            language: lang
        };
        // this.props.onGetAdminUsage(formSearch);
    }

    getAdminUsageHistory(e) {
        e.preventDefault();
        let { formSearch } = this.props;
        const lang = localStorage.getItem(CONST.LANGUAGE)
        formSearch = {
            ...formSearch,
            start_date: formSearch.start_date !== '' ? Utils.getStartDate(formSearch.start_date) : '',
            end_date: formSearch.end_date !== '' ? Utils.getStartDate(formSearch.end_date) : '',
            language: lang
        };
        this.props.onGetAdminUsage(formSearch)
    };

    handleChange = (event) => {
        this.props.updateSearchForm(event.target.name, event.target.value);
    };

    changeUser = (event) => {
        let { list_user } = this.props;
        let userChecked = list_user.filter(item => item.user_code === event.target.value)[0];
        let userName = "";
        if (userChecked) {
            userName = `${userChecked.user_first_name} ${userChecked.user_last_name}`;
        }
        this.props.updateSearchForm(event.target.name, event.target.value);
        this.props.updateUserName(userName);
    };

    changeStartDate(start_date) {
        const { formSearch } = this.props;
        if (start_date !== null && formSearch.end_date && Utils.getStartDate(start_date) > Utils.getStartDate(formSearch.end_date)) {
            Alert.warning('notification.start date must be smaller than end month'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            });
            return;
        } else {
            this.props.updateSearchForm('start_date', start_date);
        }
    };

    changeEndMonth(end_date) {
        const { formSearch } = this.props;
        if (end_date !== null && formSearch.start_date && Utils.getStartDate(formSearch.start_date) > Utils.getStartDate(end_date)) {
            Alert.warning('notification.start date must be smaller than end month'.t(), {
                position: 'top-right',
                effect: 'scale',
                timeout: require("../../config/const").TIMEOUT,
                offset: -10
            });
            return;
        } else {
            this.props.updateSearchForm('end_date', end_date);
        }
    };

    clearSelect(event) {
        event.preventDefault();
        // xoá bỏ toàn bộ các order đang có contract flag => 0;
        const { orders, onUpdateOrders } = this.props;
        orders.forEach((e, i) => {
            e.contracted_flag = '0';
            onUpdateOrders(e, i)
        })
    };

    selectAll(event) {
        event.preventDefault();
        // chọn toàn bộ các order đang có contract flag => 1;
        const { orders, onUpdateOrders } = this.props;
        orders.forEach((e, i) => {
            e.contracted_flag = '1';
            onUpdateOrders(e, i)
        })
    };

    selectOrder(order, i) {
        const { onUpdateOrders } = this.props;
        if (order.contracted_flag === '1') {
            order.contracted_flag = '0'
        } else {
            order.contracted_flag = '1'
        }
        onUpdateOrders(order, i);
    }

    confirmEstimate(event) {
        event.preventDefault();
        const { orders } = this.props;
        let request = {};
        orders.forEach((e) => {
            request[`${e.estimate_no}`] = parseInt(e.contracted_flag)
        });
        Utils.debounceSearch(request, this.props.onUpdateEstimateStatus)
    };

    changeRaw(e) {
        let {value} = e.target;
        // this.setState({[e.target.name]: value})
    }

    handleReset = () => {
        this.props.resetSearch();
    }

    render() {
        const lang = localStorage.getItem(CONST.LANGUAGE)
        const { list_user, list_software, list_hardware, list_support, formSearch, user_name, orders, isFullSideBar } = this.props;
        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <main className={'main-container summary'}>
                    <Alert stack={{ limit: 1 }} />
                    <div className="pannel-wrapper admin-history-inquiry">
                        <form className="find-history-inquiry" onSubmit={this.getAdminUsageHistory}>
                            <div className="fields">
                                <div className="multiple-fields row">
                                    <div className="field col-xl-3 col-lg-6 col-12">
                                        <label className="label">{'admin usage history inquiry.User'.t()}</label>
                                        {/* <label className="label">{'admin usage history inquiry.User first name'.t()}</label> */}
                                        <div className="control control-user">
                                            <select 
                                                name="user_code"
                                                value={formSearch?.user_code}
                                                onChange={(event) => this.changeUser(event)} >
                                                <option value="">{'admin usage history inquiry.choose user'.t()}</option>
                                                {list_user?.map((user, index) =>
                                                    <option key={index} value={user?.user_code}>{user?.user_code}</option>
                                                    // <option style={{ maxWidth: '500px', overflow: 'hidden' }} key={index} value={user?.user_first_name}>{user?.user_first_name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="field col-xl-3 col-lg-6 col-12">
                                        {/* <label className="label">{'admin usage history inquiry.User'.t()}</label> */}
                                        <label className="label">{'admin usage history inquiry.User name'.t()}</label>
                                        <div className="control control-user">
                                            <input 
                                                name="name"
                                                className="form-control" 
                                                placeholder={'admin usage history inquiry.User name'.t()}
                                                value={formSearch?.name}
                                                onChange={(event) => this.handleChange(event)}    
                                            ></input>
                                            {/* <select name="user_name" onChange={(event) => this.changeUser(event)} >
                                                <option value="">{'admin usage history inquiry.User name'.t()}</option>
                                                {list_user?.map((user, index) =>
                                                    // <option key={index} value={user?.user_code}>{user?.user_first_name} {user?.user_last_name}</option>
                                                    <option style={{ maxWidth: '500px', overflow: 'hidden' }} key={index} value={user?.user_last_name}>{user?.user_last_name}</option>
                                                )}
                                            </select> */}
                                        </div>
                                    </div>
                                    {/* {
										lang === 'en' ?
											<div className="field col-xl-4 col-lg-6 col-12 line" >
												<label className="label">&nbsp;<div className="control">Mr/Ms: {user_name}</div></label>
											</div>
											:
											<div className="field col-xl-4 col-lg-6 col-12 line " style={{ flexDirection: "column" }}>
												<label className="label">&nbsp;<div className="control">{user_name} <div style={{ position: "absolute", right: 0 }}>様</div></div></label>
											</div>
									} */}
                                    <div className="field col-xl-3 col-lg-6 col-12">
                                        <label className="label">{'admin usage history inquiry.Application Date'.t()}</label>
                                        <div className="control ">
                                            <DatePicker
                                                selected={formSearch.start_date ? new Date(formSearch.start_date) : ''}
                                                onChange={date => this.changeStartDate(date)}
                                                minDate={new Date()}
                                                dateFormat="yyyy/MM/dd"
                                                placeholderText={"YYYY/MM/DD"}
                                                name="start_date"
                                                id="start-date"
                                                autoComplete="off"
                                                customInput={
                                                    <MaskedTextInput
                                                        type="tel"
                                                        onChange={this.changeRaw}
                                                        mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="field col-xl-3 col-lg-6 col-12">
                                        <label className="label">&nbsp;</label>
                                        <div className="control ">
                                            <DatePicker
                                                selected={formSearch.end_date ? new Date(formSearch.end_date) : ''}
                                                onChange={date => this.changeEndMonth(date)}
                                                name="end_month"
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                placeholderText={" YYYY/MM/DD"}
                                                id="end-month"
                                                onChangeRaw={this.changeRaw}
                                                customInput={
                                                    <MaskedTextInput
                                                        type="tel"
                                                        onChange={this.changeRaw}
                                                        mask={[/\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/]}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="field col-xl-3 col-lg-6 col-12 contact-status">
                                        <label className="label">{'admin usage history inquiry.Contact Status'.t()}</label>
                                        <div className="control row">
                                            <div className="custom-radio signed col-6">
                                                <input type="radio" checked={formSearch?.contracted_flag == '1'} name="contracted_flag" value="1" onChange={(event) => this.handleChange(event)} autoComplete="off"
                                                    id="signed" className="custom-control-input" />
                                                <label className="custom-control-label text-nowrap" htmlFor="signed">{'admin usage history inquiry.Signed'.t()}</label>
                                            </div>
                                            <div className="custom-radio unsigned col-6">
                                                <input type="radio" checked={formSearch?.contracted_flag == '0'} name="contracted_flag" value="0" onChange={(event) => this.handleChange(event)}
                                                    id="unsigned" className="custom-control-input" />
                                                <label className="custom-control-label text-nowrap" htmlFor="unsigned">{'admin usage history inquiry.Unsigned'.t()}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="fields admin-usage-history">
								<div className="multiple-fields row">


								</div>

							</div> */}
                            <div className="field">
                                <div className="multiple-fields row">
                                    <div className="field col-xl-3 col-lg-6 col-12">
                                        <label className="label">{'admin usage history inquiry.Estimate Number'.t()}</label>
                                        <div className="control">
                                            <input type="tel" 
                                                onChange={(event) => this.handleChange(event)} 
                                                autoComplete="off" value={formSearch?.estimate_no}
                                                className="form-control" name="estimate_no" id="estimate_no" 
                                                placeholder={'admin usage history inquiry.Estimate Number'.t()} 
                                            />
                                        </div>
                                    </div>
                                    <div className="field col-xl-3 col-lg-6 col-12">
                                        <label className="label">{'admin usage history inquiry.Software'.t()}</label>
                                        <div className="control">
                                            <select 
                                                name="software_code"
                                                value={formSearch?.software_code}
                                                onChange={(event) => this.handleChange(event)}
                                            >
                                                <option value="">{'admin usage history inquiry.choose software'.t()}</option>
                                                {list_software?.map((software, index) =>
                                                    <option key={index} value={software?.software_code}>{software?.software_name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="field col-xl-3 col-lg-6 col-12">
                                        <label className="label">{'admin usage history inquiry.Hardware'.t()}</label>
                                        <div className="control">
                                            <select 
                                                name="hardware_code"
                                                value={formSearch?.hardware_code}
                                                onChange={(event) => this.handleChange(event)}
                                            >
                                                <option value="">{'admin usage history inquiry.choose hardware'.t()}</option>
                                                {list_hardware?.map((hardware, index) =>
                                                    <option key={index} value={hardware?.hardware_code}>{hardware?.hardware_name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="field col-xl-3 col-lg-6 col-12">
                                        <label className="label">{'admin usage history inquiry.Support'.t()}</label>
                                        <div className="control">
                                            <select 
                                                name="support_code"
                                                value={formSearch?.support_code}
                                                onChange={(event) => this.handleChange(event)}
                                            >
                                                <option value="">{'admin usage history inquiry.choose support'.t()}</option>
                                                {list_support?.map((support, index) =>
                                                    <option key={index} value={support?.support_code}>{support?.support_name}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-action">
                                <button id="search" className="btn btn-primary" type="submit">{'admin usage history inquiry.search'.t()}</button>
                                <button 
                                    id="reset" 
                                    className="btn btn-primary ml-2" 
                                    type="button"
                                    onClick={this.handleReset}
                                >
                                    {'admin usage history inquiry.reset'.t()}
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="pannel-wrapper history-inquiry-result">
                        <div className="box-actions">
                            <button className="btn clear" type="button" onClick={this.clearSelect}>{'admin usage history inquiry.Clear'.t()}</button>
                            <button className="btn" type="button" onClick={this.selectAll}>{'admin usage history inquiry.All'.t()}</button>
                        </div>
                        <div className="box-radius-top">
                            <table className="table table-border-solid table-border-all table-responsive-list history-inquiry-admin" style={{ fontSize: 14, color: "#555" }}>
                                <thead style={{ lineHeight: 1.7 }}>
                                    <tr>
                                        <th className="t-center col-no" rowSpan="2">{'admin usage history inquiry.No'.t()}</th>
                                        <th className="t-center col-contracted" rowSpan="2">{'admin usage history inquiry.Contracted'.t()}</th>
                                        <th className="t-center col-estimate" rowSpan="2" style={{ maxWidth: '300px' }}>{'admin usage history inquiry.User'.t()}</th>
                                        <th className="t-center col-estimate" rowSpan="2">{'admin usage history inquiry.Estimate Number'.t()}</th>
                                        <th className="t-center col-application" rowSpan="2">{'admin usage history inquiry.Application Date'.t()}</th>
                                        <th className="t-center col-software" style={{ minWidth: "300px" }} rowSpan="2">{'admin usage history inquiry.Software'.t()}</th>
                                        <th className="t-center col-hardware" style={{ minWidth: "300px" }} rowSpan="2">{'admin usage history inquiry.Hardware'.t()}</th>
                                        <th className="t-center col-support" style={{ minWidth: "300px" }} rowSpan="2">{'admin usage history inquiry.Support'.t()}</th>
                                        <th className="t-center" colSpan="4">
                                            <div>{'admin usage history inquiry.Usage Period'.t()}</div>
                                        </th>
                                    </tr>
                                    <tr className="th-row-tw">
                                        <th className="t-center col-start">{'admin usage history inquiry.Start Date'.t()}</th>
                                        <th className="t-center col-end">{'admin usage history inquiry.End Date'.t()}</th>
                                        <th className="t-center col-remaining">{'admin usage history inquiry.Remaining Days'.t()}</th>
                                        <th className="t-center col-price">{'admin usage history inquiry.Price'.t()}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        orders.length > 0 && orders.map((e, i) => (
                                            <tr key={i}>
                                                <td className="t-center" data-th="No:">{i + 1}</td>
                                                <td className="t-center custom-checkbox" data-th="Contracted:">
                                                    <input type="checkbox" id={i} name={i} checked={e.contracted_flag === '1'} className="custom-control-input" onChange={this.selectOrder.bind(this, e, i)} />
                                                    <label className="custom-control-label" htmlFor={i} >&nbsp;</label>
                                                </td>
                                                <td className="t-center" data-th="User:" style={{ maxWidth: '300px', overflow: 'hidden' }}>
                                                    <div style={{ overflow: 'hidden' }}><span className="label"><p>{e.user_code}</p></span>
                                                         {
                                                            lang === "GB" ?
                                                            <span className="rule">{e.user_first_name}&nbsp;{e.user_last_name}</span>
                                                            : <span className="rule">{e.user_last_name}&nbsp;{e.user_first_name}</span> 
                                                        }

                                                     </div>
                                                </td>
                                                <td className="t-center" data-th="Estimate number:">{e.estimate_no}</td>
                                                <td className="t-center" data-th="Application date:">{e.application_date}</td>
                                                <td className="" data-th="Software:">
                                                    <ul>
                                                        {e.items?.software?.map((el, j) => (
                                                            <li key={j}>{el.product_name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td className="" data-th="Hardware:">
                                                    <ul>
                                                        {e.items?.hardware?.product_name}                                                     
                                                    </ul>
                                                </td>
                                                <td className="" data-th="Support:">
                                                    <ul>
                                                        {e.items?.support?.map((el, j) => (
                                                            <li key={j}>{el.product_name}</li>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td className="t-center" data-th="Start date:">{e.start_date}</td>
                                                <td className="t-center" data-th="End date:">{e.end_date}</td>
                                                <td className="t-center" data-th="Remaining days:">{e.remaining_days}</td>
                                                <td className="t-center" data-th="Price:">&#165;&nbsp;{Utils.formatCurrency(parseFloat(e.total_price))}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>

                            <div className="list-usage-history-admin">
                                {
                                    orders.length > 0 && orders.map((e, i) => (
                                        <div className="usage-history-admin-item">
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h" >{'admin usage history inquiry.No'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his">{i + 1}</div>
                                            </div>
                                            <div className="t-center custom-checkbox" style={{ display: 'flex' }}>
                                                <div className="text_title_h" >{'admin usage history inquiry.Contracted'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his">
                                                    <input type="checkbox" id={i} name={i} checked={e.contracted_flag === '1'} className="custom-control-input" onChange={this.selectOrder.bind(this, e, i)} />
                                                    <label className="custom-control-label" htmlFor={i} >&nbsp;</label>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h"  >{'admin usage history inquiry.User'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his">{e.user_first_name}&nbsp;{e.user_last_name}</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h"  >{'admin usage history inquiry.Estimate Number'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his">{e.estimate_no}</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h"  >{'admin usage history inquiry.Application Date'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his">{e.application_date}</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h"  >{'admin usage history inquiry.Software'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his" >
                                                    {e.items?.software?.map((el, j) => (
                                                        <li key={j}>{el.product_name}</li>
                                                    ))}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h"  >{'admin usage history inquiry.Hardware'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his" >
                                                    {e.items?.hardware?.product_name}                                                    
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h"  >{'admin usage history inquiry.Support'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his"  >
                                                    {e.items?.support?.map((el, j) => (
                                                        <li key={j}>{el.product_name}</li>
                                                    ))}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }} >
                                                <div className="text_title_h"  >{'admin usage history inquiry.Start Date'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his">{e.start_date}</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h"  >{'admin usage history inquiry.End Date'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his">{e.end_date}</div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <div className="text_title_h"  >{'admin usage history inquiry.Remaining Days'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</div>
                                                <div className="text_content_his">{Utils.formatCurrency(parseFloat(e.total_price))}</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="application-step-actions">
                        <div className="next-step text-center">
                            <button id="confirm" className="btn btn-primary" type="button" onClick={this.confirmEstimate}>{'admin usage history inquiry.Confirm'.t()}</button>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default withTranslation()(AdminUsageHistoryInquiry)
