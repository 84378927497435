import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './style.css'
import { withTranslation } from "react-i18next";
import Alert from 'react-s-alert';
import { CONST } from "../../config/const";
import { Utils } from "../../config/utils";
import { STEP_CONFIRM_NEW_REGISTRATION, STEP_ACCOUNT_INFORMATION } from "../../constant/step-component";

class ConfirmNewRegistration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            hidePass: false,
            hideConfirmPass: false,
        }
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        console.log("DID MOUNT CONFIRM")
        if (this.props.isLogin) {
            const user_code = localStorage.getItem(CONST.USER_CODE);
            this.props.onGetAccountDetail(user_code);
        }
        this.props.getTerm();
        this.props.getPersonalInfor();
    }

    prevStep = () => {
        const { isLogin, history } = this.props;
        if (isLogin) {
            this.props.history.push(STEP_ACCOUNT_INFORMATION.prevRoute);
        } else {
            // this.props.history.push(STEP_CONFIRM_NEW_REGISTRATION.prevRoute);
            history.push(STEP_CONFIRM_NEW_REGISTRATION.prevRoute);
        }
    }

    nextStep = async () => {
        const { isLogin, history } = this.props;
        console.log({isLogin })
        if (isLogin) {
            await this.props.nextStepSuccess();
            await this.props.updateOnEstimate(true);
            this.props.history.push(STEP_ACCOUNT_INFORMATION.nextRoute);
        } else {
            const { data_confirm, history } = this.props;
            this.props.onRequestAccount(data_confirm, history)
            // Utils.logOut(history);
        }
    }

    TogglePass = () => {
        const { hidePass, } = this.state
        this.setState({ hidePass: !hidePass })
    }
    ToggleConfirmPass = () => {
        const { hideConfirmPass, } = this.state
        this.setState({ hideConfirmPass: !hideConfirmPass })
    }
    render() {
        const { show } = this.state;
        let data_account = null;
        const { data_confirm, isFullSideBar, term, personal_information, account_detail, isLogin, data } = this.props;
        let phone_1 = "+81" + data?.phone_1;
        let phone_2 = data?.phone_2;
        let phone_3 = data?.phone_3;
        let fax_number_1 = data?.fax_1 && "+81" + data?.fax_1;
        let fax_number_2 = data?.fax_2;
        let fax_number_3 = data?.fax_3;
        if (isLogin) {
            data_account = account_detail
        } else {
            data_account = data_confirm
        }
        const language = localStorage.getItem(CONST.LANGUAGE)

        // if (data_account?.fax_number && data_account?.fax_number !== null) {
        //     const fax = data_account.fax_number;
        //     fax_number_1 = fax.slice(fax.length - 13, fax.length - 8);
        //     fax_number_2 = fax.slice(fax.length - 8, fax.length - 4);
        //     fax_number_3 = fax.slice(fax.length - 4, fax.length);
        // }

        // if (data_account !== null) {
        //     const phone = data_account?.company_phone_number;
        //     phone_1 = phone.slice(phone.length - 13, phone.length - 8);
        //     phone_2 = phone.slice(phone.length - 8, phone.length - 4);
        //     phone_3 = phone.slice(phone.length - 4, phone.length);
        // }

        console.log({data});

        const windowOpen1 = () => {
            window.open('/Science_Cloud_Terms_Of_Service.pdf','_blank', 'fullscreen=yes')
          }
        const windowOpen2 = () => {
            window.open('/About_The_Handing_Of_Personal_Information.pdf','_blank', 'fullscreen=yes')
          }
        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <main className={'main-container summary'}>
                    <Alert stack={{ limit: 1 }} />
                    {/* <div className={`modal-estimate ${show ? 'show-modal-estimate' : ''}`}>
                        <div className="modal-estimate-content">
                            <div className="modal-estimate-title">
                                <p>{'use application.Notification'.t()}</p>
                            </div>
                            <div className="modal-estimate-body">
                                <p className='text-primary' style={{ textAlign: 'center', padding: 20 }}>{'notification.the selected data will be deleted! Do you want to continue?'.t()}</p>
                            </div>
                            <div className="modal-estimate-footer">
                                <button className='btn btn-primary ml-2' onClick={this.closeModalAndConfirm}>{'notification.OK'.t()}</button>
                                <button className='btn btn-secondary ml-2' onClick={this.closeModalAndCanel}>{'notification.Canel'.t()}</button>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <div className="step-app prev-step" />
                        <div className="step-app current-step">
                            <div className="pannel-wrapper panel-request-account panel-confirm-new-registration">
                                <form className="form-request-account">
                                    <div className="fields">
                                        <label className="label">{'confirm registration.Login ID'.t()}<em> *</em></label>
                                        <div className="multiple-fields">
                                            <div className="field">
                                                <div className="control">
                                                    <input type="text" className="form-control" name="login-id"
                                                        id="login-id" placeholder={"confirm registration.Email Address".t()}
                                                        value={data_account?.user_code} disabled={true} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {
                                        !isLogin &&
                                        <div className="fields">
                                            <div className="multiple-fields">
                                                <div className="field">
                                                    <label className="label">{'request account.Password'.t()}<em> *</em></label>
                                                    <div className="control">
                                                        <input type={this.state.hidePass ? "text" : "password"} className="form-control" name="password"
                                                            id="password" value={data_account?.password} disabled={true} />
                                                        <a className="eye"  ><i onClick={this.TogglePass} className={`fas  ${this.state.hidePass ? "fa-eye fa-eye-slash" : "fa-eye"}`}></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="fields">
                                        <div className="multiple-fields">
                                            {
                                                !isLogin &&
                                                <div className="field">
                                                    <label className="label">{'request account.Password Confirmation'.t()}<em> *</em></label>
                                                    <div className="control">
                                                        <input type={this.state.hideConfirmPass ? "text" : "password"} className="form-control" name="confirmation-password"
                                                            id="confirmation-password" value={data_account?.password} disabled={true} />
                                                        <a className="eye"><i onClick={this.ToggleConfirmPass} className={`fas  ${this.state.hideConfirmPass ? "fa-eye fa-eye-slash" : "fa-eye"}`}></i></a>
                                                    </div>
                                                </div>
                                            }
                                            <div className="field">
                                                <label className="label">{'confirm registration.Keyword for re-application'.t()}<em> *</em></label>
                                                <div className="control">
                                                    <input type="text" className="form-control" name="re-application"
                                                        id="re-application" value={data_account?.keyword} disabled={true} />
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="fields">
                                        <label className="label">{'confirm registration.Name'.t()}<em> *</em></label>
                                        {
                                            language === 'JP' ? 
                                            <div className="multiple-fields">
                                                <div className="field name">
                                                    <div className="control">
                                                        <input type="text" className="form-control" name="last-name"
                                                            id="last-name" placeholder={"confirm registration.Last Name".t()} value={data_account?.user_last_name} disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="field name">
                                                    <div className="control">
                                                        <input type="text" className="form-control" name="first-name"
                                                            id="first-name" placeholder={"confirm registration.First Name".t()} value={data_account?.user_first_name} disabled={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="multiple-fields">
                                                <div className="field name">
                                                    <div className="control">
                                                        <input type="text" className="form-control" name="first-name"
                                                            id="first-name" placeholder={"confirm registration.First Name".t()} value={data_account?.user_first_name} disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="field name">
                                                    <div className="control">
                                                        <input type="text" className="form-control" name="last-name"
                                                            id="last-name" placeholder={"confirm registration.Last Name".t()} value={data_account?.user_last_name} disabled={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="fields">
                                        <label className="label">{'confirm registration.Name (Kana)'.t()}<em> *</em></label>
                                        {
                                            language === 'JP' ? 
                                            <div className="multiple-fields">
                                                <div className="field name">
                                                    <div className="control">
                                                        <input type="text" className="form-control" name="last-name-kana"
                                                            id="last-name-kana" placeholder={"confirm registration.Last Name".t() + "confirm registration.(Kana)".t()} value={data_account?.user_last_kana} disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="field name">
                                                    <div className="control">
                                                        <input type="text" className="form-control" name="first-name-kana"
                                                            id="first-name-kana" placeholder={"confirm registration.First Name".t() + "confirm registration.(Kana)".t()}
                                                            value={data_account?.user_first_kana} disabled={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="multiple-fields">
                                                <div className="field name">
                                                    <div className="control">
                                                        <input type="text" className="form-control" name="first-name-kana"
                                                            id="first-name-kana" placeholder={"confirm registration.First Name".t() + "confirm registration.(Kana)".t()}
                                                            value={data_account?.user_first_kana} disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="field name">
                                                    <div className="control">
                                                        <input type="text" className="form-control" name="last-name-kana"
                                                            id="last-name-kana" placeholder={"confirm registration.Last Name".t() + "confirm registration.(Kana)".t()} value={data_account?.user_last_kana} disabled={true} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="fields">
                                        <label className="label">{'confirm registration.Company Name'.t()}<em> *</em></label>
                                        <div className="control">
                                            <input type="text" className="form-control" name="company" id="company"
                                                value={data_account?.company_name} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="fields">
                                        <label className="label">{'confirm registration.Company Name'.t()} {'confirm registration.(Kana)'.t()}<em> *</em></label>
                                        <div className="control">
                                            <input type="text" className="form-control" name="company-kana"
                                                id="company-kana" value={data_account?.company_kana} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="fields">
                                        <label className="label">{'confirm registration.Department'.t()}</label>
                                        <div className="control">
                                            <input type="text" className="form-control" name="department" id="department"
                                                value={data_account?.department} disabled={true} />
                                        </div>
                                    </div>
                                    <div className="fields">
                                        <div className="multiple-fields">
                                            <div className="field">
                                                <label className="label">{'confirm registration.Position'.t()}</label>
                                                <div className="control">
                                                    <input type="text" className="form-control" name="position"
                                                        id="position" value={data_account?.position} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fields fields-in-field">
                                        <label className="label">{'confirm registration.Phone Number'.t()}<em> *</em></label>
                                        <div className="multiple-fields">
                                            <div className="field phone">
                                                <div className="multiple-fields three-fields line-between">
                                                    <div className="field">
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="phone-1"
                                                                id="phone-1" value={phone_1} disabled={true} />
                                                        </div>
                                                    </div>
                                                    <div className="field">
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="phone-2"
                                                                id="phone-2" value={phone_2} disabled={true} />
                                                        </div>
                                                    </div>
                                                    <div className="field">
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="phone-3"
                                                                id="phone-3" value={phone_3} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fields">
                                        <label className="label">{'confirm registration.Email Address'.t()}</label>
                                        <div className="multiple-fields">
                                            <div className="field">
                                                <div className="control">
                                                    <input type="text" className="form-control" name="login-id"
                                                        id="login-id" 
                                                        value={data_account?.mail_address} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fields fields-in-field">
                                        <label className="label">{'confirm registration.Postal Code'.t()} </label>
                                        <div className="multiple-fields">
                                            <div className="field portal">
                                                <div className="multiple-fields line-between">
                                                    <div className="field" style={{ width: '50%' }}>
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="postalcode-1"
                                                                id="postalcode-1" value={data_account?.postal_code1} disabled={true} />
                                                        </div>
                                                    </div>
                                                    <div className="field" style={{ width: '50%' }}>
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="postalcode-2"
                                                                id="postalcode-2" value={data_account?.postal_code2} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fields">
                                        <div className="multiple-fields">
                                            <div className="field">
                                                <label className="label">{'confirm registration.Address'.t()}</label>
                                                <div className="control">
                                                    <input type="text" className="form-control" name="address"
                                                        id="address" value={data_account?.street_address1} disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="fields fields-in-field">
                                        <label className="label">{'confirm registration.FAX Number'.t()}</label>
                                        <div className="multiple-fields">
                                            <div className="field fax">
                                                <div className="multiple-fields three-fields line-between">
                                                    <div className="field">
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="fax-1"
                                                                id="fax-1" value={fax_number_1} disabled={true} />
                                                        </div>
                                                    </div>
                                                    <div className="field">
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="fax-2"
                                                                id="fax-2" value={fax_number_2} disabled={true} />
                                                        </div>
                                                    </div>
                                                    <div className="field">
                                                        <div className="control">
                                                            <input type="text" className="form-control" name="fax-3"
                                                                id="fax-3" value={fax_number_3} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <ul className="links">
                                    <li>
                                        {/* <a data-toggle="modal" data-target="#terms-of-service">{'confirm registration.Terms of service'.t()}</a> */}
                                        <a  onClick={windowOpen1}>{'confirm registration.Terms of service'.t()}</a>
                                    </li>
                                    <li>
                                        {/* <a data-toggle="modal" data-target="#handling-of-person-info">{'confirm registration.About the handing of personal information'.t()}</a> */}
                                        <a onClick={windowOpen2}>{'confirm registration.About the handing of personal information'.t()}</a>
                                    </li>
                                </ul>
                                <div id="terms-of-service" className="modal fade" role="dialog">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close"
                                                    data-dismiss="modal">&times;</button>
                                                <h4 className="modal-title text-center">{'confirm registration.Terms of service'.t()}</h4>
                                            </div>
                                            <div className="modal-body">
                                                <pre className="content-text">{term}</pre>
                                            </div>
                                            <div className="modal-footer text-center">
                                                <button type="button" className="btn btn-primary btn-lg"
                                                    data-dismiss="modal">{"request account.OK".t()}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div id="handling-of-person-info" className="modal fade" role="dialog">
                                    <div className="modal-dialog">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close"
                                                    data-dismiss="modal">&times;</button>
                                                <h4 className="modal-title text-center">{'request account.About the handing of personal information'.t()}</h4>
                                            </div>
                                            <div className="modal-body">
                                                <pre className="content-text">{personal_information}</pre>
                                            </div>
                                            <div className="modal-footer text-center">
                                                <button type="button" className="btn btn-primary btn-lg"
                                                    data-dismiss="modal">{"request account.OK".t()}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="application-step-actions tw-buttons">
                                 <div style={{ color: '#ff9b1b', textAlign: 'center', paddingTop: 30 }}>
                                    {"confirm registration.Warning Estimate".t()}
                                </div>
                                <div className="next-step text-center" style={{ paddingBottom: 16 }}>
                                    <button id="back-request-account" className="btn btn-secondary" type="button" onClick={this.prevStep}>
                                        {"confirm registration.Back".t()}
                                    </button>
                                    <button id="next-estimate" className="btn btn-primary" type="button" onClick={this.nextStep}>
                                        {"confirm registration.Next".t()}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="step-app next-step" />
                    </div>
                </main>
            </div>
        )
    }
}

export default withTranslation()(withRouter(ConfirmNewRegistration))

