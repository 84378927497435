import { CLEAR_ESTIMATE } from '../../redux/actionType/nextStepActionType';
import {
    FETCH_SUMMARY,
    FETCH_SUMMARY_SUCCESS,
    FETCH_SUMMARY_ERROR,
    UPDATE_IS_LOADING_SUMMARY
} from './actionType';

const DEFAULT_STATE = {
    summary_data: null,
    isLoadingSummary: false,
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_SUMMARY:
            return {
                ...state
            }
        case FETCH_SUMMARY_SUCCESS:
            return {
                ...state,
                summary_data: action.payload
            }
        case FETCH_SUMMARY_ERROR:
            return {
                ...state,
            }
        case UPDATE_IS_LOADING_SUMMARY:
            return {
                ...state,
                isLoadingSummary: action.payload
            }
        case CLEAR_ESTIMATE:
            return {
                ...state,
                summary_data: null,
                isLoadingSummary: false,
            }
        default:
            return state;
    }
}