import React, { Component } from 'react';
import './admin-import.css';
import { withTranslation } from "react-i18next";
import { listAdminImport } from '../../constant/admin-import';
import Alert from 'react-s-alert';
import { Modal, Button } from 'react-bootstrap';

const ResultImport = ({ data, status }) => {
	if (data.length === 0) return null;
	return (
		<>
			{
				status === "success" ?
					data ? <p className={"warning"} >{"notification.When import data have error".t()}</p>
						: <p className={"import-succ"} >{"notification.When import data successful".t()}</p>
					: <p className={"warning"} >{"notification.Somthing wrong please check it".t()}</p>
			}
			<div className="warning">
				{
					data.slice(0, 3).map((item, index) =>
						<div key={index} className="warning-item">
							<p>{item}</p>
						</div>
					)
				}
			</div>
		</>
	)
}

class AdminImport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filename: '',
			file: null,
			table_name: ''
		}
		this.selectFile = this.selectFile.bind(this);
		this.handleImport = this.handleImport.bind(this);
	}
	componentDidMount() {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}

	componentWillUnmount() {
		this.props.clearReducer()
	}
	handleChangeImportType = (item) => {
		this.setState({ table_name: item.table_name })
	}

	selectFile(e) {
		const filename = e.target.files[0].name;
		const file = e.target.files[0];

		this.setState({ filename, file }, () => {
			this.handleImport()
		})
	};

	async handleImport() {
		const { file, filename, table_name } = this.state;
		if (table_name !== '') {
			let formData = new FormData();
			formData.append("table_name", table_name);
			formData.append("file", file, filename);
			await this.props.uploadFile(formData);
		} else {
			Alert.warning('notification.please choose table import'.t(), {
				position: 'top-right',
				effect: 'scale',
				timeout: require("../../config/const").TIMEOUT,
				offset: -10
			})
		}
	}
	handleShow = () => {
		this.setState({ show: true })
	}
	handleClose = () => {
		this.setState({ show: false })
	}
	render() {
		const { error_file, isFullSideBar, status } = this.props;
		return (
			<div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
				<Alert stack={{ limit: 1 }} />
				<main className={'main-container summary'}>
					<form className="admin-form-import" method="post" encType="multipart/form-data" action="#">
						<div className="pannel-wrapper pannel-export pannel-import">
							<div className="fields">
								<div className="multiple-fields row">
									{listAdminImport.map((item, index) =>
										<div key={index} className="field col-lg-6 col-12">
											<div className="control">
												<div className="custom-radio">
													<input type="radio" onChange={() => this.handleChangeImportType(item)} name="import-type" value="classNameification-master" id={item.table_name} className="custom-control-input" />
													<label className="custom-control-label text-nowrap color-black" htmlFor={item.table_name}>{item.label.t()}</label>
												</div>
											</div>
										</div>
									)
									}
								</div>
								<div className="mage-error">Please select options</div>
							</div>
						</div>
					</form>
					<div className="application-step-actions tw-buttons">
						<div className="next-step text-center">
							{/*<button id="import" className="btn btn-primary" type="button" onClick={this.handleImport}>{'admin import.Import'.t()}</button>*/}
							<div className="file-upload-replace-button">
								<input
									type="file"
									id="file-upload"
									name="file-upload"
									onChange={this.selectFile}
									disabled={this.state.table_name === ''}
									value={''}
								/>
								<label className="btn import" htmlFor="file-upload">{"admin import.Import".t()}</label>
							</div>
							<button id="log" className="btn btn-secondary" type="button" onClick={() => this.handleShow()} disabled={error_file.length === 0}>
								{'admin import.Log'.t()}
							</button>
						</div>
					</div>
					<ResultImport
						data={error_file}
						status={status}
					/>
					<div className={`modal-estimate ${this.state.show ? 'show-modal-estimate' : ''}`}>
						<div className="modal-estimate-content">
							<div className="modal-estimate-title">
								<p>{'use application.Notification'.t()}</p>
							</div>
							<div className="modal-estimate-body" style={{maxHeight: 'calc(100vh - 250px)', overflowY: 'scroll'}}>
								<div className="warning error-file-import">
									{
										error_file.length !== 0 ?
											error_file.map((item, index) =>
												<div key={index} className="warning-item">
													<p>{item}</p>
												</div>
											) :
											<p>{"notification.Does not have any file error".t()}</p>
									}
								</div>
							</div>
							<div className="modal-estimate-footer">
								<button className='btn btn-secondary ml-2' onClick={this.handleClose}>{'notification.OK'.t()}</button>
							</div>
						</div>
					</div>
				</main>
			</div>
		)
	}
}

export default withTranslation()(AdminImport)
