import {combineReducers} from 'redux';
import UseApplicationReducer from '../../views/use-application/reducer';
import HardwareSettingReducer from '../../views/hardware-setting/reducer';
import ApplicationDetailReducer from '../../views/application-detail/reducer';
import SummaryReducer from '../../views/summary/reducer';
import RequestAccountReducer from '../../views/request-account/reducer';
import EstimateReducer from '../../views/estimate/reducer';
import AccountDetailReducer from '../../views/confirm-new-registration/reducer';
import AdminImportReducer from '../../views/admin-import/reducer';
import UsageAmountReducer from '../../views/usage-amount/reducer';
import LoginReducer from '../../views/login/reducer';
import UsageHistoryReducer from '../../views/usage-history-inquiry/reducer';
import AdminExportReducer from '../../views/admin-export/reducer';
import HistoryInquiryAdminReducer from '../../views/admin-usage-history-inquiry/reducer';
import NextStepReducer from '../reducer/nextStepReducer';

export default combineReducers({
    UseApplicationReducer,
    HardwareSettingReducer,
    ApplicationDetailReducer,
    SummaryReducer,
    RequestAccountReducer,
    EstimateReducer,
    NextStepReducer,
    AccountDetailReducer,
    AdminImportReducer,
    UsageAmountReducer,
    UsageHistoryReducer,
    LoginReducer,
    AdminExportReducer,
    HistoryInquiryAdminReducer
})
