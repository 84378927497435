import { takeLatest, put } from 'redux-saga/effects';
import { API } from '../../services/APIs';
import { httpService } from '../../services/httpService';
import {
    fetchUsageAmountSuccess,
    fetchUsageAmountError
} from './action';
import { FETCH_USAGE_AMOUNT } from './actionType';
import * as responseCode from '../../constant/response-code';
import { CONST } from '../../config/const';

function* getUsageAmount(action) {
    try {
        let language = localStorage.getItem(CONST.LANGUAGE);
        // const res = yield httpService.getWithoutToken(API.GET_ESTIMATE_DETAIL + `?estimate_no=${action.payload}&language=${language}`)
        const res = yield httpService.get(API.GET_ESTIMATE_DETAIL + `?estimate_no=${action.payload}&language=${language}`)
        if (res.status === responseCode.SUCCESS) {
            yield put(fetchUsageAmountSuccess(res.data))
        }
        if (res.code === responseCode.NOT_FOUND) {
            yield put(fetchUsageAmountError(res.message))
        }
    } catch (e) {
        console.log(e);
    }
}

export function* watchFetchUsageAmount() {
    yield takeLatest(FETCH_USAGE_AMOUNT, getUsageAmount)
}