import {
    FETCH_ACCOUNT_DETAIL, FETCH_ACCOUNT_ERROR, FETCH_ACCOUNT_SUCCESS,
    POST_REQUEST_ACCOUNT_CONFIRM, POST_REQUEST_ACCOUNT_SUCCESS, POST_REQUEST_ACCOUNT_FAILED
} from "./actionType";

import Alert from 'react-s-alert';

export function fetchAccountDetail(user_code) {
    return {
        type: FETCH_ACCOUNT_DETAIL,
        payload: user_code
    }
}

export function fetchAccountSuccess(data) {
    return {
        type: FETCH_ACCOUNT_SUCCESS,
        payload: data
    }
}

export function fetchAccountError(message) {
    return {
        type: FETCH_ACCOUNT_ERROR,
        payload: message
    }
}

export const postRequestAccount = (data, history) => ({
    type: POST_REQUEST_ACCOUNT_CONFIRM,
    payload: {
        data, history
    }
});
export const postRequestAccountSuccess = (result) => {
    Alert.success('Request new account successfully', {
        timeout: require("../../config/const").TIMEOUT,
        effect: 'scale',
        offset: -10
    });
    return {
        type: POST_REQUEST_ACCOUNT_SUCCESS,
        payload: result
    }
};
export const postRequestAccountFailed = (msg) => {
    console.log({ msg });

    Alert.error(msg, {
        timeout: require("../../config/const").TIMEOUT,
        effect: 'scale',
        offset: -10
    });
    return {
        type: POST_REQUEST_ACCOUNT_FAILED,
        payload: msg
    }
};
