import { httpService } from "../../services/httpService";
import { API } from "../../services/APIs";
import { takeLatest, put } from 'redux-saga/effects';
import { FETCH_LOGIN } from "./actionType";
import * as responseCode from '../../constant/response-code';
import { CONST } from "../../config/const";
import jwtDecode from 'jwt-decode';
import { checkAuth, fetchLoginError } from "./action";
import { fetching } from "../use-application/action";
import { clearEstimate } from "../../redux/action/nextStepAction";

function* onLogin(action) {
    try {
        yield put(fetching(true));
        const { username, password, currentRoute } = action.payload;
        const prevUserLogin = sessionStorage.getItem(CONST.USER_CODE);
        const response = yield httpService.postWithoutToken(API.LOGIN, { username, password });
        if (response.code === 200) {

            if (response.status === responseCode.SUCCESS) {
                if(prevUserLogin && username !== prevUserLogin) {
                    yield put(clearEstimate())
                }
                yield sessionStorage.setItem(CONST.TOKEN, response.data.access_token);
                const token = jwtDecode(response.data.access_token);
                yield sessionStorage.setItem(CONST.USER_NAME, token.user_claims.name);
                yield sessionStorage.setItem(CONST.USER_NAME_JP, token.user_claims.name_jp);
                yield sessionStorage.setItem(CONST.USER_CODE, username);
                if (token.user_claims.role === CONST.ADMIN) {
                    yield put(checkAuth({ isAdmin: true, isLogin: true }));
                    action.payload.history.push('/history-inquiry-admin')
                } else {
                    yield put(checkAuth({ isAdmin: false, isLogin: true }));
                    // action.payload.history.push('/usage-history-inquiry')
                    if(username !== prevUserLogin) {
                        action.payload.history.push('/')
                    } else {
                        action.payload.history.push(currentRoute)
                    }
                }
            } else {
                yield put(fetchLoginError(response.message))
            }
        } else {
            yield put(fetchLoginError(response.message))
        }
        yield put(fetching(false));
    } catch (e) {
        yield put(fetchLoginError(e.message))
    }

}

export function* watchLogin() {
    yield takeLatest(FETCH_LOGIN, onLogin)
}
