import { takeLatest, put, takeEvery } from 'redux-saga/effects';
import { httpService } from "../../services/httpService";
import { API } from "../../services/APIs";
import { FETCH_UPDATE_ESTIMATE, GET_LANGUAGE } from "../actionType/nextStepActionType";
import { fetchUpdateEstimateSuccess, fetchUpdateEstimateError, changeTitle, changeRoute, nextStepSuccess, nextStepError, changeLoadingNextStep, getLanguageSuccess, getLanguageError } from '../action/nextStepAction';
import * as responseCode from '../../constant/response-code';
import { CONST } from '../../config/const';

function* updateEstimate(action) {
    try {
        yield put(changeLoadingNextStep(true));
        let language = localStorage.getItem(CONST.LANGUAGE);
        let url = API.UPDATE_ESTIMATE;
        let data = action.payload.data;
        data = { ...data, language }
        // const res = yield httpService.postWithoutToken(url, data);
        const res = yield httpService.post(url, data);
        if (res.status === responseCode.SUCCESS) {

            if (action.payload.isBack == false) {
                yield put(nextStepSuccess("aaaa"));
            }
            yield put(fetchUpdateEstimateSuccess(res.data));
            yield action.payload.history.push(action.payload.route);
            yield put(changeTitle(action.payload.title));
            yield put(changeRoute(action.payload.route));
        } else {
            yield put(fetchUpdateEstimateError(res.message));
            yield put(nextStepError());
        }
        // if (res.code === responseCode.NOT_FOUND) {
        //     yield put(fetchUpdateEstimateError(res.message));
        //     yield put(nextStepError());
        // }
        yield put(changeLoadingNextStep(false));
    } catch (e) {
        console.log(e);
        yield put(fetchUpdateEstimateError(e.message));
        yield put(nextStepError());
        yield put(changeLoadingNextStep(false));
    }

}

export function* watchUpdateEstimate() {
    yield takeLatest(FETCH_UPDATE_ESTIMATE, updateEstimate)
}

function* getLanguage() {
    try {
        // yield put(changeLoadingNextStep(true));
        const res = yield httpService.getWithoutToken(API.GET_LANG);
        if (res.status === responseCode.SUCCESS) {
            yield put(getLanguageSuccess(res.data))
        }
        else {
            yield put(getLanguageError(res.message))
        }

    } catch (e) {
        console.log(e);
    }

}

export function* watchGetLanguage() {
    yield takeLatest(GET_LANGUAGE, getLanguage)
}
