import {
    FETCH_SUMMARY,
    FETCH_SUMMARY_SUCCESS,
    FETCH_SUMMARY_ERROR,
    UPDATE_IS_LOADING_SUMMARY
}
    from "./actionType";


export function fetchSummary(estimateNo) {
    return {
        type: FETCH_SUMMARY,
        payload: estimateNo
    }
}

export function fetchSummarySuccess(data) {
    return {
        type: FETCH_SUMMARY_SUCCESS,
        payload: data
    }
}

export function fetchSummaryError(message) {
    return {
        type: FETCH_SUMMARY_SUCCESS,
        payload: message
    }
}

export function updateIsLoadingSummary(isLoading) {
    return {
        type: UPDATE_IS_LOADING_SUMMARY,
        payload: isLoading
    }
}