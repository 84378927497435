import React, { Component } from 'react';
import './style.css'
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { STEP_USAGE_AMOUNT, STEP_USAGE_AMOUNT_LOGIN } from '../../constant/step-component';
import { Utils } from '../../config/utils';

class UsageAmount extends Component {

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.props.onFetchUsageAmount(this.props.estimate_no);
    }

    nextStep = () => {
        let { isLogin } = this.props;
        let stepCurrent;
        if (isLogin) {
            stepCurrent = STEP_USAGE_AMOUNT_LOGIN
        } else {
            stepCurrent = STEP_USAGE_AMOUNT
        }
        let isBack = this.props.currentStep > stepCurrent.step;
        this.props.onChangeTitle(stepCurrent.nextTitle);
        this.props.onChangeRoute(stepCurrent.nextRoute);
        this.props.history.push(stepCurrent.nextRoute);
        if (isBack === false) {
            this.props.nextStepSuccess();
        }
    }

    prevStep = () => {
        this.props.history.push(STEP_USAGE_AMOUNT.prevRoute);
        this.props.onChangeTitle(STEP_USAGE_AMOUNT.prevTitle);
        this.props.onChangeRoute(STEP_USAGE_AMOUNT.prevRoute);
    }

    render() {
        const { usage_amount_data, isFullSideBar } = this.props;
        console.log({ usage_amount_data });

        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <main className={'main-container summary'}>
                    <div>
                        <div className="step-app prev-step" />
                        <div className="step-app current-step">
                            <div className="pannel-wrapper usage-amount">
                                <div className="application-date f-16">
                                    <span className="label">{'usage amount.Application date'.t()}</span>
                                    <span className="rule" >{usage_amount_data?.application_date}</span>
                                </div>
                                <ul className="usage-amount-info f-16">
                                    <span className="label title">{'usage amount.Total Price'.t()}</span>
                                    <li><span className="label">{'usage amount.Estimate Number'.t()}</span><span
                                        className="rule">{usage_amount_data?.estimate_no}</span></li>
                                    <li><span className="label">{'usage amount.Total Price (included tax)'.t()}</span><span
                                        className="rule">¥{Utils.formatCurrency(usage_amount_data.total_price)}</span></li>
                                    <li><span className="label">{'usage amount.Expiration date'.t()}</span><span className="rule">{usage_amount_data?.expiration_date}</span>
                                    </li>
                                    <li>
                                        <span className="label">{'usage amount.Desired period'.t()}</span>
                                        <span className="rule date-time">
                                            <div><b>{"usage amount.Start Date".t()}</b>{usage_amount_data?.start_date}</div>
                                            <div><b>{'usage amount.End Date'.t()}</b>{usage_amount_data?.end_date}</div>
                                        </span>
                                    </li>
                                </ul>
                                <table className="table table-border-solid table-responsive-list usage-amount-table">
                                    <thead>
                                        <tr className="th-row">
                                            <th className="col-no">{'usage amount.No'.t()}</th>
                                            <th className="col-category" style={{ width: '40%' }}>{'usage amount.Product Category'.t()}</th>
                                            <th className="col-name" style={{ width: '45%' }}>{"usage amount.Product Name".t()}</th>
                                            <th className="col-name" style={{ width: '15%', minWidth: '180px', textAlign: 'end', paddingRight: '20px' }}>{"usage amount.Price".t()}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {usage_amount_data?.items?.map((item, index) =>
                                            <tr key={index}>
                                                <td className="col-no" data-th="No: ">{index + 1}</td>
                                                <td className="col-clear" data-th="Product Category:">{item?.product_category}</td>
                                                <td className="col-software" data-th="Product Name:">{item?.product_name}</td>
                                                <td className="col-software" style={{textAlign: 'end'}} data-th="Product Name:">¥ {Utils.formatCurrency(parseInt(item?.price))}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div>
                                    {
                                        usage_amount_data?.items?.map((item, index) =>
                                            <div key={index} className="list-usage-amount">
                                                <div>
                                                    <span style={{ fontWeight: '600' }}>{'usage amount.No'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    <span>{index + 1}</span>
                                                </div>
                                                <div>
                                                    <p style={{ fontWeight: '600' }}>{'usage amount.Product Category'.t()}:&nbsp;&nbsp;</p>
                                                    <p>{item?.product_category} </p>
                                                </div>
                                                <div >
                                                    <p style={{ fontWeight: '600' }}>{"usage amount.Product Name".t()}:&nbsp;&nbsp;</p>
                                                    <p>{item?.product_name}</p>
                                                </div>
                                                <div >
                                                    <p style={{ fontWeight: '600' }}>{"usage amount.Price".t()}:&nbsp;&nbsp;</p>
                                                    <p>¥ {Utils.formatCurrency(parseInt(item?.price))}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="application-step-actions tw-buttons">
                                <div className="next-step text-center">
                                    <button id="back-application" onClick={this.prevStep} className="btn btn-secondary" type="button">{'usage amount.Back'.t()}</button>
                                    <button id="next-summary" onClick={this.nextStep} className="btn btn-primary" type="button">{'usage amount.Next'.t()}</button>
                                </div>
                            </div>
                        </div>
                        <div className="step-app next-step" />
                    </div>
                </main>
            </div>
        )
    }
}
export default withTranslation()(withRouter(UsageAmount));
