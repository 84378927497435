import { takeLatest, put } from 'redux-saga/effects';
import { API } from '../../services/APIs';
import { httpService } from '../../services/httpService';
import * as responseCode from '../../constant/response-code';
import {
    SEARCH_HISTORY_INQUIRY_ADMIN,
    SEARCH_HISTORY_INQUIRY_ADMIN_SUCCESS,
    SEARCH_HISTORY_INQUIRY_ADMIN_ERROR,
    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_ERROR,
    SEARCH_SOFTWARE,
    SEARCH_SOFTWARE_ERROR,
    SEARCH_SOFTWARE_SUCCESS,
    SEARCH_HARDWARE,
    SEARCH_HARDWARE_SUCCESS,
    SEARCH_HARDWARE_ERROR,
    SEARCH_SUPPORT,
    SEARCH_SUPPORT_ERROR,
    SEARCH_SUPPORT_SUCCESS, GET_ADMIN_USAGE, UPDATE_ESTIMATE_STATUS
} from './actionType'
import Alert from 'react-s-alert';
import {
    searchUserSuccess,
    searchHardwareSuccess,
    searchSoftwareSuccess,
    searchSupportSuccess,
    getAdminUsageSuccess,
    getAdminUsageError,
    updateEstimateStatusSuccess,
    updateEstimateStatusError
} from './action';
import { CONST } from '../../config/const';

function* getUser(action) {
    try {
        const lang = localStorage.getItem(CONST.LANGUAGE)
        const res = yield httpService.get(API.SEARCH_USER + "?language=" + lang);
        if (res.status === responseCode.SUCCESS) {
            let data = res.data.map(item => {
                if (item.user_code.length > 50) {
                    return { ...item, user_code: item.user_code.slice(0, 50) }
                }
                return item
            })
            yield put(searchUserSuccess(data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* getSoftware(action) {
    try {
        const lang = localStorage.getItem(CONST.LANGUAGE)
        const res = yield httpService.get(API.SEARCH_SOFTWARE + "?language=" + lang);
        if (res.status === responseCode.SUCCESS) {
            yield put(searchSoftwareSuccess(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* getHardware(action) {
    try {
        const lang = localStorage.getItem(CONST.LANGUAGE)
        const res = yield httpService.get(API.SEARCH_HARDWARE + "?language=" + lang);
        if (res.status === responseCode.SUCCESS) {
            yield put(searchHardwareSuccess(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* getSupport(action) {
    try {
        const lang = localStorage.getItem(CONST.LANGUAGE)
        const res = yield httpService.get(API.SEARCH_SUPPORT + "?language=" + lang);
        if (res.status === responseCode.SUCCESS) {
            yield put(searchSupportSuccess(res.data));
        }
    } catch (e) {
        console.log(e);
    }
}

function* getAdminUsageHistory(action) {
    try {
        const response = yield httpService.post(API.GET_ADMIN_USAGE_HISTORY, action.payload);
        if (response.code === 200) {
            if (response.status === responseCode.SUCCESS) {
                if (response.data) {
                    yield put(getAdminUsageSuccess(response.data.orders))
                } else {
                    yield put(getAdminUsageSuccess([]))
                }
            } else {
                yield put(getAdminUsageError('error'))
            }
        } else {
            yield put(getAdminUsageError('error'))
        }
    } catch (e) {
        yield put(getAdminUsageError('error'));
        Alert.error(e.message, {
            timeout: require("../../config/const").TIMEOUT,
            effect: 'scale'
        });
        console.log({ e })
    }
}

function* updateEstimateStatus(action) {
    try {
        const response = yield httpService.post(API.UPDATE_ESTIMATE_STATUS, action.payload);
        if (response.code === 200) {
            if (response.status === responseCode.SUCCESS) {
                yield put(updateEstimateStatusSuccess(response.message));
                Alert.success('admin usage history inquiry.The estimation is updated'.t(), {
                    timeout: require("../../config/const").TIMEOUT,
                    effect: 'scale'
                })
            } else {
                yield put(updateEstimateStatusError(response.message));
                Alert.error(response.message, {
                    timeout: require("../../config/const").TIMEOUT,
                    effect: 'scale'
                })
            }
        } else {
            yield put(updateEstimateStatusError(response.message));
            Alert.error(response.message, {
                timeout: require("../../config/const").TIMEOUT,
                effect: 'scale'
            })
        }
    } catch (e) {
        yield put(updateEstimateStatusError(e.message));
        Alert.error(e.message, {
            timeout: require("../../config/const").TIMEOUT,
            effect: 'scale'
        })
        console.log({ e })
    }
}

export function* watchFetchHistoryInquiryAdmin() {
    yield takeLatest(SEARCH_USER, getUser);
    yield takeLatest(SEARCH_SOFTWARE, getSoftware);
    yield takeLatest(SEARCH_HARDWARE, getHardware);
    yield takeLatest(SEARCH_SUPPORT, getSupport);
    yield takeLatest(GET_ADMIN_USAGE, getAdminUsageHistory);
    yield takeLatest(UPDATE_ESTIMATE_STATUS, updateEstimateStatus);
}
