import React from 'react';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';

const Footer = ({ isFullSideBar }) => {
    // const { isFullSideBar } = this.props;
    return (
        <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
            <footer className="main-footer">
                <div className={'main-footer-content'}>
                    {/* <a className="ft-item" href="http://www.hpc.co.jp/aboutus.html" target="_blank">{'footer.about us'.t()}</a><span
                        className="separator">|</span> */}
                    <a className="ft-item" href="#" target="_blank">&nbsp;{'footer.privacy policy'.t()}</a><span
                        className="separator hide-mobile">|</span>
                    <address>
                        &nbsp;{'footer.copyright'.t()} <a href="http://www.hpc.co.jp" target="_blank">HPC SYSTEMS Inc.</a>&nbsp;{'footer.all rights reserved'.t()}
                    </address>
                </div>
            </footer>
        </div>
    )
};
// export default withTranslation()(Footer);
const mapStateToProps = state => ({
    isLogin: state.LoginReducer.isLogin,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    title: state.NextStepReducer.title,
    isAdmin: state.LoginReducer.isAdmin,
    currentStep: state.NextStepReducer.currentStep,
    language: state.NextStepReducer.language,
    isLoadingNextStep: state.NextStepReducer.isLoadingNextStep,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Footer))
