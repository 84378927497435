import { API } from "../../services/APIs";
import { httpService } from "../../services/httpService";
import { takeEvery, put, takeLatest } from 'redux-saga/effects';
import { CHECK_HARDWARE, FETCH_LIST_HARDWARE } from "./actionType";
import * as responseCode from '../../constant/response-code';
import { tr } from '../../config/utils'
import {
    checkHardwareError,
    checkHardwareSuccess,
    fetchListHardwareError,
    fetchListHardwareSuccess,
    updateListHardware
} from "./action";
// import Alert from 'react-s-alert';
import { fetchUpdateEstimate } from "../../redux/action/nextStepAction";
import Alert from 'react-s-alert';
import { CONST } from "../../config/const";



function* getListHardware(action) {
    try {
        let language = localStorage.getItem(CONST.LANGUAGE);
        let data = action.payload;

        data = { ...data, language }
        const url = API.GET_LIST_HARDWARE;
        // const res = yield httpService.postWithoutToken(url, data);
        const res = yield httpService.post(url, data);
        if (res.status === responseCode.SUCCESS) {
            const total_item = res.total_item;
            let total_page = 1;
            if (total_item > 0) {
                total_page = Math.ceil(total_item / res.item_per_page)
            }
            res.data.map(item => {
                item.checked = false
                item.quantity = ''
            });
            yield put(fetchListHardwareSuccess({ data: res.data, total_page }));
        } else {
            yield put(fetchListHardwareError(res.message))
        }
    } catch (e) {
        console.log(e);
        yield put(fetchListHardwareError(e))
    }
}

export function* watchGetListHardware() {
    yield takeEvery(FETCH_LIST_HARDWARE, getListHardware)
}

function* checkHardware(action) {
    try {
        const data = action.payload.hardware;
        let language = localStorage.getItem(CONST.LANGUAGE);
        const request = {
            "classification_code": data.classification_code,
            "operating_system_ver": data.operating_system_ver,
            "cpu_frequency": data.cpu_frequency,
            "cpu_number_of_cores": data.cpu_number_of_cores,
            "memory_type": data.memory_type,
            "memory_size": data.memory_size,
            "memory_qty": data.memory_qty,
            "storage_type": data.storage_type,
            "storage_size": data.storage_size,
            "storage_qty": data.storage_qty,
            "storage_raid": data.storage_raid,
            "estimate_no": data.estimate_no,
            "language": language
        };
        // const res = yield httpService.postWithoutToken(API.CHECK_HARDWARE, request);
        const res = yield httpService.post(API.CHECK_HARDWARE, request);

        if (res.code === 200) {
            if (res.status === responseCode.SUCCESS) {
                const { available } = res.data;
                let message = '';
                if (available === 0) {
                    message = 'hardware setting.Hardware is not available'.t();
                    yield put(checkHardwareError(data));
                    yield action.ref.current.blur();
                    Alert.error(message, {
                        position: 'top-right',
                        effect: 'scale',
                        beep: false,
                        timeout: require("../../config/const").TIMEOUT,
                        offset: -10
                    });
                } else if (!available || available < tr(data.quantity)) {
                    message = 'hardware setting.check hardware unavailable'.t().replace('X', available);
                    yield put(checkHardwareError(data))
                    yield action.ref.current.blur();
                    Alert.error(message, {
                        position: 'top-right',
                        effect: 'scale',
                        beep: false,
                        timeout: require("../../config/const").TIMEOUT,
                        offset: -10
                    });
                } else {
                    yield put(checkHardwareSuccess(data));
                    if (action.isNext) {
                        yield put(fetchUpdateEstimate(action.payload.data, 'Summary', '/summary', action.payload.history, action.payload.isBack))
                    }
                }
                // else {
                //     const message = 'hardware setting.Hardware is not available'.t();
                //     yield put(checkHardwareError(message))
                // }
            } else {
                const message = 'hardware setting.Hardware is not available'.t();
                yield put(checkHardwareError(data))
                yield action.ref.current.blur();
                Alert.error(message, {
                    position: 'top-right',
                    effect: 'scale',
                    beep: false,
                    timeout: require("../../config/const").TIMEOUT,
                    offset: -10
                });
            }
        }

    } catch (e) {
        yield put(checkHardwareError(e))
        yield action.ref.current.blur();
    }
}
export function* watchCheckHardware() {
    yield takeLatest(CHECK_HARDWARE, checkHardware)
}
