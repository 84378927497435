import {
    FETCH_LIST_HARDWARE_SUCCESS,
    UPDATE_LIST_HARDWARE, FETCH_LIST_HARDWARE,
    CHECK_HARDWARE_SUCCESS, CHECK_HARDWARE_ERROR,
    CHECK_HARDWARE, LOADING_HARDWARE, FETCH_LIST_HARDWARE_ERROR,
    UPDATE_SEARCH_KEYWORD
} from "./actionType";
import { Utils } from '../../config/utils';
import { CLEAR_ESTIMATE } from '../../redux/actionType/nextStepActionType';

const DEFAULT_STATE = {
    list_hardware: [],
    total_page: null,
    hardware_active: [],
    isLoading: false,
    search_keyword: "",
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_LIST_HARDWARE:
            return {
                ...state,
                isLoading: true
            }
        case FETCH_LIST_HARDWARE_SUCCESS:
            const hardware_active = [...state.hardware_active];
            let _list_hardware_update;
            if (hardware_active.length > 0) {
                _list_hardware_update = Utils.updateValue(hardware_active, action.payload.data, "primary_key");
            } else {
                _list_hardware_update = action.payload.data
            }
            return {
                ...state,
                isLoading: false,
                list_hardware: _list_hardware_update,
                total_page: action.payload.total_page
            };
        case FETCH_LIST_HARDWARE_ERROR:
            return {
                ...state,
                isLoading: false
            }
        case UPDATE_LIST_HARDWARE:
            let list_hardware = [...state.list_hardware];
            let update_hardware_active = [...state.hardware_active];
            list_hardware = list_hardware.map(hw => {
                if (hw.primary_key === action.payload.primary_key) {
                    return action.payload
                } else {
                   return {...hw, checked: false}
                }
            })
            update_hardware_active = update_hardware_active.filter(hw => hw.primary_key !== action.payload.primary_key);
            // console.log({update_hardware_active, list_hardware})
            return {
                ...state,
                list_hardware,
                hardware_active: update_hardware_active
            }

        case CHECK_HARDWARE:
            return {
                ...state,
                isLoading: true,
            }
        case CHECK_HARDWARE_SUCCESS:
            const success_list_hardware = [...state.list_hardware];
            let _hardware_active = [...state.hardware_active];
            console.log({action})
            success_list_hardware.map(hw => {
                if (hw.primary_key === action.payload.primary_key) {
                    hw = action.payload;
                    hw.checked = true;
                } else {
                    hw.quantity = '';
                    hw.checked = false;
                }
            })

            if (action.payload.checked === true) {
                if ([...state.hardware_active].filter(hw => hw.primary_key === action.payload.primary_key).length === 0) {
                    _hardware_active = [...state.hardware_active, action.payload];
                }
                else {
                    [...state.hardware_active].forEach(hw => {
                        if (hw.primary_key === action.payload.primary_key) {
                            hw.quantity = action.payload.quantity
                        } else {
                            hw.quantity = ''
                        }
                    })
                    _hardware_active = [...state.hardware_active];
                }
                _hardware_active = [action.payload]
            }
            return {
                ...state,
                isLoading: false,
                list_hardware: success_list_hardware,
                hardware_active: _hardware_active
            }
        case CHECK_HARDWARE_ERROR:
            const error_list_hardware = [...state.list_hardware]
            const list_hardware_active = [...state.hardware_active];
            error_list_hardware.map(hw => {
                if (hw.primary_key === action.payload.primary_key) {
                    hw.checked = false;
                    hw.quantity = "";
                }
            })
            console.log({ error_list_hardware })
            let _update_hardware_active = list_hardware_active.filter(hw => hw.primary_key !== action.payload.primary_key)
            return {
                ...state,
                isLoading: false,
                list_hardware: error_list_hardware,
                hardware_active: _update_hardware_active
            }
        case LOADING_HARDWARE:
            return {
                ...state,
                isLoading: action.payload
            }
        case UPDATE_SEARCH_KEYWORD:
            return {
                ...state,
                search_keyword: action.payload
            }
        case CLEAR_ESTIMATE:
            return {
                ...state,
                list_hardware: [],
                total_page: null,
                hardware_active: [],
                isLoading: false,
                search_keyword: ""
            }
        default:
            return state;
    }
}
