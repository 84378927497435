const FETCH_LIST_HARDWARE = 'FETCH_LIST_HARDWARE';
const FETCH_LIST_HARDWARE_SUCCESS = 'FETCH_LIST_HARDWARE_SUCCESS';
const FETCH_LIST_HARDWARE_ERROR = 'FETCH_LIST_HARDWARE_ERROR';

const UPDATE_LIST_HARDWARE = 'UPDATE_LIST_HARDWARE';

const CHECK_HARDWARE = 'CHECK_HARDWARE';
const CHECK_HARDWARE_SUCCESS = 'CHECK_HARDWARE_SUCCESS';
const CHECK_HARDWARE_ERROR = 'CHECK_HARDWARE_ERROR';

const LOADING_HARDWARE = 'LOADING_HARDWARE';

const UPDATE_SEARCH_KEYWORD = 'UPDATE_SEARCH_KEYWORD';

export {
    FETCH_LIST_HARDWARE, FETCH_LIST_HARDWARE_SUCCESS, FETCH_LIST_HARDWARE_ERROR,
    CHECK_HARDWARE, CHECK_HARDWARE_SUCCESS, CHECK_HARDWARE_ERROR, UPDATE_LIST_HARDWARE,
    LOADING_HARDWARE, UPDATE_SEARCH_KEYWORD
}
