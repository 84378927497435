import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Alert from "react-s-alert";
import "./style.css";
import { Utils } from "../../config/utils";
import { STEP_USE_APPLICATION } from "../../constant/step-component";
import { Modal, Button } from "react-bootstrap";
import { CONST } from "../../config/const";

class UseApplication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActiveClassification: 0,
      listSoftwareVersion: [],
      listActiveSoftwareCode: [],
      listActiveSoftware: [],
      show: false,
      softwareSelect: {},
    };
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let { processor, search_keyword, classification_code } = this.props.searchForm;
    // const { language } = this.props;
    // console.log({ language })
    let language = localStorage.getItem(CONST.LANGUAGE);
    // console.log({ language })
    this.props.getClassification(2, processor, language, search_keyword, classification_code);
  }

  // Choose Classification
  onHandleClickItem = (index) => {
    this.props.changeClassification(index);
    let { searchForm, classification_data } = this.props;
    searchForm.classification_code =
      classification_data[index].classification_code;
    this.props.getSoftware(searchForm);
  };

  // Choose Software
  onHandleClickSoftware = (data) => {
    let { listActiveSoftwareCode, listActiveSoftware, searchForm } = this.props;
    let prevProcessor = listActiveSoftware.map(item => item.software_processor);
    let currentProcessor = searchForm.processor;
    // console.log({currentProcessor, prevProcessor});
    console.log({ data, prevProcessor, currentProcessor});
    data.software_processor = currentProcessor;
    if (prevProcessor.length === 0) {
      this.addItemSoftwareActive(data);
    } else {
      if (
        prevProcessor.includes(currentProcessor) 
        || currentProcessor === "cpu,gpu" 
      ) {
        if (listActiveSoftwareCode.includes(data.software_code)) {
          this.removeSoftwareActive(data);
        } else {
          this.addItemSoftwareActive(data);
        }
      } else {
        data = {
          ...data,
          product_category: 2,
          product_code: data.software_code,
          software_version: "",
          quantity: 1,
        };
        this.setState({ show: true, softwareSelect: data });
        // this.clearItemSoftwareActive(data);
      }
    }
  };

  // Add Software
  addItemSoftwareActive = (data) => {
    let { listSoftwareVersion } = this.props;
    data = {
      ...data,
      product_category: 2,
      product_code: data.software_code,
      software_version: "",
      quantity: 1,
    };
    this.props.addSoftwareActive(data);
    if (listSoftwareVersion.includes(data.software_code) === false) {
      this.props.getSoftwareVersion(data.software_name);
    }
  };

  // Remove Software
  removeSoftwareActive = async (data) => {
    this.props.loadingListSoftware(true);
    this.props.removeSoftwareActive(data);
    // this.props.loadingListSoftware(false);
    await setTimeout(() => {
      this.props.loadingListSoftware(false);
    }, 300);
  };

  clearItemSoftwareActive = (data) => {
    this.props.clearListSoftwareVersion(data);
  };

  // Change Processor
  onHandleChecked = (event) => {
    this.props.changeProcessor(event.target.value);
    const { searchForm } = this.props;
    searchForm.processor = event.target.value;
    this.props.getSoftware(searchForm);
  };

  // Change Search Input
  onHandleChangeInput = (event) => {
    this.props.updateSearchKeyword(event.target.value);
    let { searchForm } = this.props;
    searchForm.search_keyword = event.target.value;
    Utils.debounceSearch(searchForm, this.props.getSoftware);
  };

  renderOption = (name) => {
    let dataOption = this.props.softwareVersion.filter((item) => {
      return item.software_name === name;
    });
    return dataOption.map((item, index) => {
      if (item.software_version) {
        return (
          <option key={index} value={item.software_version}>
            {item.software_version}
          </option>
        );
      } else {
        return null;
      }
    });
  };

  // Change Software Version
  onChangeSoftwareVersion = (e, software, index) => {
    let { listActiveSoftware } = this.props;
    software.software_version = e.target.value;
    listActiveSoftware[index] = software;
    this.props.updateSoftwareVersion(software);
  };

  extractValues = (arr) => {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  }

  nextStep = () => {
    let {
      data_update_estimate,
      listActiveSoftware,
      history,
      currentStep,
    } = this.props;
    let isBack = currentStep > STEP_USE_APPLICATION.step;
    if (listActiveSoftware.length === 0) {
      Alert.warning("notification.please choose a software".t(), {
        position: "top-right",
        effect: "scale",
        timeout: require("../../config/const").TIMEOUT,
        offset: -10,
      });
    } else if (listActiveSoftware.length > 0) {
      // Remove check software version
      // if (listActiveSoftware.filter(item => item.software_version === '').length > 0) {
      //     Alert.warning('notification.please choose software version'.t(), {
      //         position: 'top-right',
      //         effect: 'scale',
      //         timeout: require("../../config/const").TIMEOUT,
      //         offset: -10
      //     })
      // }
      // else {
      //     let listHardware = [...data_update_estimate.items].filter(item => Object.keys(item).includes('software_code') === false);
      //     let listItem = [...listHardware].concat(listActiveSoftware);
      //     data_update_estimate = {
      //         ...data_update_estimate,
      //         step: "2",
      //         items: listItem
      //     }
      //     this.props.nextStepUseApplication(data_update_estimate, STEP_USE_APPLICATION.nextTitle, STEP_USE_APPLICATION.nextRoute, history, isBack)
      // }

      let listHardware = [...data_update_estimate.items].filter(
        (item) => Object.keys(item).includes("software_code") === false
      );
      const listProcess = this.extractValues(listActiveSoftware.map(item => item.software_processor));
      const process = listProcess.find(item => item !== 'cpu,gpu');
      if(process) {
        listActiveSoftware = listActiveSoftware.map(item => {
            return {...item, software_processor: process};
        })
      }
      let listItem = [...listHardware].concat(listActiveSoftware);
      data_update_estimate = {
        ...data_update_estimate,
        step: "2",
        items: listItem,
      };
      console.log({data_update_estimate})
      this.props.nextStepUseApplication(data_update_estimate, STEP_USE_APPLICATION.nextTitle, STEP_USE_APPLICATION.nextRoute, history, isBack)
    }
  };
  closeModalAndConfirm = () => {
    let data = this.state.softwareSelect;
    this.setState({ show: false }, () => {
      this.clearItemSoftwareActive(data);
    });
  };

  closeModalAndCanel = () => {
    this.setState({ show: false });
  };

  getTextSmall(text) {
    return text.slice(0, 3);
  }

  render() {
    const {
      isLoading,
      classification_data,
      softwares,
      softwareVersion,
      classificationActive,
      listActiveSoftwareCode,
      listActiveSoftware,
      isLoadingListSoftware,
      isFullSideBar,
    } = this.props;
    const { show } = this.state;
    const { processor, search_keyword } = this.props.searchForm;
    let language = localStorage.getItem(CONST.LANGUAGE);
    console.log({language})
    // console.log({listActiveSoftware});
    return (
      <div className={`${isFullSideBar ? "" : "disable-menu"}`}>
        <main className="main-container use-application">
          <Alert stack={{ limit: 1 }} />
          <div
            className={`modal-estimate ${show ? "show-modal-estimate" : ""}`}
          >
            <div className="modal-estimate-content">
              <div className="modal-estimate-title">
                <p>{"use application.Notification".t()}</p>
              </div>
              <div className="modal-estimate-body">
                <p
                  className="text-primary"
                  style={{ textAlign: "center", padding: 20 }}
                >
                  {"notification.the selected data will be deleted! Do you want to continue?".t()}
                </p>
              </div>
              <div className="modal-estimate-footer">
                <button
                  className="btn btn-primary ml-2"
                  onClick={this.closeModalAndConfirm}
                >
                  {"notification.OK".t()}
                </button>
                <button
                  className="btn btn-secondary ml-2"
                  onClick={this.closeModalAndCanel}
                >
                  {"notification.Canel".t()}
                </button>
              </div>
            </div>
          </div>
          <div className="pannel-wrapper application-processor">
            <div className="processor-container">
              <span className="f_semibold fs-16 color-dark processor-text">
                {"use application.Please select the processor of the hardware you want to use".t()}
              </span>
              <div className="custom-radio-wrapper">
                <div className="custom-radio">
                  <input
                    type="radio"
                    value="cpu"
                    checked={processor === "cpu"}
                    id="cpu"
                    name="processor"
                    className="custom-control-input"
                    onChange={this.onHandleChecked}
                  />
                  <label
                    className="custom-control-label f_semibold fs-16 color-black"
                    htmlFor="cpu"
                  >
                    CPU
                  </label>
                </div>
                <div className="custom-radio">
                  <input
                    type="radio"
                    value="gpu"
                    checked={processor === "gpu"}
                    id="gpu"
                    name="processor"
                    className="custom-control-input"
                    onChange={this.onHandleChecked}
                  />
                  <label
                    className="custom-control-label f_semibold fs-16 color-black"
                    htmlFor="gpu"
                  >
                    GPU
                  </label>
                </div>
                <div className="custom-radio">
                  <input
                    type="radio"
                    value="cpu,gpu"
                    checked={processor === "cpu,gpu"}
                    id="cpu,gpu"
                    name="processor"
                    className="custom-control-input"
                    onChange={this.onHandleChecked}
                  />
                  <label
                    className="custom-control-label f_semibold fs-16 color-black"
                    htmlFor="cpu,gpu"
                  >
                    CPU, GPU
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="pannel-wrapper choice-applications applications-list">
            <input
              type="hidden"
              name="sellected-app"
              id="sellected-app"
              value=""
            />
            <div className="application-filter">
              <ul className="filter-nav">
                {classification_data?.length > 0 ? (
                  classification_data.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => this.onHandleClickItem(index)}
                      className={`filter-item ${
                        classificationActive === index ? "active" : ""
                      }`}
                    >
                      {/* <a className="fil-link">{item.classification_name}</a> */}
                      <a className="filter-text">{item.classification_name}</a>
                      <a className="filter-text-small">
                        {this.getTextSmall(item.classification_name)}
                      </a>
                    </li>
                  ))
                ) : (
                  <div className="text-center">
                    {"notification.does not have any software".t()}
                  </div>
                )}
              </ul>
              <div className="search-app">
                <input
                  type="tel"
                  onChange={this.onHandleChangeInput}
                  value={search_keyword}
                  className="quicksearch"
                  placeholder={"use application.Search application".t()}
                />
              </div>
            </div>
            <ul className="application-items">
              <div className={`loading ${isLoading && "on-loading"}`}>
                <div className="loading-image">
                  <img
                    className="w-100 h-100"
                    src={require("../../assets/images/loading.gif")}
                  />
                </div>
              </div>
              {softwares?.length > 0 ? (
                softwares.map((item, index) => (
                  <li
                    id="app-1"
                    onClick={() => this.onHandleClickSoftware(item)}
                    data-app="1"
                    key={index}
                    className={`application-item ${
                      listActiveSoftwareCode.includes(item.software_code)
                        ? "active"
                        : "overlay"
                    }`}
                  >
                    <div className="item">
                      {item.software_icon ? (
                        <img
                          className="img-fluid"
                          src={`../../assets/${item.software_icon}`}
                          alt=""
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src="../../assets/images/icon/logo_favicon.png"
                          alt=""
                        />
                      )}
                      {/* <div className="app-name">
                                                {item.software_name}
                                            </div> */}
                    </div>
                  </li>
                ))
              ) : (
                <div className="text-center">
                  {"notification.does not have any software".t()}
                </div>
              )}
            </ul>
          </div>
          <div className="use-app">{'use application.App1'.t()}<a href=" mailto:hpcs_sales@hpc.co.jp">hpcs_sales@hpc.co.jp</a>{'use application.App2'.t()}</div>
          <div className="pannel-wrapper application-software">
            <table className="table table-border-solid table-responsive-list list-software-active">
              {/*<div className={`loading-list-software ${isLoadingListSoftware && 'on-loading-list-software'}`}>*/}
              {/*<div className="loading-list-software-image">*/}
              {/*<img className="w-100 h-100" src={require('../../assets/images/loading-next-step.gif')} />*/}
              {/*</div>*/}
              {/*</div>*/}
              <thead>
                <tr className="">
                  <th className="col-no">{"use application.No".t()}</th>
                  <th className="col-clear">{"use application.Clear".t()}</th>
                  <th className="col-software">
                    {"use application.Software".t()}
                  </th>
                  <th className="col-version">
                    {"use application.Version".t()}
                  </th>
                  <th className="col-software">
                    {"use application.Supplement".t()}
                  </th>
                </tr>
              </thead>
              <tbody>
                {listActiveSoftware?.length > 0 ? (
                  listActiveSoftware.map((item, index) => (
                    <tr key={index.toString()}>
                      {/* <td className="col-no" data-th="No">{Utils.checkIndex(index + 1)}</td> */}
                      <td className="col-no" data-th="No">
                        {index + 1}
                      </td>
                      <td className="col-clear" data-th="Clear">
                        <a
                          title="Remove"
                          className="icon-remove"
                          onClick={() => this.removeSoftwareActive(item)}
                        >
                          <span>
                            <i
                              style={{ color: "#fff" }}
                              className="fas fa-times"
                            />
                          </span>
                        </a>
                      </td>
                      <td className="col-software" data-th="Software">
                        {item.software_name}
                      </td>
                      <td className="col-version" data-th="Version">
                        <select
                          onChange={(e) =>
                            this.onChangeSoftwareVersion(e, item, index)
                          }
                          className="no-border no-padding-left"
                          value={item.software_version}
                        >
                          <option value="">
                            {"use application.Choose version".t()}
                          </option>
                          {softwareVersion?.length > 0 &&
                            this.renderOption(item.software_name)}
                        </select>
                      </td>
                      <td className="col-software" data-th="Software">
                        {language === 'JP' ? item.supplement : item.supplement_gb}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      {"notification.does not have any software".t()}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div>
              {listActiveSoftware.length > 0 ? (
                listActiveSoftware.map((item, index) => (
                  <div key={index} className="list-active-software-item">
                    <div>
                      <span style={{ fontWeight: "600" }}>
                        {"use application.No".t()}:&nbsp;&nbsp;&nbsp;&nbsp;
                      </span>
                      <span>{index + 1}</span>
                    </div>
                    <div className="item-flex">
                      <div style={{ fontWeight: "600", alignItems: "center" }}>
                        {"use application.Clear".t()} :&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                      <div>
                        <a
                          title="Remove"
                          className="icon-remove"
                          onClick={() => this.removeSoftwareActive(item)}
                        >
                          <span>
                            <i
                              style={{ color: "#fff" }}
                              className="fas fa-times"
                            />
                          </span>
                        </a>
                      </div>
                    </div>
                    <div className="item-flex">
                      <div style={{ fontWeight: "600", alignItems: "center" }}>
                        {"use application.Software".t()}{" "}
                        :&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                      <div>{item.software_name}</div>
                    </div>
                    <div className="item-flex">
                      <div style={{ fontWeight: "600" }}>
                        {"use application.Version".t()}&nbsp;&nbsp;
                      </div>
                      <div>
                        <select
                          onChange={(e) =>
                            this.onChangeSoftwareVersion(e, item, index)
                          }
                          className="no-border no-padding-left"
                          value={item.software_version}
                        >
                          <option value="">
                            {"use application.Choose version".t()}
                          </option>
                          {softwareVersion?.length > 0 &&
                            this.renderOption(item.software_name)}
                        </select>
                      </div>
                    </div>
                    <div className="item-flex">
                      <div style={{ fontWeight: "600", alignItems: "center" }}>
                        {"use application.Supplement".t()}{" "}
                        :&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                      <div>{language === 'JP' ? item.supplement : item.supplement_gb}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center list-active-software-item">
                  {"notification.does not have any software".t()}
                </div>
              )}
            </div>
          </div>
          <div id="application-step" className="application-step-actions">
            <div className="next-step text-center">
              <button
                onClick={this.nextStep}
                className="btn btn-primary btn-lg"
                type="button"
              >
                {"use application.Next".t()}
              </button>
            </div>
          </div>

          <div className="step-app next-step" />
        </main>
      </div>
    );
  }
}

export default withTranslation()(UseApplication);
