import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  pdf,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { Utils } from "../../config/utils";

const fontBold = require("../../assets/fonts/Nasu-Bold.ttf");
const fontRegular = require(`../../assets/fonts/Nasu-Regular.ttf`);

Font.register({
  family: "Nasu-Regular",
  fonts: [{ src: fontBold }, { src: fontRegular }],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFF",
    padding: 20,
  },
  row: {
    flexDirection: "row",
  },
  text: {
    fontSize: 12,
    lineHeight: 1.5,
    fontFamily: "Nasu-Regular",
  },
  textPrice: {
    fontWeight: "bold",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#444",
    borderBottomStyle: "solid",
    marginBottom: 10,
    alignItems: 'center'
  },
  userWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  estimateInfo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 30,
  },
  estimateLeft: {
    width: "50%",
  },
  estimateRight: {
    width: "50%",
    alignItems: "flex-end",
    // textAlign: 'left'
  },
  estimateDetail: {},
  estimateDetailHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  estimateDetailHeaderRight: {
    width: "65%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  estimateBlock: {
    borderTopColor: "#444",
    borderTopWidth: 2,
    borderTopStyle: "solid",
  },
  estimateItem: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  estimateItemRight: {
    width: "65%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  totalWrapper: {
    borderTopColor: "#444",
    borderTopWidth: 1,
    borderTopStyle: "solid",
    alignItems: "flex-end",
  },
  estimateDetailTotal: {
    borderTopColor: "#444",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    alignItems: "flex-end",
    borderBottomColor: "#444",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
  },
});

// Create Document Component
export const MyDocument = ({ pdfInfo, ...props }) => {
  const hwInfo = pdfInfo?.hw_info?.length > 0 && pdfInfo?.hw_info[0];
  const language = pdfInfo?.language;

  const A = language === "GB" ? "ESTIMATE FILE (Temporary)" : "見 積 書 （仮）";
  const C = language === "GB" ? "Estimate No" : "見積番号";
  const G =
    language === "GB"
      ? "Subject    Use of Science Cloud"
      : "件名　　サイエンスクラウド(利用)";
  const H = language === "GB" ? "Amount of money: " : "金額 ";
  const K = language === "GB" ? "(Included Tax)" : "（消費税込）";
  const L =
    language === "GB"
      ? "Payment terms:  Closed at the end of the month "
      : "支払条件　月末締翌月末振込";
  const M =
    language === "GB"
      ? "Expiration date: Within {0} month from the date of issue of the quotation"
      : "有効期限　見積書発行日より{0}ヶ月以内";
  const O = language === "GB" ? "Product Name" : "製品名";
  const P =
    language === "GB" ? "Product unit price (monthly)" : "製品単価（月額）";
  const Q = language === "GB" ? "Unit selling price" : "販売単価";
  const R = language === "GB" ? "Note" : "備考";
  const S = language === "GB" ? "Cloud" : "クラウド";
  const T =
    language === "GB"
      ? "Science cloud (monthly)"
      : "サイエンスクラウド（月額）";
  const W = language === "GB" ? "Standard plan:" : "Standardプラン：";
  const Y = language === "GB" ? "Software used:" : "ご利用ソフトウェア：";
  const a = language === "GB" ? "Subtotal" : "小計";
  const c = language === "GB" ? "Support" : "サポート";
  const j = language === "GB" ? "Tax" : "消費税";
  const h = language === "GB" ? "Number of used" : "ご利用台数";
  const i = language === "GB" ? "Total" : "合計";
  const l = language === "GB" ? "Total (Included tax)" : "税込合計";
  const n =
    language === "GB"
      ? "<Breakdown on the use of science cloud>"
      : "＜サイエンスクラウド利用に関する内訳＞";
  const o = language === "GB" ? "□Hardware configuration" : "□ハードウェア構成";
  const p = language === "GB" ? "Storage" : "ストレージ";
  const q =
    language === "GB"
      ? "Simultaneous submission job quantity limit (depending on the number of CPUs), calculation time unlimited"
      : "同時投入ジョブ数量制限（CPU数に依存）、計算時間無制限";
  const r = language === "GB" ? "□Software used：If you wish to use your own software, please feel free to contact us at hpcs_sales@hpc.co.jp for further information." : "□ご利用ソフトウェア：利用者様がご用意するソフトウェアを使用される場合は、別途、弊社 hpcs_sales@hpc.co.jp にお気軽にお問い合わせください。";
  const t = language === "GB" ? "□Usage period" : "□ご利用期間";
  const u = language === "GB" ? "months" : "ヶ月";
  const to = language === "GB" ? "to" : "から";

  const renderInfoHPC = (language) => {
    return language === "GB" ? (
      <View style={styles.estimateRight}>
        <Text style={styles.text}>HPC Systems Inc.</Text>
        <Text style={styles.text}>Representative Director Teppei Ono</Text>
        <Text style={styles.text}>〒108-0022</Text>
        <Text style={styles.text}>
          LOOP-X 8th floor, 3-9-15 , Minato-ku, Tokyo{" "}
        </Text>
        <Text style={styles.text}>
          TEL: 03-5446-5531&nbsp;&nbsp;FAX: 03-5446-5550
        </Text>
      </View>
    ) : (
      <View style={styles.estimateRight}>
        <Text style={styles.text}>HPCシステムズ株式会社</Text>
        <Text style={styles.text}>代表取締役 小野　鉄平</Text>
        <Text style={styles.text}>〒108-0022</Text>
        <Text style={styles.text}>東京都港区海岸3-9-15　LOOP-X　8階</Text>
        <Text style={styles.text}>
          TEL: 03-5446-5531&nbsp;&nbsp;FAX: 03-5446-5550
        </Text>
      </View>
    );
  };

  return (
    <Document title="HSC">
      <Page size="A4" style={styles.page}>
        {/* header */}
        <View style={styles.header}>
          <View>
            {/* <Text style={styles.text}>見 積 書 （仮）</Text> */}
            <Text style={[styles.text, {fontSize: 22}]}>{A}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.text}>
              {pdfInfo?.application_date}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Text>
            {/* <Text style={styles.text}>見積番号</Text> */}
            <Text style={styles.text}>
              {C}: {pdfInfo?.estimate_no}
            </Text>
          </View>
        </View>
        {/* user name */}
        <View style={styles.userWrapper}>
          <Text style={[styles.text, {borderBottomWidth: 1, borderBottomStyle: 'solid', borderBottomColor: '#000'}]}>
            {language === "GB" && "Mr/Ms "}
            {`${pdfInfo?.user_first_name} ${pdfInfo?.user_last_name}`}{" "}
            {language === "GB" ? "" : "様"}
          </Text>
        </View>
        {/* Estimate Info */}
        <View style={styles.estimateInfo}>
          {/* Left */}
          <View style={styles.estimateLeft}>
            {/* <Text style={styles.text}>件名　　サイエンスクラウド利用</Text> */}
            <Text style={styles.text}>{G}</Text>
            <View style={[styles.row]}>
              <Text style={styles.text}>
                {H} ￥{Utils.formatCurrency(pdfInfo?.total_price_includes_tax)}
                {K}
              </Text>
            </View>
            <Text style={styles.text}>{L}</Text>
            <Text style={styles.text}>{M.replace('{0}', pdfInfo?.num_months_expiration)}</Text>
          </View>
          {/* Right */}
          {/* <View style={styles.estimateRight}>
            <Text style={styles.text}>HPCシステムズ株式会社</Text>
            <Text style={styles.text}>代表取締役 小野　鉄平</Text>
            <Text style={styles.text}>〒108-0022</Text>
            <Text style={styles.text}>東京都港区海岸3-9-15　LOOP-X　8階</Text>
            <Text style={styles.text}>
              Tel: 03-5446-5531&nbsp;&nbsp;Fax: 03-5446-5550
            </Text>
          </View> */}
          {renderInfoHPC(language)}
        </View>
        {/* Estimate detail */}
        <View style={styles.estimateDetail}>
          {/* Header */}
          <View style={styles.estimateDetailHeader}>
            <Text style={styles.text}>{O}</Text>
            <View style={styles.estimateDetailHeaderRight}>
              <Text style={styles.text}>{P}</Text>
              <Text style={styles.text}>{Q}</Text>
              <Text style={[styles.text, { marginRight: 40 }]}>{R}</Text>
            </View>
          </View>
          <Text style={styles.text}>{S}</Text>
          {/* List Item */}
          {/* Block 1*/}
          <View style={styles.estimateBlock}>
            <View style={styles.estimateItem}>
              <Text style={[styles.text, { width: "35%" }]}>{T}</Text>
              <View style={[styles.estimateItemRight, ]}>
                <Text style={[styles.text, {width: '20%'}]}>
                  ￥{Utils.formatCurrency(parseInt(pdfInfo?.software_price))}
                </Text>
                <Text style={[styles.text, styles.textPrice]}>
                  ￥
                  {Utils.formatCurrency(
                    parseInt(pdfInfo?.software_selling_price)
                  )}
                </Text>
                <Text style={styles.text}></Text>
              </View>
            </View>
            <View style={{width: '20%'}}></View>
            <View style={styles.estimateItem}>
              <Text style={[styles.text, {marginLeft: 12}]}>
                {W} {hwInfo?.branch}
              </Text>
            </View>
            <View style={styles.estimateItem}>
              <Text style={[styles.text, {marginLeft: 12}]}>
                {Y} {pdfInfo?.software_names?.join("/")}
              </Text>
            </View>
            <View style={styles.totalWrapper}>
              <Text style={[styles.text, styles.textPrice]}>
                {a}: ￥
                {Utils.formatCurrency(
                  parseInt(pdfInfo?.software_selling_price)
                )}
              </Text>
            </View>
          </View>
          {/* Block 2*/}
          <View style={styles.estimateBlock}>
            <View style={styles.estimateItem}>
              <Text style={[styles.text, { width: "45%" }]}>{c}</Text>
              <View style={[styles.estimateItemRight, { width: "35%" }]}>
                {/* <Text style={styles.text}></Text> */}
                <Text style={[styles.text, styles.textPrice, {marginLeft: 12,textAlign: 'right'}]}>
                  ￥{Utils.formatCurrency(pdfInfo?.supports_price)}
                </Text>
                {/* <Text style={styles.text}></Text> */}
              </View>
              <View style={{width: '20%'}}></View>
            </View>
            {pdfInfo?.supports?.length > 0 &&
              pdfInfo?.supports?.map((item, index) => (
                <View key={index} style={[styles.estimateItem, {marginLeft: 12}]}>
                  <Text style={[styles.text, { width: "45%" }]}>
                    {item?.product_name}
                  </Text>
                  <View style={[styles.estimateItemRight, { width: "35%" }]}>
                    {/* <Text style={[styles.text]}></Text> */}
                    <Text style={[styles.text, {textAlign: 'right'}]}>
                      ￥
                      {Utils.formatCurrency(parseInt(item?.price))
                        ? Utils.formatCurrency(parseInt(item?.price))
                        : "0"}
                    </Text>
                    {/* <Text style={[styles.text]}></Text> */}
                  </View>
                  <View style={{width: '20%'}}></View>
                </View>
              ))}
            {/* <View style={styles.estimateItem}>
              <Text style={styles.text}>Standard plan: TypeB</Text>
            </View> */}
            <View style={styles.totalWrapper}>
              <Text style={[styles.text, styles.textPrice]}>
                {a}: ￥
                {pdfInfo?.supports_price
                  ? Utils.formatCurrency(pdfInfo?.supports_price)
                  : "0"}
              </Text>
            </View>
          </View>
          {/* Total Estimate */}
          <View style={styles.estimateDetailTotal}>
            <Text style={[styles.text, styles.textPrice]}>
              {i}: ￥{Utils.formatCurrency(pdfInfo?.total_price)}
            </Text>
            <Text style={[styles.text, styles.textPrice]}>
              {j}(10%): ￥{Utils.formatCurrency(pdfInfo?.tax)}
            </Text>
            <Text style={[styles.text, styles.textPrice]}>
              {l}: ￥{Utils.formatCurrency(pdfInfo?.total_price_includes_tax)}
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.text}>{n}</Text>
          <Text style={[styles.text]}>{o}</Text>
          <Text style={[styles.text, { marginLeft: 12 }]}>
            {hwInfo?.branch}
          </Text>
          <Text style={[styles.text, { marginLeft: 12 }]}>
            Memory: {hwInfo?.memory}
          </Text>
          <Text style={[styles.text, { marginLeft: 12 }]}>
            CPU: {hwInfo?.cpu}
          </Text>
          <Text style={[styles.text, { marginLeft: 12 }]}>
            {p} : {hwInfo?.storage}
          </Text>
          <Text style={[styles.text, { marginLeft: 12 }]}>
            {h}：{hwInfo?.quantity} {language === "GB" ? "" : "台"}
          </Text>
          <Text style={[styles.text, { marginLeft: 12 }]}>{q}</Text>
          <Text style={styles.text}>{r}</Text>
          {pdfInfo?.software_names?.length > 0 &&
            pdfInfo?.software_names.map((item, index) => (
              <Text key={index} style={[styles.text, { marginLeft: 12 }]}>
                {item}
              </Text>
            ))}
          <Text style={styles.text}>{t}</Text>
          <Text style={[styles.text, { marginLeft: 12 }]}>
            {pdfInfo?.num_months}
            {u} ( {language === "GB" && "from "} {pdfInfo?.start_date} {to}{" "}
            {pdfInfo?.end_date})
          </Text>
        </View>
      </Page>
    </Document>
  );
};

const PdfView = (props) => {
  const pdfInfo = useSelector((state) => state.EstimateReducer.pdfInfo);

  localStorage.setItem('LANGUAGE', pdfInfo?.language);
  localStorage.setItem('i18nextLng', pdfInfo?.language);

  console.log({ pdfInfo });
  return (
    <div>
      <PDFViewer 
        style={{ width: "100vw", height: "100vh" }}
      >
        <MyDocument pdfInfo={pdfInfo} />
      </PDFViewer>
      {/* <PDFDownloadLink
        document={<MyDocument pdfInfo={pdfInfo}/>}
        fileName="info.pdf"
      >
        {({ blob, url, loading, error, ...e }) =>
          loading ? "Loading document..." : "Download now!"
        }
      </PDFDownloadLink> */}
    </div>
  );
};

export default PdfView;
