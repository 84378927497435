import React, { Component } from 'react';
import './style.css'
import { withTranslation } from "react-i18next";
import { API } from "../../services/APIs";
import { httpService } from "../../services/httpService";
import Alert from 'react-s-alert';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_password: '',
            new_password: '',
            confirm_password: ''
        }
    }

    onChangePassword = async (e) => {
        e.preventDefault();
        const { current_password, new_password, confirm_password } = this.state;
        if (!current_password || !new_password || !confirm_password) {
            Alert.warning('change password.Please enter your current password or new password or confirm password'.t(), {
                timeout: 5000,
                offset: -10
            })
        } else if (new_password !== confirm_password) {
            Alert.warning('change password.The new password and confirm password do not match'.t(), {
                timeout: 5000,
                offset: -10
            })
        } else if (current_password === new_password) {
            Alert.warning('change password.The new password is the same with old password, you must enter different'.t(), {
                timeout: 5000,
                offset: -10
            })
        } else {
            try {
                const response = await httpService.post(API.POST_CHANGE_PASSWORD, { old_password: current_password, new_password, confirm_new_password: confirm_password });
                if (response.status === "success") {
                    Alert.success('change password.The password is changed'.t(), {
                        timeout: 5000,
                        offset: -10
                    })
                    this.setState({
                        current_password: '',
                        new_password: '',
                        confirm_password: ''
                    })
                } else {
                    Alert.error(response.message, {
                        timeout: 5000,
                        offset: -10
                    })
                }
            } catch (e) {
                Alert.error(e.message, {
                    timeout: 5000,
                    offset: -10
                })
                console.log(e);
            }
        }
    }

    render() {
        return (
            <main className={'main-container summary'}>
                <Alert stack={{ limit: 1 }} />
                <div>
                    <div className="step-app prev-step" />
                    <div className="step-app current-step">
                        <div className="pannel-wrapper pannel-change-password">
                            <div className="change-password-container">
                                <div className="messages">
                                    {/*<div className="message message-error">*/}
                                    {/** {'change password.wrong password'.t()}*/}
                                    {/*</div>*/}
                                </div>
                                <form className="f-change-password" noValidate>
                                    <div className="field">
                                        <label className="label">{'change password.Current Password'.t()}</label>
                                        <div className="control">
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="current-password"
                                                id="current-password"
                                                value={this.state.current_password}
                                                onChange={text => this.setState({ current_password: text.target.value })}
                                            />
                                            <div className="mage-error">You must enter a text</div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">{'change password.New Password'.t()}</label>
                                        <div className="control">
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="new-password"
                                                id="new-password"
                                                value={this.state.new_password}
                                                onChange={text => this.setState({ new_password: text.target.value })}
                                            />
                                            <div className="mage-error">You must enter a text</div>
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label">{'change password.New Password (confirmation)'.t()}</label>
                                        <div className="control">
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="confirm-password"
                                                id="confirm-password"
                                                value={this.state.confirm_password}
                                                onChange={text => this.setState({ confirm_password: text.target.value })}
                                            />
                                            <div className="mage-error">You must enter a text</div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="application-step-actions">
                            <div className="next-step text-center">
                                <button style={{ width: 'auto' }} onClick={this.onChangePassword} className="btn btn-primary" type="button">{'change password.Send E-mail'.t()}</button>
                            </div>
                        </div>
                    </div>
                    <div className="step-app next-step" />
                </div>
            </main>
        )
    }
}

export default withTranslation()(ChangePassword)

