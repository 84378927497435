import { connect } from "react-redux";
import { fetchAccountDetail } from "./action";
import ConfirmNewRegistration from "./ConfirmNewRegistration";
import { changeRoute, changeTitle, nextStepSuccess, updateOnEstimate } from '../../redux/action/nextStepAction';
import { postRequestAccount } from './action';
import { getPersonalInfor, getTerm } from "../request-account/action";

const mapStateToProps = state => ({
    account_detail: state.AccountDetailReducer.account_detail,
    currentStep: state.NextStepReducer.currentStep,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    term: state.RequestAccountReducer.term,
    personal_information: state.RequestAccountReducer.personal_information,
    data_confirm: state.RequestAccountReducer.data_confirm,
    data: state.RequestAccountReducer.data
});

const mapDispatchToProps = dispatch => ({
    onGetAccountDetail: (user_code) => dispatch(fetchAccountDetail(user_code)),
    nextStepSuccess: () => dispatch(nextStepSuccess()),
    onChangeTitle: (title) => dispatch(changeTitle(title)),
    onChangeRoute: (route) => dispatch(changeRoute(route)),
    onRequestAccount: (data, history) => dispatch(postRequestAccount(data, history)),
    getTerm: () => dispatch(getTerm()),
    getPersonalInfor: () => dispatch(getPersonalInfor()),
    updateOnEstimate: (onEstimate) => dispatch(updateOnEstimate(onEstimate))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmNewRegistration)
