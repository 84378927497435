import { connect } from "react-redux";
import AdminExport from './AdminExport';
import { fetchExportFile, resetExport } from "./action";

const mapStateToProps = state => ({
    data: state.AdminExportReducer.data,
    disableLog: state.AdminExportReducer.disableLog,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    message_error: state.AdminExportReducer.message_error,
});

const mapDispatchToProps = dispatch => ({
    onExportFile: (data) => dispatch(fetchExportFile(data)),
    resetExport: () => dispatch(resetExport())
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminExport)
