import {connect} from "react-redux";
import Login from "./Login";
import {fetchLogin} from "./action";
import {resetRequestAccount} from "../request-account/action";
import {prevUserLogin} from "../../redux/action/nextStepAction";

const mapStateToProps = state => ({
    token: state.LoginReducer.token,
    user_type: state.LoginReducer.user_type,
    error: state.LoginReducer.error,
    fetching: state.LoginReducer.fetching,
    currentRoute: state.NextStepReducer.route
});

const mapDispatchToProps = dispatch => ({
   login: (data) => dispatch(fetchLogin(data)),
   resetRequestAccount: () => dispatch(resetRequestAccount())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
