import { FETCH_IMPORT, FETCH_IMPORT_ERROR, FETCH_IMPORT_SUCCESS, RESET_REDUCER_IMPORT } from "./actionType";

export function fetchImport(form) {
    return {
        type: FETCH_IMPORT,
        payload: form
    }
}
export function fetchImportSuccess(success) {
    return {
        type: FETCH_IMPORT_SUCCESS,
        payload: success
    }
}
export function fetchImportError(error, data) {
    return {
        type: FETCH_IMPORT_ERROR,
        payload: error,
        data
    }
}

export function resetReducer() {
    return {
        type: RESET_REDUCER_IMPORT,
    }
}
