import { takeLatest, put } from 'redux-saga/effects';
import { API } from '../../services/APIs';
import { httpService } from '../../services/httpService';
import Alert from 'react-s-alert';
import { persistor } from '../../redux/store';

import * as responseCode from '../../constant/response-code';
import { CONST } from '../../config/const';
import { updateOnEstimate } from '../../redux/action/nextStepAction';

import {
    FETCH_ESTIMATE,
    FETCH_ESTIMATE_SUCCESS,
    FETCH_ESTIMATE_ERROR,
    UPDATE_ESTIME_FILE,
    GET_PDF_INFO
} from './actionType';

import {
    fetchEstimate,
    fetchEstimateSuccess,
    fetchEstimateError,
    updateEstimateFileSuccess,
    updateEstimateFileError,
    updateIsLoadingEstimate,
    updateEstimateFileStatus,
    getPdfInfoSuccess,
    getPdfInfoError
} from './action';

function* getEstimate(action) {
    try {
        yield put(updateIsLoadingEstimate(true));
        let language = localStorage.getItem(CONST.LANGUAGE)
        const url = API.GET_ESTIMATE_DETAIL + `?estimate_no=${action.payload}&language=${language}`;
        // const res = yield httpService.getWithoutToken(url);
        const res = yield httpService.get(url);
        if (res.status == responseCode.SUCCESS) {
            if(res?.data?.note?.length > 0) {
                const message = res?.data?.note?.join(' <br> ');
                console.log({message});
                // res?.data?.note?.map(item => {
                    Alert.warning(message, {
                        html: true,
                        timeout: require("../../config/const").TIMEOUT,
                        // timeout: 10000,
                        effect: 'scale'
                    })
                // })
            }
            yield put(fetchEstimateSuccess(res.data));
        }
        if (res.code == responseCode.NOT_FOUND) {
            yield put(fetchEstimateError(res.message));
        }
        yield put(updateIsLoadingEstimate(false));
    } catch (e) {
        console.log(e);
        yield put(updateIsLoadingEstimate(false));
    }
}

function* updateEstimateFile(action) {
    try {
        const language = localStorage.getItem(CONST.LANGUAGE);
        let data = { ...action.payload.data, language }
        yield put(updateIsLoadingEstimate(true));
        // const res = yield httpService.postWithoutToken(API.UPDATE_ESTIMATE_FILE, data);
        const res = yield httpService.post(API.UPDATE_ESTIMATE_FILE, data);
        if (res.code === 200) {
            if (res.status === responseCode.SUCCESS) {
                yield persistor.purge();
                yield persistor.pause();
                yield put(updateEstimateFileSuccess());
                yield put(updateIsLoadingEstimate(false));
                yield put(updateOnEstimate(false));
                yield put(updateEstimateFileStatus(CONST.STATUS_ESTIMATE_SUCCESS));
            }
            if (res.status === responseCode.ERROR) {
                yield put(updateEstimateFileError());
                yield put(updateIsLoadingEstimate(false));
                yield put(updateEstimateFileStatus(CONST.STATUS_ESTIMATE_ERROR));
            }
        }
        if (res.code === responseCode.NOT_FOUND) {
            yield put(updateEstimateFileError());
            yield put(updateIsLoadingEstimate(false));
            yield put(updateEstimateFileStatus(CONST.STATUS_ESTIMATE_ERROR));
        }
    } catch (e) {
        console.log(e);
        yield put(updateIsLoadingEstimate(false));
        yield put(updateEstimateFileStatus(CONST.STATUS_ESTIMATE_ERROR));
    }
}

function* getPdfInfo(action) {
    try {
        yield put(updateIsLoadingEstimate(true));
        let language = localStorage.getItem(CONST.LANGUAGE)
        const url = API.GET_PDF_INFO + `?estimate_no=${action.payload}&language=${language}`;
        // const res = yield httpService.getWithoutToken(url);
        console.log({url});
        const res = yield httpService.get(url);
        console.log({res});
        if (res.status == responseCode.SUCCESS) {
            yield put(getPdfInfoSuccess({url: res.url, language}));
        }
        if (res.code == responseCode.NOT_FOUND) {
            yield put(getPdfInfoError(res.message));
        }
        yield put(updateIsLoadingEstimate(false));
    } catch (e) {
        console.log(e);
        yield put(getPdfInfoError(false));
    }
}

export function* watchGetEstimate() {
    yield takeLatest(FETCH_ESTIMATE, getEstimate);
    yield takeLatest(UPDATE_ESTIME_FILE, updateEstimateFile)
    yield takeLatest(GET_PDF_INFO, getPdfInfo)
}
