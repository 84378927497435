import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API } from "../../services/APIs";
import { httpService } from "../../services/httpService";
import { CALCULATE_SUPPORT_PRICE, CALCULATING, FETCH_LIST_SUPPORT } from "./actionType";
import { fetchCalculateSuccess, fetchCalculateError, fetchListSupport, fetchListSupportSuccess, updateListSupport } from "./action";
import * as responseCode from '../../constant/response-code';
import { CONST } from '../../config/const';

function* getListSupport(action) {
    try {
        // yield put(fetchListSupport(true));
        let language = localStorage.getItem(CONST.LANGUAGE);
        const url = API.GET_LIST_SUPPORT + `?language=${language}`;
        // const response = yield httpService.getWithoutToken(url);
        const response = yield httpService.get(url);
        if (response.code === 200) {
            if (response.status === responseCode.SUCCESS) {
                response.data.forEach(item => {
                    item.checked = false;
                    if(item.fixed_amount === '2') {
                        item.days = '';
                        item.defaultPrice = item.price;
                    }
                })
                yield put(fetchListSupportSuccess(response.data));
                // yield put(fetchListSupport(false));
            }
        }
    } catch (e) {
        console.log({ e })
    }
}

export function* watchGetListSupport() {
    yield takeEvery(FETCH_LIST_SUPPORT, getListSupport)
}

function* calculateSupportPrice(action) {
    try {
        yield put({
            type: CALCULATING, payload: {
                loading: true,
                data: action.payload
            }
        })
        const url = API.CALCULATE_SUPPORT_PRICE;

        // const response = yield httpService.postWithoutToken(url, action.payload);
        const response = yield httpService.post(url, action.payload);
        if (typeof (action.index) !== "undefined" || action.index) {
            yield put(updateListSupport(action.index));
        }
        console.log({ response });

        if (response.code === 200) {
            if (response.status === responseCode.SUCCESS) {
                yield put(fetchCalculateSuccess(response.data || []));
                // yield put({ type: CALCULATING, payload: { loading: false } });
                yield getListSupport();
                // yield put({ type: FETCH_LIST_SUPPORT });

            }
            else {
                yield put(fetchCalculateError(response.message));
            }
        }
        else {
            yield put(fetchCalculateError(response.message));
        }
    } catch (e) {
        console.log({ e })
        yield put(fetchCalculateError("error"));
    }
}
export function* watchCalculateSupportPrice() {
    yield takeLatest(CALCULATE_SUPPORT_PRICE, calculateSupportPrice)
}
