import {
    FETCH_ESTIMATE,
    FETCH_ESTIMATE_SUCCESS,
    FETCH_ESTIMATE_ERROR,
    UPDATE_ESTIME_FILE_SUCCESS,
    UPDATE_ESTIME_FILE_ERROR,
    UPDATE_IS_LOADING_ESTIMATE,
    UPDATE_ESTIMATE_FILE_STATUS,
    GET_PDF_INFO_SUCCESS
} from './actionType';
import { FETCH_CLASSIFICATION_ERROR } from '../use-application/actionType';
import { CLEAR_ESTIMATE } from '../../redux/actionType/nextStepActionType';

const DEFAULT_STATE = {
    estimateData: [],
    pdfInfo: {},
    update_estimate_file_success: false,
    isLoadingEstimate: false,
    update_estiamte_file_status: null,
    success: false,
    error: null,
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_ESTIMATE:
            return {
                ...state
            }
        case FETCH_ESTIMATE_SUCCESS:
            return {
                ...state,
                estimateData: action.payload
            }
        case FETCH_CLASSIFICATION_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case UPDATE_ESTIME_FILE_SUCCESS:
            return {
                ...state,
                update_estimate_file_success: true,
                success: true,
            }
        case UPDATE_ESTIME_FILE_ERROR:
            return {
                ...state,
                update_estimate_file_success: false,
                success: false
            }
        case UPDATE_IS_LOADING_ESTIMATE:
            return {
                ...state,
                isLoadingEstimate: action.payload
            }
        case UPDATE_ESTIMATE_FILE_STATUS:
            return {
                ...state,
                update_estiamte_file_status: action.payload
            }
        case GET_PDF_INFO_SUCCESS: 
            return {
                ...state,
                pdfInfo: action.payload
            }
        case CLEAR_ESTIMATE:
            return {
                ...state,
                estimateData: [],
                update_estimate_file_success: false,
                isLoadingEstimate: false,
                update_estiamte_file_status: null,
                success: false,
                error: null,
            }
        default:
            return state;
    }
}