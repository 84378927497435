import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { API } from "../../services/APIs";
import { httpService } from "../../services/httpService";
import { FETCH_ACCOUNT_DETAIL, POST_REQUEST_ACCOUNT_CONFIRM } from "./actionType";
import {
    fetchAccountSuccess,
    postRequestAccountFailed,
    postRequestAccountSuccess
} from "./action";
import * as responseCode from '../../constant/response-code';
import { CONST } from "../../config/const";
import { Utils } from "../../config/utils";
import { nextStepSuccess, updateOnEstimate, clearEstimate } from '../../redux/action/nextStepAction';
import { resetRequestAccount } from '../request-account/action';

function* getAccountDetail(action) {
    try {
        // yield put(changeLoadingNextStep(true));
        const url = API.GET_ACCOUNT_DETAIL + `?user_code=${action.payload}`;
        const response = yield httpService.getWithoutToken(url);
        if (response.status === responseCode.SUCCESS) {
            if (response.status === 'success') {
                yield put(fetchAccountSuccess(response.data));
            }
        }
        // yield put(changeLoadingNextStep(false));
    } catch (e) {
        // yield put(changeLoadingNextStep(false));
        console.log(e);
    }
}

export function* watchGetAccountDetail() {
    yield takeEvery(FETCH_ACCOUNT_DETAIL, getAccountDetail)
}

function* postRequestAccount(action) {
    try {
        console.log({ data: action.payload })
        const url = API.POST_REQUEST_ACCOUNT;
        const res = yield httpService.postWithoutToken(url, action.payload.data);
        console.log({ res })
        if (res.status === responseCode.SUCCESS) {
            // yield localStorage.setItem(CONST.USER_CODE, res.data.user_code);
            // yield put(postRequestAccountSuccess(res.data.user_code));
            // yield put(nextStepSuccess("aaa"));
            // yield put(updateOnEstimate(true));
            // yield action.payload.history.push('/estimate');
            // yield put(Utils.logOut(action.payload.history));
            window.location.href = 'https://science-cloud.tech/login';
//            window.location.href = 'https://www.hpc.co.jp';
            yield put(resetRequestAccount());
            yield put(clearEstimate());
        } else {
            yield put(postRequestAccountFailed(res.message))
            yield put(updateOnEstimate(false));
        }
    } catch (error) {
        yield put(postRequestAccountFailed(error.message));
        yield put(updateOnEstimate(false));
        console.log(error);
    }
}

export function* watchPostRequestAccount() {
    yield takeEvery(POST_REQUEST_ACCOUNT_CONFIRM, postRequestAccount);
}