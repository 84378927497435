export const listAdminImport = [
    {
        table_name: 'classification_master',
        label: 'admin import.Classnameification Mater'
    },
    {
        table_name: 'user_master',
        label: 'admin import.User Master'
    },
    {
        table_name: 'software_master',
        label: 'admin import.Software Master'
    },
    {
        table_name: 'control_master',
        label: 'admin import.Control Master'
    },
    {
        table_name: 'hardware_master',
        label: 'admin import.Hardware Master'
    },
    {
        table_name: 'estimate_work',
        label: 'admin import.Estimate Work'
    },
    {
        table_name: 'support_master',
        label: 'admin import.Support Master'
    },
    {
        table_name: 'estimate_file',
        label: 'admin import.Estimate File'
    },
    {
        table_name: 'price_master',
        label: 'admin import.Price Master'
    },
]