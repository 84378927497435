import debounce from 'lodash/debounce';
import { CONST } from './const';
import { persistor } from '../redux/store';
import moment from 'moment';

export const Utils = {
    debounceSearch: debounce((data, cb) => {
        cb(data)
    }, 1000),
    debounceCalculate: debounce((cb) => {
        cb()
    }, 500),
    getStartDate: (stringDate) => {
        return moment(stringDate).format("YYYY-MM-DD").split('-').join('/');
    },
    getEndDate: (endMonth) => {
        return moment(endMonth).endOf('month').format("YYYY-MM-DD").split('-').join('/');
    },
    getEndMonth: (endMonth) => {
        return moment(endMonth).format("YYYY-MM").split('-').join('/');
    },
    checkIndex: (index) => {
        if (index < 10) {
            return `0${index}`;
        }
        return index;
    },
    formatCurrency(n, f = 0) {
        if (n === "undefined" || n === null || typeof n === "undefined") return 0;
        if (f > 0) return n.toFixed(f).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
        if (n === 0) return '0';
        else return n.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    logOut: async (history) => {
        await sessionStorage.removeItem(CONST.TOKEN);
        await sessionStorage.removeItem(CONST.USER_NAME);
        await sessionStorage.removeItem(CONST.USER_TYPE);
        persistor.purge();
        await history.push('/login');
    },
    disableOldMonth(date) {
        date.setDate(1)
        return date
    },
    updateValue: (valueArray, updateArray, keyUpdate) => {
        return updateArray.map(obj => {
            if (valueArray.find(o => o[keyUpdate] === obj[keyUpdate])) {
                let arr = valueArray.find(o => o[keyUpdate] === obj[keyUpdate])
                return { ...arr, 
                    classification_name: obj.classification_name, 
                    support_name: obj.support_name, 
                    days: arr.days,
                    support_overview: obj.support_overview }
            } else return obj
        });
    }

}
export function tr(text) {
    const search = "０１２３４５６７８９　";
    const replace = '0123456789 '
    // Make the search string a regex.
    var regex = RegExp('[' + search + ']', 'g');
    var t = text.replace(regex,
        function (chr) {
            // Get the position of the found character in the search string.
            var ind = search.indexOf(chr);
            // Get the corresponding character from the replace string.
            var r = replace.charAt(ind);
            return r;
        });
    return t;
}
