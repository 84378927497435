const BASE_URL_PROD = 'https://science-cloud.tech/api/v1/';

// const BASE_URL_DEV = 'http://73.arrowhitech.net:35000/v1/';
// const BASE_URL_DEV = 'http://hpcs-api.arrowhitech.net/v1/';
const BASE_URL_DEV = 'https://science-cloud.tech/api/v1/';

const BASE_URL = process.env.NODE_ENV !== "production" ? BASE_URL_DEV : BASE_URL_PROD  ;

export const API = {
    LOGIN: BASE_URL + 'login',
    GET_LIST_SOFTWARE: BASE_URL + 'get-list-software',
    CALCULATE_SUPPORT_PRICE: BASE_URL + 'calculate-support-price',
    REQUEST_ACCOUNT: BASE_URL + 'request-account',
    GET_LIST_SUPPORT: BASE_URL + 'get-list-support',
    GET_ESTIMATE_DETAIL: BASE_URL + 'get-estimate', //QUERY PARAM eg: ?estimate_no=20191211001
    UPDATE_ESTIMATE: BASE_URL + 'update-estimate',
    GET_USAGE_HISTORY: BASE_URL + 'get-usage-history',
    GET_ADMIN_USAGE_HISTORY: BASE_URL + 'get-usage-history-admin',
    GET_SUMMARY: BASE_URL + 'get-summary', //QUERY PARAM eg: ?estimate_no=20191211001
    GET_LIST_HARDWARE: BASE_URL + 'get-list-hardware', // ???
    GET_CLASSIFICATION: BASE_URL + 'get-classification', //QUERY PARAM eg: ?type=1
    GET_SETTING: BASE_URL + 'get-setting', //QUERY PARAM eg: ?control_code=003
    CHECK_HARDWARE: BASE_URL + 'check-hardware', //QUERY PARAM eg: ?code=hw001

    GET_SOFTWARE_VERSION: BASE_URL + 'get-software-versions', //QUERY PARAM eg: ?name=Guassan
    POST_REQUEST_ACCOUNT: BASE_URL + 'request-account',
    VALIDATE_ACCOUNT_INFO: BASE_URL + 'validate-account-info',
    GET_ACCOUNT_DETAIL: BASE_URL + 'get-account-detail',

    // POST_REQUEST_ACCOUNT: BASE_URL + 'request-account',

    POST_FORGOT_PASSWORD: BASE_URL + 'forgot-password',
    POST_CHANGE_PASSWORD: BASE_URL + 'change-password',
    SEARCH_USER: BASE_URL + 'search-user',
    SEARCH_SOFTWARE: BASE_URL + 'search-software',
    SEARCH_HARDWARE: BASE_URL + 'search-hardware',
    SEARCH_SUPPORT: BASE_URL + 'search-support',
    UPDATE_ESTIMATE_FILE: BASE_URL + 'update-estimate-file',
    UPDATE_ESTIMATE_STATUS: BASE_URL + 'update-estimate-status',
    IMPORT: BASE_URL + 'import',
    EXPORT: BASE_URL + 'export',
    TERM_OF_SERVICE: BASE_URL + 'get-setting',
    PERSONAL_INFORMATION: BASE_URL + 'get-setting',
    GET_LANG: BASE_URL + "get-list-language",

    GET_PDF_INFO: BASE_URL + 'get-pdf-info'
};
