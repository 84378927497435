import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./style.css";
import { withTranslation } from "react-i18next";
import Alert from "react-s-alert";
import { CONST } from "../../config/const";
import {
  STEP_ESTIMATE,
  STEP_ESTIMATE_LOGIN,
} from "../../constant/step-component";
import { persistor } from "../../redux/store";
import { Utils } from "../../config/utils";
class Estimate extends Component {

  componentDidMount() {
    this.props.onGetEstimate(this.props.estimate_no);
    this.props.getPdfInfo(this.props.estimate_no);

  }

  prevStep = () => {
    const { isLogin } = this.props;
    if (isLogin) {
      this.props.history.push(STEP_ESTIMATE_LOGIN.prevRoute);
    } else {
      this.props.history.push(STEP_ESTIMATE.prevRoute);
    }

    this.props.onChangeTitle(STEP_ESTIMATE.prevTitle);
    this.props.onChangeRoute(STEP_ESTIMATE.prevRoute);
  };

  nextStep = async () => {
    let { history } = this.props;
    let data = {
      estimate_no: this.props.estimate_no,
      user_code: sessionStorage.getItem(CONST.USER_CODE),
      timestamp: new URLSearchParams(this.props.pdfInfo?.url).get('ts')      
    };
    await this.props.updateEstimateFile(data, history);
  };
  closeModal = async () => {
    let { updateEstimateFileStatus, history, isLogin } = this.props;
    if (updateEstimateFileStatus === CONST.STATUS_ESTIMATE_SUCCESS) {
      // await persistor.purge();
      // await persistor.pause();
      this.props.clearEstimate();
      if (isLogin) {
        history.push("/");
        // await sessionStorage.removeItem(CONST.TOKEN);
        // await sessionStorage.removeItem(CONST.USER_NAME);
        // await sessionStorage.removeItem(CONST.USER_TYPE);
        // history.push('/login');
      } else {
        this.props.closeModal();
      }
    }
    if (updateEstimateFileStatus === CONST.STATUS_ESTIMATE_ERROR) {
      this.props.closeModal();
    }
  };

  handleNavigatePdf = () => {
    // const win = window.open(`/pdf`, "_blank");
    // win.localStorage.setItem('i18nextLng', localStorage.getItem('i18nextLng'))
    // win.localStorage.setItem('LANGUAGE', localStorage.getItem('LANGUAGE'))
    const BASE_URL_PROD = "https://science-cloud.tech/api/";
    const BASE_URL_DEV = "https://science-cloud.tech/api/";

    const BASE_URL =
        process.env.NODE_ENV !== "production" ? BASE_URL_DEV : BASE_URL_PROD  ;
    window.location.href = BASE_URL + this.props.pdfInfo?.url;
  };

  render() {
    const lang = localStorage.getItem(CONST.LANGUAGE);
    let notification, data_account;
    let isLoading = true;
    if(this.props.pdfInfo?.url) isLoading = false;
    console.log(this.props.pdfInfo?.url)
    const {
      estimateData,
      isLoadingEstimate,
      updateEstimateFileStatus,
      isFullSideBar,
      success,
      account_detail,
      isLogin,
      data_confirm,
      pdfInfo
    } = this.props;
    const user_name = sessionStorage.getItem(CONST.USER_NAME);
    if (updateEstimateFileStatus === CONST.STATUS_ESTIMATE_SUCCESS) {
      notification = "notification.update estimate file success".t();
    }
    if (updateEstimateFileStatus === CONST.STATUS_ESTIMATE_ERROR) {
      notification = "notification.update estimate file error".t();
    }
    console.log({ updateEstimateFileStatus });
    if (isLogin) {
      data_account = account_detail;
    } else {
      data_account = data_confirm;
    }

    console.log({ estimateData, pdfInfo });

    return (
        <div className={`${isFullSideBar ? "" : "disable-menu"}`}>
          <main className={"main-container summary"}>
            <div
                className={`loading-estimate ${
                    isLoadingEstimate && "on-loading-estimate"
                }`}
            >
              <div className="loading-estimate-image">
                <img
                    className="w-100 h-100"
                    src={require("../../assets/images/loading-next-step.gif")}
                />
              </div>
            </div>
            <Alert stack={{ limit: 7 }} />
            <div style={{ position: "relative" }}>
              <div className="step-app prev-step" />
              <div className="step-app current-step">
                <div className="pannel-wrapper usage-amount">
                  <div className="application-date f-16">
                  <span className="label">
                    {"estimate.Application date".t()}:&nbsp;
                  </span>
                    <span className="rule">{estimateData?.application_date}</span>
                  </div>
                  <div className="user-name f-16">
                  <span className="label" style={{ fontSize: 18 }}>
                    {"usage amount.Total Price".t()}
                  </span>
                  </div>
                  <div className="user-name f-16">
                    <span className="label">{"estimate.User".t()}:</span>
                    {lang === "GB" ? (
                        // <span className="rule">Mr/Ms {data_account?.user_first_name + " " + data_account?.user_last_name}</span>
                        // : <span className="rule">{data_account?.user_first_name + " " + data_account?.user_last_name} 様</span>
                        <span className="rule">Mr/Ms {estimateData?.company_name}</span>
                    ) : (
                        <span className="rule">{estimateData?.company_name} 様</span>
                    )}
                  </div>
                  <ul className="usage-amount-info f-16">
                    <li>
                    <span className="label">
                      {"estimate.Estimate Number".t()}:
                    </span>
                      <span className="rule">{estimateData?.estimate_no}</span>
                    </li>
                    <li>
                    <span className="label">
                      {"estimate.Total Price (included tax)".t()}:
                    </span>
                      <span className="rule">
                      ¥{Utils.formatCurrency(estimateData?.total_price)}
                    </span>
                    </li>
                    <li>
                    <span className="label">
                      {"estimate.Expiration date".t()}:
                    </span>
                      <span className="rule">
                      {estimateData?.expiration_date}
                    </span>
                    </li>
                    <li>
                    <span className="label">
                      {"estimate.Desired period".t()}:
                    </span>
                      <span className="rule">
                      <b>{"estimate.Start Date".t()}</b>
                        {estimateData?.start_date}
                        <b>{"estimate.End Date".t()}</b>
                        {estimateData?.end_date}
                    </span>
                    </li>
                  </ul>
                  <table className="table table-border-solid table-responsive-list usage-amount-table">
                    <thead>
                    <tr className="th-row">
                      <th className="col-no">{"estimate.No".t()}</th>
                      <th className="col-category">
                        {"estimate.Product Category".t()}
                      </th>
                      <th className="col-name" style={{ width: "100%" }}>
                        {"estimate.Product Name".t()}
                      </th>
                      <th className="col-name" style={{ minWidth: 100 }}>
                        {"estimate.Price".t()}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {estimateData?.items ? (
                        this.props.estimateData.items.map((item, index) => (
                            <tr key={index}>
                              <td className="col-no" data-th="No: ">
                                {index + 1}
                              </td>
                              <td className="col-clear" data-th="Product Category:">
                                {item.product_category}
                              </td>
                              <td className="col-software" data-th="Product Name:">
                                {item.product_name}
                              </td>
                              <td className="col-software" data-th="Product Name:">
                                {/* ￥{item.price ? Utils.formatCurrency(`${item.price}`) : '0'} */}
                                {item.price
                                    ? `¥${Utils.formatCurrency(parseInt(item.price))}`
                                    : ""}
                              </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                          <td className="text-center" colSpan={3}>
                            no data
                          </td>
                        </tr>
                    )}
                    </tbody>
                  </table>

                  <div>
                    {estimateData?.items
                        ? this.props.estimateData.items.map((item, index) => (
                            <div key={index} className="list-usage-amount">
                              <div>
                            <span style={{ fontWeight: "600" }}>
                              {"usage amount.No".t()}:&nbsp;&nbsp;&nbsp;&nbsp;
                            </span>
                                <span>{index + 1}</span>
                              </div>
                              <div>
                                <p style={{ fontWeight: "600" }}>
                                  {"usage amount.Product Category".t()}:&nbsp;&nbsp;
                                </p>
                                <p>{item?.product_category} </p>
                              </div>
                              <div>
                                <p style={{ fontWeight: "600" }}>
                                  {"usage amount.Product Name".t()}:&nbsp;&nbsp;
                                </p>
                                <p>{item?.product_name}</p>
                              </div>
                              <div>
                                <p style={{ fontWeight: "600" }}>
                                  {"estimate.Price".t()}:&nbsp;&nbsp;
                                </p>
                                <p>
                                  {item.price
                                      ? `¥${Utils.formatCurrency(
                                          parseInt(item.price)
                                      )}`
                                      : ""}
                                </p>
                              </div>
                            </div>
                        ))
                        : null}
                  </div>
                </div>
                <div className="application-step-actions tw-buttons">
                  {!success ? (
                      <div className="next-step text-center">
                        <button
                            id="export-pdf"
                            disabled={isLoading}
                            onClick={() => this.handleNavigatePdf()}
                            className={`btn btn-secondary btn-download-pdf ${isLoading ? 'btn-loading' : ''}`}
                            type="button"
                        >
                          <div>{"estimate.Export PDF".t()}</div>
                          <img style={{ width: 20, height: 20 }} src={require('../../assets/images/loading-next-step.gif')} />
                        </button>
                        <button
                            id="back-application"
                            onClick={this.prevStep}
                            className="btn btn-secondary"
                            type="button"
                        >
                          {"estimate.Back".t()}
                        </button>
                        <button
                            id="next-summary"
                            disabled={isLoading}                           
                            onClick={this.nextStep}
                            className="btn btn-primary"
                            type="button"
                        >
                          {"estimate.Estimate".t()}
                        </button>
                      </div>
                  ) : null}
                </div>
              </div>
              <div
                  className={`modal-estimate ${
                      updateEstimateFileStatus !== null ? "show-modal-estimate" : ""
                  }`}
              >
                <div className="modal-estimate-content">
                  <div className="modal-estimate-title">
                    <p>{"notification.Estimate Notification".t()}</p>
                  </div>
                  <div className="modal-estimate-body">
                    <p
                        className={`${
                            updateEstimateFileStatus === CONST.STATUS_ESTIMATE_SUCCESS
                                ? "text-primary"
                                : "text-danger"
                        }`}
                    >
                      {notification}
                    </p>
                  </div>
                  <div className="modal-estimate-footer">
                    <button
                        className={`btn ${
                            updateEstimateFileStatus === CONST.STATUS_ESTIMATE_SUCCESS
                                ? "btn-primary"
                                : "btn-danger"
                        }`}
                        onClick={this.closeModal}
                    >
                      {"notification.OK".t()}
                    </button>
                  </div>
                </div>
              </div>
              <div className="step-app next-step" />
            </div>
          </main>
        </div>
    );
  }
}
export default withTranslation()(withRouter(Estimate));
