import { connect } from "react-redux";
import AdminUsageHistoryInquiry from "./AdminUsageHistoryInquiry";
import {
    searchUser,
    searchSoftware,
    searchHardware,
    searchSupport,
    updateSearchForm,
    updateUserName,
    getAdminUsage,
    updateOrders,
    updateEstimateStatus,
    resetSearch
} from './action';

const mapStateToProps = state => ({
    list_user: state.HistoryInquiryAdminReducer.list_user,
    list_software: state.HistoryInquiryAdminReducer.list_software,
    list_hardware: state.HistoryInquiryAdminReducer.list_hardware,
    list_support: state.HistoryInquiryAdminReducer.list_support,
    formSearch: state.HistoryInquiryAdminReducer.formSearch,
    user_name: state.HistoryInquiryAdminReducer.user_name,
    orders: state.HistoryInquiryAdminReducer.orders,
    isFullSideBar: state.NextStepReducer.isFullSideBar
})

const mapDispatchToProps = dispatch => ({
    searchUser: (history) => dispatch(searchUser(history)),
    searchSoftware: () => dispatch(searchSoftware()),
    searchHardware: () => dispatch(searchHardware()),
    searchSupport: () => dispatch(searchSupport()),
    updateSearchForm: (key, value) => dispatch(updateSearchForm(key, value)),
    updateUserName: (userName) => dispatch(updateUserName(userName)),
    onGetAdminUsage: (data) => dispatch(getAdminUsage(data)),
    onUpdateOrders: (order, index) => dispatch(updateOrders(order, index)),
    onUpdateEstimateStatus: (request) => dispatch(updateEstimateStatus(request)),
    resetSearch: () => dispatch(resetSearch())
})
export default connect(mapStateToProps, mapDispatchToProps)(AdminUsageHistoryInquiry)
