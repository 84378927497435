import React, { Component } from 'react';
import './admin-export.css';
import { withTranslation } from "react-i18next";
import { listAdminImport } from "../../constant/admin-import";
import Alert from 'react-s-alert';

const ResultExport = ({ data }) => {
	if (data === null) return null;
	console.log({ data });
	return (
		<>
			<p className={"import-succ"} >{data && data.message}</p>
		</>
	)
}
class AdminExport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			table_name: '',
			folder: '',
			show: false
		};
		this.handleExport = this.handleExport.bind(this);
	}

	componentDidMount() {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	}
	componentWillUnmount() {
		console.log("---------------------------------");

		this.props.resetExport()
	}
	selectClass(item) {
		this.setState({ table_name: item.table_name })
	}

	handleExport(e) {
		e.preventDefault();
		const { table_name } = this.state;
		if (!table_name) {
			Alert.warning('admin export.You must choose a table to export'.t(), {
				timeout: require('../../config/const').TIMEOUT,
				effect: 'scale',
				offset: -10
			})
		} else {
			this.props.onExportFile({ table_name })
		}
	};

	handleShow = () => {
		this.setState({ show: true })
	};

	handleClose = () => {
		this.setState({ show: false })
	};

	render() {
		const { isFullSideBar, data, disableLog, message_error } = this.props;
		const { table_name } = this.state;
		return (
			<div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
				<Alert stack={{ limit: 1 }} />
				<main className={'main-container summary'}>
					<form className="admin-form-import" method="post" encType="multipart/form-data" action="#">
						<div className="pannel-wrapper pannel-export pannel-import">
							<div className="fields">
								<div className="multiple-fields row">
									{
										listAdminImport.map((item, index) => (
											<div className="field col-lg-6 col-12" key={index.toString()} onClick={this.selectClass.bind(this, item)}>
												<div className="control">
													<div className="custom-radio">
														<input
															type="radio"
															name="import-type"
															value={item.table_name === table_name}
															id={item.table_name}
															className="custom-control-input"
														/>
														<label className="custom-control-label text-nowrap color-black" htmlFor={item.table_name}>{item.label.t()}</label>
													</div>
												</div>
											</div>
										))
									}
								</div>
								<div className="mage-error">Please select options</div>
							</div>
						</div>
						{/* <div className="pannel-wrapper pannel-export pannel-upload-file">
							<div className="form-group">
								<input type="text" name="url-upload" className="form-control" id="file-text" />
								<div className="mage-error">You must enter a file or enter file url and upload</div>
							</div>
							<div className="file-upload-replace-button">
								<input type="file" id="file-upload" name="file-upload" />
								<label className="btn" htmlFor="file-upload" />
							</div>
						</div> */}
					</form>
					<div className="application-step-actions tw-buttons">
						<div className="next-step text-center">
							<button id="export" className="btn btn-primary" type="button" onClick={this.handleExport} disabled={this.state.table_name === ''}>{'admin export.Export'.t()}</button>
							{/* <div className="file-upload-replace-button">
								<input type="file" id="file-download"
									name="file-download"
									onChange={this.handleExport}
								/>
								<label className="btn import" htmlFor="file-download">{"admin export.Export".t()}</label>
							</div> */}
							<button id="log" className="btn btn-secondary" type="button" onClick={() => this.handleShow()} disabled={disableLog}>
								{'admin export.Log'.t()}
							</button>
						</div>
					</div>
					<ResultExport
						data={data}
					/>
					<div className={`modal-estimate ${this.state.show ? 'show-modal-estimate' : ''}`}>
						<div className="modal-estimate-content">
							<div className="modal-estimate-title">
								<p>{'use application.Notification'.t()}</p>
							</div>
							<div className="modal-estimate-body">
								<div className="warning">
									{message_error}
									{/* {
										error_file.map((item, index) =>
											<div key={index} className="warning-item">
												<p>{item}</p>
											</div>
										)
									} */}
								</div>
							</div>
							<div className="modal-estimate-footer">
								<button className='btn btn-secondary ml-2' onClick={this.handleClose}>{'notification.OK'.t()}</button>
							</div>
						</div>
					</div>
				</main>
			</div>
		)
	}
}

export default withTranslation()(AdminExport)
