import React, { Component } from 'react';
import './style.css'
import { withTranslation } from "react-i18next";
import { CONST } from '../../config/const';

class UsageHistoryInquiry extends Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        this.props.fetchUsageHistory(this.props.history);
    }

    render() {
        const lang = localStorage.getItem(CONST.LANGUAGE)
        let { usage_history_data, isFullSideBar } = this.props;
        let userName = sessionStorage.getItem(CONST.USER_NAME);
        let userNameJP = sessionStorage.getItem(CONST.USER_NAME_JP);

        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <main className={'main-container summary'}>
                    <div >
                        <div className="step-app prev-step"></div>
                        <div className="step-app current-step">
                            <div className="pannel-wrapper usage-history-inquiry">
                                <div className="user-name"><span className="label">{'usage history.User'.t()}:</span>
                                    {
                                        lang === "GB" ?
                                            <span className="rule">Mr/Ms {userName}</span>
                                            : <span className="rule">{userNameJP} 様</span>
                                    }

                                </div>
                                <div className="box-radius-top">
                                    <table
                                        className="table table-border-solid table-border-all table-responsive-list history-inquiry">
                                        <thead>
                                            <tr>
                                                <th className="t-center col-no" rowSpan="2">{'usage history.No'.t()}</th>
                                                <th className="col-estimate" rowSpan="2">{'usage history.Estimate number'.t()}</th>
                                                <th className="col-estimate" rowSpan="2">{'usage history.Application Date'.t()}</th>
                                                <th className="col-software" rowSpan="2">{'usage history.Software'.t()}</th>
                                                <th className="col-hardware" rowSpan="2">{'usage history.Hardware'.t()}</th>
                                                <th className="col-support" rowSpan="2">{"usage history.Support".t()}</th>
                                                <th className="t-center col-period" colSpan="3">{'usage history.Usage period'.t()}</th>
                                            </tr>
                                            <tr className="th-row-tw">
                                                <th className="t-center col-start t-end">{'usage history.Start Date'.t()}</th>
                                                <th className="t-center col-end t-end">{'usage history.End Date'.t()}</th>
                                                <th className="t-center t-end">{'usage history.Remaining days'.t()}</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ fontSize: 14, color: '#555' }}>
                                            {
                                                usage_history_data.length > 0 ?
                                                    usage_history_data.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="t-center" data-th="No:">{index + 1}</td>
                                                            <td className="" data-th="Estimate number:">{item.estimate_no}</td>
                                                            <td className="" data-th="Application Date:">{item.application_date}</td>
                                                            <td className="" data-th="Software:">
                                                                <ul>
                                                                    {item?.items?.software?.map((software, index) =>
                                                                        <li key={index}>{software.product_name}</li>
                                                                    )}
                                                                </ul>
                                                            </td>
                                                            <td className="" data-th="Hardware:">                                                             
                                                                    {item?.items?.hardware?.product_name}                                                              
                                                            </td>
                                                            <td className="" data-th="Support:">
                                                                <ul>
                                                                    {item?.items?.support?.map((support, index) =>
                                                                        <li key={index}>{support.product_name}</li>
                                                                    )}
                                                                </ul>
                                                            </td>
                                                            <td className="t-center" data-th="Start date:">{item.start_date}</td>
                                                            <td className="t-center" data-th="End date:">{item.end_date}</td>
                                                            <td className="t-center" data-th="Remaining days:">{item.remaining_days}</td>
                                                        </tr>
                                                    ) :
                                                    null
                                            }

                                        </tbody>
                                    </table>

                                    <div className="list-usage-history">
                                        {
                                            usage_history_data.length > 0 ?
                                                usage_history_data.map((item, index) =>
                                                    <div className="usage-history-item">
                                                        <div className="text_bold">
                                                            < div className="text_title_h">{'usage history.No'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</ div>
                                                            < div className="text_content_his">{index + 1}</ div>
                                                        </div>
                                                        <div className="text_bold">
                                                            < div className="text_title_h">{'usage history.Estimate number'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</ div>
                                                            < div className="text_content_his">{item.estimate_no}</ div>
                                                        </div>
                                                        <div className="text_bold">
                                                            < div className="text_title_h">{'usage history.Software'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</ div>
                                                            <div className="text_content_his" >
                                                                <div >
                                                                    {item?.items?.software?.map((software, index) =>
                                                                        <li key={index}>{software.product_name}</li>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text_bold">
                                                            < div className="text_title_h">{'usage history.Hardware'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</ div>
                                                            <div className="text_content_his" >
                                                                <div>
                                                                    {item?.items?.hardware?.product_name}                                                                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text_bold">
                                                            < div className="text_title_h">{"usage history.Support".t()}:&nbsp;&nbsp;&nbsp;&nbsp;</ div>
                                                            <div className="text_content_his" >
                                                                <div>
                                                                    {item?.items?.support?.map((support, index) =>
                                                                        <li key={index}>{support.product_name}</li>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="text_bold">
                                                            < div className="text_title_h">{'usage history.Start Date'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</ div>
                                                            < div className="text_content_his" >{item.start_date}</ div>
                                                        </div>
                                                        <div className="text_bold">
                                                            < div className="text_title_h">{'usage history.End Date'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</ div>
                                                            < div className="text_content_his" >{item.end_date}</ div>
                                                        </div>
                                                        <div className="text_bold" >
                                                            < div className="text_title_h" >{'usage history.Remaining days'.t()}:&nbsp;&nbsp;&nbsp;&nbsp;</ div>
                                                            < div className="text_content_his" >{item.remaining_days}</ div>
                                                        </div>
                                                    </div>
                                                ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="step-app next-step"></div>
                    </div>
                </main>
            </div>
        )
    }
}

export default withTranslation()(UsageHistoryInquiry)
