//step route login
const STEP_USE_APPLICATION = {
    step: 1,
    prevRoute: '/',
    prevTitle: 'use application',
    currentRoute: '/',
    currentTitle: 'use application',
    nextRoute: '/hardware-setting',
    nextTitle: 'hardware setting',
    classNameLi: 'nav-item application content-center',
    classNameSpan: 'icon-holder application',
    isLogin: true,
    isAdmin: false,
};
const STEP_HARDWARE_SETTING = {
    step: 2,
    prevRoute: '/',
    prevTitle: 'use application',
    currentRoute: '/hardware-setting',
    currentTitle: 'hardware setting',
    nextRoute: '/summary',
    nextTitle: 'summary',
    classNameLi: 'nav-item hardware content-center',
    classNameSpan: 'icon-holder hardware',
    isLogin: true,
    isAdmin: false,
};
const STEP_SUMMARY = {
    step: 3,
    prevRoute: '/hardware-setting',
    prevTitle: 'hardware setting',
    currentRoute: '/summary',
    currentTitle: 'summary',
    nextRoute: '/application-details',
    nextTitle: 'application details',
    classNameLi: 'nav-item summary content-center',
    classNameSpan: 'icon-holder summary',
    isLogin: true,
    isAdmin: false,
};
const STEP_APPLICATION_DETAILS = {
    step: 4,
    prevRoute: '/summary',
    prevTitle: 'summary',
    currentTitle: 'application details',
    currentRoute: '/application-details',
    nextRoute: '/estimate',
    nextTitle: 'estimate',
    classNameLi: 'nav-item application-details content-center',
    classNameSpan: 'icon-holder application-details',
    isLogin: true,
    isAdmin: false,
};
const STEP_USAGE_AMOUNT = {
    step: 5,
    prevRoute: '/application-details',
    prevTitle: 'application details',
    currentRoute: '/usage-amount',
    currentTitle: 'usage amount',
    nextRoute: '/estimate',
    nextTitle: 'estimate',
    classNameLi: 'nav-item usage-amount content-center',
    classNameSpan: 'icon-holder usage-amount',
    isLogin: true,
    isAdmin: false,
};
const STEP_ACCOUNT_INFORMATION = {
    step: 6,
    prevRoute: '/usage-amount',
    prevTitle: 'usage amount',
    currentTitle: 'account information',
    currentRoute: '/account-information',
    nextRoute: '/estimate',
    nextTitle: 'estimate',
    classNameLi: 'nav-item confirm-new-registration content-center',
    classNameSpan: 'icon-holder confirm-new-registration',
    isLogin: true,
    isAdmin: false,
};
const STEP_ESTIMATE_LOGIN = {
    step: 5,
    prevRoute: '/application-details',
    prevTitle: 'application details',
    currentTitle: 'estimate',
    currentRoute: '/estimate',
    nextRoute: '/summary',
    nextTitle: 'summary',
    classNameLi: 'nav-item estimate content-center',
    classNameSpan: 'icon-holder estimate',
    isLogin: true,
    isAdmin: false,
};

const STEP_ESTIMATE = {
    step: 5,
    prevRoute: '/application-details',
    prevTitle: 'application details',
    currentTitle: 'estimate',
    currentRoute: '/estimate',
    nextRoute: '/summary',
    nextTitle: 'summary',
    classNameLi: 'nav-item estimate content-center',
    classNameSpan: 'icon-holder estimate',
    isLogin: true,
    isAdmin: false,
};

// step unlogin
const STEP_REQUEST_ACCOUNT = {
    step: 1,
    prevRoute: '/',
    prevTitle: 'request account',
    currentTitle: 'request account',
    currentRoute: '/',
    nextRoute: '/confirm-new-registration',
    nextTitle: 'confirm new registration',
    classNameLi: 'nav-item request-account content-center',
    classNameSpan: 'icon-holder request-account',
    isLogin: false,
    isAdmin: false,
};
const STEP_CONFIRM_NEW_REGISTRATION = {
    step: 2,
    prevRoute: '/',
    prevTitle: 'request account',
    currentTitle: 'confirm new registration',
    currentRoute: '/confirm-new-registration',
    nextRoute: '/login',
    nextTitle: 'login',
    classNameLi: 'nav-item confirm-new-registration content-center',
    classNameSpan: 'icon-holder confirm-new-registration',
    isLogin: false,
    isAdmin: false,
};
const STEP_LOGIN = {
    step: 0,
    prevRoute: '/login',
    prevTitle: 'login',
    currentTitle: 'login',
    currentRoute: '/login',
    nextRoute: '/login',
    nextTitle: 'login',
    classNameLi: 'nav-item confirm-new-registration content-center',
    classNameSpan: 'icon-holder confirm-new-registration',
    isLogin: false,
    isAdmin: false,
};


// step login
const STEP_USAGE_AMOUNT_LOGIN = {
    step: 5,
    prevRoute: '/application-details',
    prevTitle: 'application details',
    currentRoute: '/usage-amount',
    currentTitle: 'usage amount',
    nextRoute: '/estimate',
    nextTitle: 'estimate',
    classNameLi: 'nav-item usage-amount content-center',
    classNameSpan: 'icon-holder usage-amount',
    isLogin: true,
    isAdmin: false,
};

const USAGE_HISTORY_INQUIRY_USER = {
    step: 0,
    prevRoute: '/login',
    prevTitle: '',
    currentRoute: '/usage-history-inquiry',
    currentTitle: 'usage history inquiry',
    nextRoute: '',
    nextTitle: '',
    classNameLi: 'nav-item estimate content-center',
    classNameSpan: 'icon-holder usage-history-inquiry',
    isLogin: true,
    isAdmin: false,
}

const CHANGE_PASSWORD = {
    step: 0,
    prevRoute: '/login',
    prevTitle: '',
    currentRoute: '/change-password',
    currentTitle: 'change password',
    nextRoute: '',
    nextTitle: '',
    classNameLi: 'nav-item usage-history-inquiry content-center',
    classNameSpan: 'icon-holder change-password',
    isLogin: true,
    isAdmin: false,
}

const FORGOT_PASSWORD = {
    step: 0,
    prevRoute: '/login',
    prevTitle: '',
    currentRoute: '/forgot-password',
    currentTitle: 'forgot password',
    nextRoute: '',
    nextTitle: '',
    classNameLi: 'nav-item usage-history-inquiry content-center',
    classNameSpan: 'icon-holder change-password',
    isLogin: true,
    isAdmin: false,
}

const USAGE_HISTORY_INQUIRY_ADMIN = {
    step: 0,
    prevRoute: '/login',
    prevTitle: '',
    currentRoute: '/history-inquiry-admin',
    currentTitle: 'usage history inquiry',
    nextRoute: '',
    nextTitle: '',
    classNameLi: 'nav-item usage-history-inquiry content-center',
    classNameSpan: 'icon-holder usage-history-inquiry',
    isLogin: true,
    isAdmin: true,
}

const ADMIN_IMPORT = {
    step: 0,
    prevRoute: '/login',
    prevTitle: '',
    currentRoute: '/admin-import',
    currentTitle: 'admin import',
    nextRoute: '',
    nextTitle: '',
    classNameLi: 'nav-item admin-import content-center',
    classNameSpan: 'icon-holder admin-import',
    isLogin: true,
    isAdmin: true,
}

const ADMIN_EXPORT = {
    step: 0,
    prevRoute: '/login',
    prevTitle: '',
    currentRoute: '/admin-export',
    currentTitle: 'admin export',
    nextRoute: '',
    nextTitle: '',
    classNameLi: 'nav-item  admin-export content-center',
    classNameSpan: 'icon-holder admin-export',
    isLogin: true,
    isAdmin: true,
}

const userSideUp = [
    // STEP_USE_APPLICATION,
    // STEP_HARDWARE_SETTING,
    // STEP_SUMMARY,
    // STEP_APPLICATION_DETAILS,
    // STEP_USAGE_AMOUNT,
    STEP_REQUEST_ACCOUNT,
    STEP_CONFIRM_NEW_REGISTRATION,
    STEP_LOGIN
    // STEP_ESTIMATE,
]

const userSideUpLogin = [
    STEP_USE_APPLICATION,
    STEP_HARDWARE_SETTING,
    STEP_SUMMARY,
    STEP_APPLICATION_DETAILS,
    // STEP_USAGE_AMOUNT_LOGIN,
    // STEP_ACCOUNT_INFORMATION,
    STEP_ESTIMATE_LOGIN
]

const userSideDown = [
    USAGE_HISTORY_INQUIRY_USER,
    CHANGE_PASSWORD,
]

const adminRoute = [
    USAGE_HISTORY_INQUIRY_ADMIN,
    ADMIN_IMPORT,
    ADMIN_EXPORT,
]

export {
    STEP_USE_APPLICATION,
    STEP_HARDWARE_SETTING,
    STEP_SUMMARY,
    STEP_APPLICATION_DETAILS,
    STEP_USAGE_AMOUNT,
    STEP_REQUEST_ACCOUNT,
    STEP_CONFIRM_NEW_REGISTRATION,
    STEP_ESTIMATE,
    USAGE_HISTORY_INQUIRY_USER,
    CHANGE_PASSWORD,
    FORGOT_PASSWORD,
    USAGE_HISTORY_INQUIRY_ADMIN,
    ADMIN_IMPORT,
    ADMIN_EXPORT,
    STEP_USAGE_AMOUNT_LOGIN,
    STEP_ACCOUNT_INFORMATION,
    STEP_ESTIMATE_LOGIN,
    userSideUp,
    userSideDown,
    adminRoute,
    userSideUpLogin
}
