import React from 'react';
import MainApp from './route';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n, { _t } from "./i18n";
import { I18nextProvider, withTranslation } from "react-i18next";
import { sagaMiddleware } from '../src/redux/store';
import rootSaga from '../src/redux/sagas';
import { Provider } from "react-redux";
import { CONST } from "./config/const";

// redux persist
import { PersistGate } from 'redux-persist/lib/integration/react';
import { store, persistor } from "./redux/store";
import Loading from './views/loading/Loading';

// mandatory
import 'react-s-alert/dist/s-alert-default.css';
import "react-datepicker/dist/react-datepicker.css";

// optional - you can choose the effect you want
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

String.prototype.t = function () {
    let str = _t(this);
    str = str.replace('[missing "vi-VN.', '');
    str = str.replace('[missing "en-US.', '');
    str = str.replace('[missing "jp-JP.', '');
    str = str.replace('" translation]', '');
    return str;
}

class App extends React.Component {

    async componentDidMount() {
        // window.addEventListener("beforeunload", (ev) => {
        //     ev.preventDefault();
        //     // persistor.purge();
        //     return ev.returnValue = 'Are you sure you want to close?';
        // });
        // await persistor.purge();
        await localStorage.removeItem('persist:root');

        const language = await localStorage.getItem(CONST.LANGUAGE);
        if (language !== null && language !== 'en') {
            i18n.changeLanguage(language);
        } else {
            i18n.changeLanguage('JP');
            localStorage.setItem(CONST.LANGUAGE, i18n.language);
        }
        i18n.changeLanguage('JP');
        localStorage.setItem(CONST.LANGUAGE, 'JP');
    }


    render() {
        return (
            <div className="App">
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <PersistGate loading={<Loading />} persistor={persistor}>
                            <MainApp />
                        </PersistGate>
                    </Provider>
                </I18nextProvider>
            </div>
        );
    }
}
sagaMiddleware.run(rootSaga);

export default App;
