import { connect } from 'react-redux';
import RequestAccount from './RequestAccount';
import { getTerm, getPersonalInfor, postRequestAccount, updateData, onChangeData } from './action';
import { changeRoute, changeTitle, nextStepSuccess } from '../../redux/action/nextStepAction';

const mapStateToProps = state => ({
    msg: state.RequestAccountReducer.msg,
    user_code: state.RequestAccountReducer.user_code,
    currentStep: state.NextStepReducer.currentStep,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    data: state.RequestAccountReducer.data,
    term: state.RequestAccountReducer.term,
    personal_information: state.RequestAccountReducer.personal_information,
})

const mapDispatchToProps = dispatch => ({
    nextStepSuccess: () => dispatch(nextStepSuccess()),
    onRequestAccount: (data) => dispatch(postRequestAccount(data)),
    onChangeTitle: (title) => dispatch(changeTitle(title)),
    onChangeRoute: (route) => dispatch(changeRoute(route)),
    updateData: (data) => dispatch(updateData(data)),
    getTerm: () => dispatch(getTerm()),
    getPersonalInfor: () => dispatch(getPersonalInfor()),
    onChangeData: (data) => dispatch(onChangeData(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RequestAccount);
