import React from "react";
import Alert from "react-s-alert";
import { withRouter } from "react-router-dom";
import { CONST } from "../../config/const";
import { persistor } from "../../redux/store";
import { Utils } from "../../config/utils";
import "./style.css";
import i18n from "../../i18n";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
    };
  }

  componentDidMount() {
    const { user_type, history } = this.props;
    let token = sessionStorage.getItem(CONST.TOKEN);
    this.props.resetRequestAccount();
    i18n.changeLanguage("JP");
    localStorage.setItem(CONST.LANGUAGE, "JP");
    if (token) {
      if (user_type === CONST.ADMIN) {
        history.push("/history-inquiry-admin");
      } else {
        history.push("/");
      }
    }
  }

  componentWillMount() {
    const { user_type, history } = this.props;
    let token = sessionStorage.getItem(CONST.TOKEN);
  }

  handleLogin = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    const { currentRoute } = this.props;
    if (!username || !password) {
      Alert.warning("notification.Please enter your username or password".t(), {
        timeout: require("../../config/const").TIMEOUT,
      });
    } else {
      Utils.debounceSearch(
        { username, password, history: this.props.history, currentRoute },
        this.props.login
      );
    }
  };

  render() {
    const { fetching } = this.props;
/* 20211014 ADD Start */
    const windowOpen1 = () => {
          window.open('/Science_Cloud_manual.pdf','_blank', 'fullscreen=yes')
          }
/* 20211014 ADD End */

    return (
      <div className="login">
        <Alert stack={{ limit: 1 }} />
        <div className="banner-login">
          <div className="content">
            {/* <h3>Run your application and distribute it</h3>
                        <p>Let your application run on the cloud, on your servers and distribute it to others. Analysis
                            of the result in the same place.</p> */}
            {/* <img src={require("../../assets/images/logo/login_logo.png")} alt=""
                            className="img-fluid sign-in" /> */}
            <img
              src={require("../../assets/images/logo/login_logo1.jpg")}
              alt=""
              className="img-fluid sign-in"
            />
          </div>
        </div>
        <div className="login-wrapper">
          <div className="sign-in-wrapper">
            <div className="sign-in">
              <div className="logo">
                {/* <h1 className="login-title">Science Cloud</h1>
                                <div className="login-sub-title">Plaform to create the future</div> */}
                {/* <img className="img-fluid" src={require("../../assets/images/logo/HPCS_logo.png")}
                                    alt="" /> */}
                <img
                  className="img-fluid"
                  src={require("../../assets/images/logo/HPCS_logo.png")}
                  alt=""
                />
              </div>
              <nav className="nav nav-tabs tabs-login">
                <a
                  className="tab-link active-tab"
                  id="login-tab"
                  data-toggle="tab"
                  style={{ cursor: "default" }}
                  href="#login"
                >
                  Login
                </a>
              </nav>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="login">
                  <form onSubmit={this.handleLogin} method={"post"} noValidate>
                    <div className="form-group text-box">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email address"
                        onChange={(text) =>
                          this.setState({ username: text.target.value })
                        }
                      />
                      <div className="invalid-feedback feedback-pos">
                        Please input valid email.
                      </div>
                    </div>
                    <div className="form-group text-box">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="Password"
                        onChange={(text) =>
                          this.setState({ password: text.target.value })
                        }
                      />
                    </div>
                    <div className="form-group text-box">
                      <button
                        className="btn btn-default"
                        type="submit"
                        disabled={fetching}
                      >
                        Login
                      </button>
                      <a
                        className="nav-link"
                        id="forgot-password"
                        href="forgot-password"
                      >
                        Forgot password?
                      </a>
                    </div>
                    <div className="form-group text-box">
                      <a 
                        className="nav-link"
                        id="request-account"
                        href="https://science-cloud.tech/" 
                        target="_blank"
                      >
                        新規にアカウントを作成される場合はこちらをクリックして下さい。
                      </a>
                    </div>
                    <div className="form-group text-box">
                      <a  
                        id="manual"
                        onClick={windowOpen1}
                        target="_blank"
                      >
                        【操作マニュアル】初めてご利用される場合はこちらをクリックして下さい。
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="logo-wrapper">
            <img
              className="img-fluid"
              src={require("../../assets/images/logo/HPCS_logo1.png")}
              alt=""
            />
          </div>
          <footer className="main-footer">
            <div className={"main-footer-content"}>
              {/* <a className="ft-item" href="http://www.hpc.co.jp/aboutus.html" target="_blank">{'footer.about us'.t()} |</a> */}
              {/* <span
                                className="separator">|</span> */}
              <a className="ft-item" href="#" target="_blank">
                &nbsp;{"footer.privacy policy".t()} |
              </a>
              {/* <span
                                className="separator hide-mobile">|</span> */}
              <address>
                &nbsp;{"footer.copyright".t()}{" "}
                <a href="http://www.hpc.co.jp" target="_blank">
                  HPC SYSTEMS Inc.
                </a>
                &nbsp;{"footer.all rights reserved".t()}
              </address>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
