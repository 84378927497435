import {
    FETCH_LIST_SUPPORT_SUCCESS,
    FETCH_LIST_SUPPORT_ERROR,
    FETCH_LIST_SUPPORT,
    UPDATE_LIST_SUPPORTS,
    CALCULATE_SUPPORT_PRICE_SUCCESS, CALCULATE_SUPPORT_PRICE, CALCULATE_SUPPORT_PRICE_ERROR,
    UPDATE_END_MONTH,
    UPDATE_START_DATE,
    UPDATE_PURPOSE_OF_USE,
    RESET_IS_NEXT,
    UPDATE_ORDER_DAY
} from "./actionType";

export function fetchListSupport(loading) {
    return {
        type: FETCH_LIST_SUPPORT,
        payload: loading
    }
}

export function fetchListSupportSuccess(data) {
    return {
        type: FETCH_LIST_SUPPORT_SUCCESS,
        payload: data
    }
}

export function fetchListSupportError(error) {
    return {
        type: FETCH_LIST_SUPPORT_ERROR,
        payload: error
    }
}

export function fetchCalculate(data, index) {
    return {
        type: CALCULATE_SUPPORT_PRICE,
        payload: data,
        index
    }
}
export function fetchCalculateSuccess(data) {
    return {
        type: CALCULATE_SUPPORT_PRICE_SUCCESS,
        payload: data
    }
}
export function fetchCalculateError(error) {
    return {
        type: CALCULATE_SUPPORT_PRICE_ERROR,
        payload: error
    }
}

export function updateListSupport(index) {
    return {
        type: UPDATE_LIST_SUPPORTS,
        payload: index
    }
}

export function updateStartDate(date) {
    return {
        type: UPDATE_START_DATE,
        payload: date
    }
}

export function updateEndMonth(month) {
    return {
        type: UPDATE_END_MONTH,
        payload: month
    }
}

export function updatePurposeOfUse(text) {
    return {
        type: UPDATE_PURPOSE_OF_USE,
        payload: text
    }
}

export function resetIsNext() {
    return {
        type: RESET_IS_NEXT
    }
}

export function updateOrderDay(data) {
    return {
        type: UPDATE_ORDER_DAY,
        payload: data
    }
}