import {
    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    SEARCH_SOFTWARE,
    SEARCH_SOFTWARE_SUCCESS,
    SEARCH_HARDWARE,
    SEARCH_HARDWARE_SUCCESS,
    SEARCH_SUPPORT,
    SEARCH_SUPPORT_SUCCESS,
    UPDATE_SEARCH_FORM,
    UPDATE_USER_NAME,

    GET_ADMIN_USAGE,
    GET_ADMIN_USAGE_SUCCESS,
    GET_ADMIN_USAGE_ERROR,
    UPDATE_ORDERS,
    UPDATE_ESTIMATE_STATUS,
    UPDATE_ESTIMATE_STATUS_SUCCESS,
    UPDATE_ESTIMATE_STATUS_ERROR,
    RESET_SEARCH
} from './actionType'

export function searchUser(history) {
    return {
        type: SEARCH_USER,
        payload: history
    }
}

export function searchUserSuccess(data) {
    return {
        type: SEARCH_USER_SUCCESS,
        payload: data
    }
}

export function searchSoftware() {
    return {
        type: SEARCH_SOFTWARE,
    }
}

export function searchSoftwareSuccess(data) {
    return {
        type: SEARCH_SOFTWARE_SUCCESS,
        payload: data
    }
}

export function searchHardware() {
    return {
        type: SEARCH_HARDWARE,
    }
}

export function searchHardwareSuccess(data) {
    return {
        type: SEARCH_HARDWARE_SUCCESS,
        payload: data
    }
}

export function searchSupport() {
    return {
        type: SEARCH_SUPPORT,
    }
}

export function searchSupportSuccess(data) {
    return {
        type: SEARCH_SUPPORT_SUCCESS,
        payload: data
    }
}

export function updateSearchForm(key, value) {
    return {
        type: UPDATE_SEARCH_FORM,
        payload: {
            key, value
        }
    }
}

export function updateUserName(userName) {
    return {
        type: UPDATE_USER_NAME,
        payload: userName
    }
}

export function getAdminUsage(data) {
    return {
        type: GET_ADMIN_USAGE,
        payload: data
    }
}
export function getAdminUsageSuccess(data) {
    return {
        type: GET_ADMIN_USAGE_SUCCESS,
        payload: data
    }
}
export function getAdminUsageError(error) {
    return {
        type: GET_ADMIN_USAGE_ERROR,
        payload: error
    }
}

export function updateOrders(order, index) {
    return {
        type: UPDATE_ORDERS,
        payload: {
            order,
            index
        }
    }
}

export function updateEstimateStatus(request) {
    return {
        type: UPDATE_ESTIMATE_STATUS,
        payload: request
    }
}
export function updateEstimateStatusSuccess(success) {
    return {
        type: UPDATE_ESTIMATE_STATUS_SUCCESS,
        payload: success
    }
}
export function updateEstimateStatusError(error) {
    return {
        type: UPDATE_ESTIMATE_STATUS_ERROR,
        payload: error
    }
}

export function resetSearch() {
    return {
        type: RESET_SEARCH
    }
}