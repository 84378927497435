import {
    FETCHING,
    FETCHING_VERSION,
    FETCH_CLASSIFICATION,
    FETCH_SOFTWARE,
    FETCH_SOFTWARE_SUCCESS,
    FETCH_CLASSIFICATION_SUCCESS,
    FETCH_CLASSIFICATION_ERROR,
    FETCH_SOFTWARE_ERROR,
    FETCH_SOFTWARE_VERSION,
    FETCH_SOFTWARE_VERSION_SUCCESS,
    FETCH_SOFTWARE_VERSION_ERROR,
    NEXT_STEP_USE_APPLICATION,
    CLEAR_LIST_SOFTWARE_VERSION,
    CHANGE_CLASSIFICATION,
    CHANGE_PROCESSOR,
    ADD_SOFTWARE_ACTIVE,
    REMOVE_SOFTWARE_ACTIVE,
    UPDATE_SEARCH_KEYWORD_SOFTWARE,
    CLEAR_LIST_SOFTWARE_VERSION_SUCCESS,
    UPDATE_SOFTWARE_VERSION,
    LOADING_LIST_SOFTWARE
}
    from "./actionType";


export function fetching(isLoading) {
    return {
        type: FETCHING,
        payload: isLoading
    }
}

export function fetchingVersion(isLoadingVersion) {
    return {
        type: FETCHING_VERSION,
        payload: isLoadingVersion
    }
}

export function fetchClassification(type, processor, language, key_word, classification_code) {
    console.log({type, processor, language, key_word, classification_code})
    return {
        type: FETCH_CLASSIFICATION,
        payload: {
            type,
            processor,
            language,
            key_word,
            classification_code
        }
    }
}
export function fetchClassificationSuccess(data) {
    return {
        type: FETCH_CLASSIFICATION_SUCCESS,
        payload: data
    }
}

export function fetchClassificationError(message) {
    return {
        type: FETCH_CLASSIFICATION_ERROR,
        payload: message
    }
}

export function fetchSoftware(data) {
    return {
        type: FETCH_SOFTWARE,
        payload: data
    }
}

export function fetchSoftwareSuccess(data) {
    return {
        type: FETCH_SOFTWARE_SUCCESS,
        payload: data
    }
}

export function fetchSoftwareError(message) {
    return {
        type: FETCH_SOFTWARE_ERROR,
        payload: message
    }
}

export function fetchSoftwareVersion(softwareName) {
    return {
        type: FETCH_SOFTWARE_VERSION,
        payload: softwareName
    }
}

export function fetchSoftwareVersionSuccess(data) {
    return {
        type: FETCH_SOFTWARE_VERSION_SUCCESS,
        payload: data
    }
}

export function fetchSoftwareVersionError(message) {
    return {
        type: FETCH_SOFTWARE_VERSION_ERROR,
        payload: message
    }
}

export function nextStepUseApplication(data) {
    return {
        type: NEXT_STEP_USE_APPLICATION,
        payload: data
    }
}

export function clearListSoftwareVersion(data) {
    return {
        type: CLEAR_LIST_SOFTWARE_VERSION,
        payload: data
    }
}

export function clearListSoftwareVersionSuccess(data) {
    return {
        type: CLEAR_LIST_SOFTWARE_VERSION_SUCCESS,
        payload: data
    }
}

export function changeClassification(index) {
    return {
        type: CHANGE_CLASSIFICATION,
        payload: index
    }
}

export function changeProcessor(processor) {
    return {
        type: CHANGE_PROCESSOR,
        payload: processor
    }
}

export function addSoftwareActive(software) {
    return {
        type: ADD_SOFTWARE_ACTIVE,
        payload: software
    }
}

export function removeSoftwareActive(software) {
    return {
        type: REMOVE_SOFTWARE_ACTIVE,
        payload: software
    }
}

export function updateSearchKeyWord(keyword) {
    return {
        type: UPDATE_SEARCH_KEYWORD_SOFTWARE,
        payload: keyword
    }
}

export function updateSoftwareVersion(data) {
    return {
        type: UPDATE_SOFTWARE_VERSION,
        payload: data
    }
}

export function loadingListSoftware(isLoading) {
    return {
        type: LOADING_LIST_SOFTWARE,
        payload: isLoading
    }
}