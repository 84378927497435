import * as actionTypes from './actionType';
import Alert from 'react-s-alert';
export const postRequestAccount = (data) => ({
    type: actionTypes.POST_REQUEST_ACCOUNT,
    payload: data
});
export const postRequestAccountSuccess = (result) => {
    Alert.success('Request new account successfully', {
        timeout: require("../../config/const").TIMEOUT,
        effect: 'scale',
        offset: -10
    });
    return {
        type: actionTypes.POST_REQUEST_ACCOUNT_SUCCESS,
        payload: result
    }
};
export const postRequestAccountFailed = (msg) => {
    // console.log({ msg });
    // Alert.error('notification.This email address is already existed'.t(), {
    Alert.error(msg, {

        timeout: require("../../config/const").TIMEOUT,
        effect: 'scale',
        offset: -10
    });
    return {
        type: actionTypes.POST_REQUEST_ACCOUNT_FAILED,
        payload: msg
    }
};

export const updateData = (data) => {
    return {
        type: actionTypes.UPDATE_DATA,
        payload: data
    }
};

export const getTerm = () => ({
    type: actionTypes.GET_TERM,
});
export const getTermSuccess = (data) => ({
    type: actionTypes.GET_TERM_SUCCESS,
    payload: data
});
export const getTermError = (error) => ({
    type: actionTypes.GET_TERM_ERROR,
    payload: error
});


export const getPersonalInfor = () => ({
    type: actionTypes.GET_PERSONAL_INFORMATION,
});
export const getPersonalInforSuccess = (data) => ({
    type: actionTypes.GET_PERSONAL_INFORMATION_SUCCESS,
    payload: data
});
export const getPersonalInforError = (error) => ({
    type: actionTypes.GET_PERSONAL_INFORMATION_ERROR,
    payload: error
});

export const updateDataConfirm = (data) => ({
    type: actionTypes.UPDATE_DATA_CONFIRM,
    payload: data
})

export const onChangeData = (data) => ({
    type: actionTypes.CHANGE_DATA,
    payload: data
})

export const resetRequestAccount = () => ({
    type: actionTypes.RESET_REQUEST_ACCOUNT
})