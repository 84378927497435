import {
    FETCH_ACCOUNT_DETAIL, FETCH_ACCOUNT_SUCCESS
} from "./actionType";

const DEFAULT_STATE = {
    account_detail: null
};

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_ACCOUNT_DETAIL:
            return {
                ...state
            }
        case FETCH_ACCOUNT_SUCCESS:
            return {
                ...state,
                account_detail: action.payload
            }
        default:
            return state;
    }
}
