import {
    FETCH_USAGE_HISTORY,
    FETCH_USAGE_HISTORY_SUCCESS,
    FETCH_USAGE_HISTORY_ERROR
} from './actionType';

export function fetchUsageHistory(history) {
    return {
        type: FETCH_USAGE_HISTORY,
        payload: history
    }
}

export function fetchUsageHistorySuccess(data) {
    return {
        type: FETCH_USAGE_HISTORY_SUCCESS,
        payload: data
    }
}

export function fetchUsageHistoryError(message) {
    return {
        type: FETCH_USAGE_HISTORY_ERROR,
        payload: message
    }
}