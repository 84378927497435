import {
    FETCHING,
    FETCHING_VERSION,
    FETCH_CLASSIFICATION,
    FETCH_CLASSIFICATION_SUCCESS,
    FETCH_CLASSIFICATION_ERROR,
    FETCH_SOFTWARE,
    FETCH_SOFTWARE_SUCCESS,
    FETCH_SOFTWARE_ERROR,
    FETCH_SOFTWARE_VERSION,
    FETCH_SOFTWARE_VERSION_SUCCESS,
    FETCH_SOFTWARE_VERSION_ERROR,
    NEXT_STEP_USE_APPLICATION,
    CLEAR_LIST_SOFTWARE_VERSION,
    CHANGE_CLASSIFICATION,
    CHANGE_PROCESSOR,
    ADD_SOFTWARE_ACTIVE,
    UPDATE_SEARCH_KEYWORD_SOFTWARE,
    REMOVE_SOFTWARE_ACTIVE,
    CLEAR_LIST_SOFTWARE_VERSION_SUCCESS,
    UPDATE_SOFTWARE_VERSION,
    LOADING_LIST_SOFTWARE
} from './actionType';

import { CLEAR_ESTIMATE } from '../../redux/actionType/nextStepActionType';

const DEFAULT_STATE = {
    classification_data: [
        {
            classification_code: '',
            classification_name: 'ALL Application'
        }
    ],
    classificationActive: 0,
    softwares: [],
    softwareVersion: [],
    isLoading: false,
    isLoadingListSoftware: false,
    listActiveSoftware: [],
    listActiveSoftwareCode: [],
    listSoftwareVersion: [],
    searchForm: {
        processor: 'cpu',
        classification_code: '',
        search_keyword: '',
        // page: 1
    }
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCHING:
            return {
                ...state,
                isLoading: action.payload
            }
        case LOADING_LIST_SOFTWARE:
            return {
                ...state,
                isLoadingListSoftware: action.payload
            }
        case FETCHING_VERSION:
            return {
                ...state,
                isLoadingVersion: action.payload,
            }
        case FETCH_CLASSIFICATION_SUCCESS:
            action.payload.unshift({ lassification_code: '', classification_name: 'use application.ALL Application'.t() });
            return {
                ...state,
                classification_data: [...action.payload]
            }
        case FETCH_CLASSIFICATION_ERROR:
            return {
                ...state,
            }
        case FETCH_SOFTWARE_SUCCESS:
            let newListActive =  state.listActiveSoftware;
            if(newListActive.length > 0) {
                newListActive = newListActive.map(item => {
                    let swConvert = action.payload.find(sw => sw.software_code === item.software_code);
                    console.log({swConvert, item});
                    return {
                        ...item,
                        // supplement: swConvert?.supplement ? swConvert?.supplement : item?.supplement
                    }
                })
            }
            console.log({newListActive})
            return {
                ...state,
                softwares: action.payload,
                listActiveSoftware: newListActive
            }
        case FETCH_SOFTWARE_ERROR:
            return {
                ...state
            }
        case FETCH_SOFTWARE_VERSION:
            return {
                ...state,
            }
        case FETCH_SOFTWARE_VERSION_SUCCESS:
            return {
                ...state,
                softwareVersion: [...state.softwareVersion].concat(action.payload),
            }
        case FETCH_SOFTWARE_VERSION_ERROR:
            return {
                ...state,
            }
        case NEXT_STEP_USE_APPLICATION:
            return {
                ...state,
                listActiveSoftware: action.payload.listActiveSoftware,
                listActiveSoftwareCode: action.payload.listActiveSoftwareCode,
                listSoftwareVersion: action.payload.listSoftwareVersion
            }
        case CLEAR_LIST_SOFTWARE_VERSION_SUCCESS:
            return {
                ...state,
                softwareVersion: [],
                listActiveSoftware: [].concat(action.payload),
                listActiveSoftwareCode: [].concat(action.payload.software_code),
                listSoftwareVersion: [].concat(action.payload.software_code),
            }
        case CHANGE_CLASSIFICATION:
            let { classification_code } = state.classification_data[action.payload]
            return {
                ...state,
                classificationActive: action.payload,
                searchForm: {
                    ...state.searchForm,
                    classification_code: classification_code
                }
            }
        case CHANGE_PROCESSOR:
            return {
                ...state,
                searchForm: { ...state.searchForm, processor: action.payload }
            }
        case ADD_SOFTWARE_ACTIVE:
            const newListActiveSoftware = [...state.listActiveSoftware].concat(action.payload).map(item => {
                return {...item}
            }) 
            console.log({newListActiveSoftware});
            return {
                ...state,
                // listActiveSoftware: [...state.listActiveSoftware].concat(action.payload),
                listActiveSoftware: newListActiveSoftware,
                listActiveSoftwareCode: [...state.listActiveSoftwareCode].concat(action.payload.software_code),
                listSoftwareVersion: [...state.listSoftwareVersion].concat(action.payload.software_code)
            }
        case REMOVE_SOFTWARE_ACTIVE:
            return {
                ...state,
                listActiveSoftware: [...state.listActiveSoftware].filter(item => item.software_code !== action.payload.software_code),
                listActiveSoftwareCode: [...state.listActiveSoftwareCode].filter(item => item !== action.payload.software_code),
            }
        case UPDATE_SEARCH_KEYWORD_SOFTWARE:
            return {
                ...state,
                searchForm: { ...state.searchForm, search_keyword: action.payload }
            }
        case UPDATE_SOFTWARE_VERSION:
            let software = action.payload;
            const listActive = [...state.listActiveSoftware];
            listActive.forEach(item => {
                if (item.software_code === software.software_code) {
                    item = software
                }
            })
            return {
                ...state,
                listActiveSoftware: listActive
            }
        case CLEAR_ESTIMATE:
            const language = localStorage.getItem('LANGUAGE');
            return {
                ...state,
                classification_data: [
                    {
                        classification_code: '',
                        classification_name: language === 'GB' ? 'ALL Application' : '全てのアプリケーション'
                    }
                ],
                classificationActive: 0,
                softwares: [],
                softwareVersion: [],
                isLoading: false,
                isLoadingListSoftware: false,
                listActiveSoftware: [],
                listActiveSoftwareCode: [],
                listSoftwareVersion: [],
                searchForm: {
                    processor: 'cpu',
                    classification_code: '',
                    search_keyword: '',
                    // page: 1
                }
            }
        default:
            return state;
    }
}
