import React from "react";
import Alert from "react-s-alert";
import { withRouter } from "react-router-dom";
import { CONST } from "../../config/const";
import { Utils } from "../../config/utils";
import "./style.css";
import { API } from "../../services/APIs";
import { httpService } from "../../services/httpService";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      keyword: "",
      password: "",
      confirm_password: "",
    };
  }

  componentWillMount() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  handleForgotPassword = async (e) => {
    e.preventDefault();
    const { email, keyword, password, confirm_password } = this.state;
    if (!email && !keyword && !password) {
      Alert.warning(
        "notification.Please enter your email and keyword and password and confirm password".t(),
        {
          timeout: 5000,
        }
      );
    } else if (!email) {
      Alert.warning("notification.please enter your email address".t(), {
        timeout: 5000,
      });
    } else if (!keyword) {
      Alert.warning("notification.please enter keyword".t(), {
        timeout: 5000,
      });
    } else if (!password) {
      Alert.warning("notification.please enter new password".t(), {
        timeout: 5000,
      });
    } else if (password !== confirm_password) {
      Alert.warning(
        "notification.The password and confirm password do not match".t(),
        {
          timeout: 5000,
        }
      );
    } else if (password.length < 8) {
      Alert.warning("notification.the password incorect format".t(), {
        timeout: 5000,
      });
    } else {
      try {
        const response = await httpService.postWithoutToken(
          API.POST_FORGOT_PASSWORD,
          { user_code: email, keyword, password, confirm_password }
        );
        if (response.status === "success") {
          Alert.success(response.message, {
            timeout: 5000,
          });
        } else {
          Alert.warning(response.message, {
            timeout: 5000,
          });
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { fetching } = this.props;
    return (
      <div className="login">
        <Alert stack={{ limit: 1 }} />
        <div className="banner-login">
          <div className="content">
            {/* <h3>Run your application and distribute it</h3>
            <p>
              Let your application run on the cloud, on your servers and
              distribute it to others. Analysis of the result in the same place.
            </p>
            <img
              src={require("../../assets/images/logo/login_logo.png")}
              alt=""
              className="img-fluid sign-in"
            /> */}
            <img
              src={require("../../assets/images/logo/login_logo1.jpg")}
              alt=""
              className="img-fluid sign-in"
            />
          </div>
        </div>
        <div className="login-wrapper forgot-wrapper">
          <div className="sign-in-wrapper">
            <div className="sign-in">
              <div className="logo">
                {/* <h1 className="login-title">Science Cloud</h1>
                <div className="login-sub-title">
                  Plaform to create the future
                </div> */}
                <img
                  className="img-fluid"
                  src={require("../../assets/images/logo/HPCS_logo.png")}
                  alt=""
                />
              </div>
              <nav className="nav nav-tabs tabs-login">
                <a
                  className="tab-link active-tab"
                  id="login-tab"
                  data-toggle="tab"
                  style={{ cursor: "default" }}
                  href="#login"
                >
                  Forgot password
                </a>
              </nav>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="login">
                  <form
                    onSubmit={this.handleForgotPassword}
                    method={"post"}
                    noValidate
                  >
                    <div className="form-group text-box">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        id="email"
                        placeholder="Email address"
                        onChange={(text) =>
                          this.setState({ email: text.target.value })
                        }
                      />
                      <div className="invalid-feedback feedback-pos">
                        Please input valid email.
                      </div>
                    </div>
                    <div className="form-group text-box">
                      <input
                        type="text"
                        name="keyword"
                        className="form-control"
                        id="keyword"
                        placeholder="Keywords for re-application"
                        onChange={(text) =>
                          this.setState({ keyword: text.target.value })
                        }
                      />
                      <div className="invalid-feedback feedback-pos">
                        Please input valid keyword.
                      </div>
                    </div>
                    <div className="form-group text-box">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        id="password"
                        placeholder="New password"
                        onChange={(text) =>
                          this.setState({ password: text.target.value })
                        }
                      />
                    </div>
                    <div className="form-group text-box">
                      <input
                        type="password"
                        name="confirm-password"
                        className="form-control"
                        id="confirm-password"
                        placeholder="New password (confirmation)"
                        onChange={(text) =>
                          this.setState({ confirm_password: text.target.value })
                        }
                      />
                    </div>
                    <div className="form-group text-box tw-buttons">
                      <button className="btn btn-default" type="submit">
                        Send E-mail
                      </button>
                      <button
                        className="btn btn-default btn-back"
                        onClick={this.goBack}
                        type="button"
                      >
                        Back
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center", paddingBottom: 10, paddingTop: 30 }}>
            <img
              className="img-fluid"
              src={require("../../assets/images/logo/HPCS_logo1.png")}
              alt=""
            />
          </div>
          <footer className="main-footer">
            <div>
              <a
                className="ft-item"
                href="http://www.hpc.co.jp/aboutus.html"
                target="_blank"
              >
                {"footer.about us".t()}
              </a>
              <span className="separator">|</span>
              <a className="ft-item" href="#" target="_blank">
                &nbsp;{"footer.privacy policy".t()}
              </a>
              <span className="separator hide-mobile">|</span>
              <address>
                &nbsp;{"footer.copyright".t()}{" "}
                <a href="http://www.hpc.co.jp" target="_blank">
                  HPC SYSTEMS Inc.
                </a>
                &nbsp;{"footer.all rights reserved".t()}
              </address>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPassword);
