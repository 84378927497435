import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from 'react-i18next';

import transVi from '../src/locales/vi.json';
import transJp from '../src/locales/jp.json';
import transEn from '../src/locales/en.json';

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        lng: "GB",
        fallbackLng: "GB", // use en if detected lng is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        resources: {
            VI: {
                translations: transVi,
            },
            GB: {
                translations: transEn,
            },
            JP: {
                translations: transJp,
            },
        },
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",
        react: {
            wait: true,
        },
    });

export function _t(name, params = {}) {
    return i18n.t(name, params)
}

export default i18n;
