import React from 'react';

class Loading extends React.Component {
    render() {
        return (
            <p>Loading component</p>
        );
    }
}

export default Loading;