import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const mapStateToProps = state => ({
    title: state.NextStepReducer.title,
    isAdmin: state.LoginReducer.isAdmin,
    isLogin: state.LoginReducer.isLogin,
    currentStep: state.NextStepReducer.currentStep,
    language: state.NextStepReducer.language,
    isLoadingNextStep: state.NextStepReducer.isLoadingNextStep,
    onEstimate: state.NextStepReducer.onEstimate
});

const mapDispatchToProps = dispatch => ({
    // changeTitle: (title) => dispatch(changeTitle(title)),
    // changeRoute: (route) => dispatch(changeRoute(route)),
    // toggleIsFullSidebar: () => dispatch(toggleIsFullSidebar())
});

const StepRoute = connect(mapStateToProps, mapDispatchToProps)(
    ({ component: Component, ...rest }) => {
        const redirect = (props) => {
            if (rest.isAdmin) {
                return props.history.push('/history-inquiry-admin');
            } else {
                return props.history.push('/');
            }
        };

        let checkStep = () => {
            return rest.currentStep >= rest.stepComponent.step
        }

        let checkLogin = () => {
            return rest.stepComponent.isLogin === rest.isLogin
        }

        let checkEstimate = () => {
            if (rest.path !== '/estimate' && rest.onEstimate === true) {
                return true;
            }
            return false;
        }

        let redirectEstimate = (props) => {
            return props.history.push('/estimate')
        }

        return (
            <Route
                {...rest}
                render={props => {
                    if (checkStep() && checkLogin() && rest.isAdmin === false) {
                        return <Component {...props} {...rest} />;
                    } else {
                        redirect(props);
                    }
                    // if (checkEstimate()) {
                    //     redirectEstimate(props)
                    // } else {
                    //     if (checkStep() && checkLogin() && rest.isAdmin === false) {
                    //         return <Component {...props} {...rest} />;
                    //     } else {
                    //         redirect(props);
                    //     }
                    // }
                }}
            />
        )
    }
)



export default StepRoute
