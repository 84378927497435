import {
    FETCH_USAGE_HISTORY,
    FETCH_USAGE_HISTORY_SUCCESS,
    FETCH_USAGE_HISTORY_ERROR
} from './actionType';

const DEFAULT_STATE = {
    usage_history_data: [],
}

export default (state = DEFAULT_STATE, action) => {
    switch (action.type) {
        case FETCH_USAGE_HISTORY:
            return {
                ...state
            }
        case FETCH_USAGE_HISTORY_SUCCESS:
            return {
                ...state,
                usage_history_data: action.payload
            }
        case FETCH_USAGE_HISTORY_ERROR:
            return {
                ...state
            }
        default:
            return state;
    }
}