import React from 'react';
import { CONST } from "../config/const";
import i18n from '../../src/i18n';
import { userSideUp, userSideDown, adminRoute, userSideUpLogin } from '../constant/step-component';
import { Utils } from '../config/utils';
import { changeLanguage, toggleIsFullSidebar, getLanguage, clearEstimate } from "../redux/action/nextStepAction";
import { connect } from "react-redux";
// import { httpService } from '../services/httpService';
// import { API } from '../services/APIs';

// const HPC_LOGO = require('../assets/images/logo/HPC_logo.png');
// const ICON_TRANSLATE = require('../assets/images/sidebar/icon_translate.png');
// const FAV_ICON = require("../assets/images/icon");


const OptionLanguage = ({ onChangeValue, languages }) => {
    let languageSelect = localStorage.getItem(CONST.LANGUAGE);
    // const languages = CONST.I18N_LANGUAGE;
    return languages.map((item, index) => (
        <a className={`dropdown-item ${languageSelect === item.value ? 'active' : ''}`} onClick={() => onChangeValue(item.value_code)} key={index}>
            <span className="language-title color-dark fs-14 f_regular">{item.value}</span>
            <img src={`../assets/images/sidebar/${item.value_code}.png`} alt="" />
        </a>
    ))
}


class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: '',
            title: ''
        }
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
    }

    async componentDidMount() {
        await this.props.getLanguage();
        let language = localStorage.getItem(CONST.LANGUAGE);
        if (language === null) {
            this.handleChangeLanguage('GB')
        }
    }

    handleChangeLanguage(language) {
        i18n.changeLanguage(language);
        localStorage.setItem(CONST.LANGUAGE, language);
        this.props.onChangeLanguage(language)
    }

    logOut = () => {
        this.props.clearEstimate();
        Utils.logOut(this.props.history);
    }

    toggleFullSideBar = (e) => {
        e.preventDefault();
        this.props.toggleFullSideBar();
    }

    render() {
        let user_name = sessionStorage.getItem(CONST.USER_NAME);
        let { isAdmin, history, isLogin, location, isFullSideBar } = this.props;
        let language = localStorage.getItem(CONST.LANGUAGE);
        // let selected_language = CONST.I18N_LANGUAGE.filter(e => e.value === language);
        let selected_language = this.props.languages.filter(e => e.value_code === language);
        const lang = selected_language !== undefined && selected_language.length > 0 ? selected_language[0].value : '';
        const icon_lang = selected_language !== undefined && selected_language.length > 0 ? `../assets/images/sidebar/${selected_language[0].value_code}.png` : '';
        let title;
        let { pathname } = location;
        if (isAdmin) {
            title = adminRoute.filter(item => item.currentRoute === pathname)[0]?.currentTitle;
        } else if(isLogin) {
            title = userSideUpLogin.concat(userSideDown).filter(item => item.currentRoute === pathname)[0]?.currentTitle;
        } else {
            title = userSideUp.filter(item => item.currentRoute === pathname)[0]?.currentTitle;
        }
        const _title = `header.${title}`.t();
    
        console.log({language});

        return (
            <div className={`${isFullSideBar ? '' : 'disable-menu'}`}>
                <header className="header">
                    <div className="header-container content-center justify-content-between">
                        <div className="nav-left">
                            <ul className="nav">
                                <li className="nav-item icons">
                                    <span className="icon-close-menu fs-24">
                                        {/* <span onClick={this.toggleFullSideBar}
                                            className={`check fas fa-bars color-dark desktop ${isFullSideBar ? 'icon-close' : ''}`} /> */}
                                        <span onClick={this.toggleFullSideBar}
                                            className={`check fas fa-bars color-dark desktop icon-open`} />
                                        <span onClick={this.toggleFullSideBar}
                                            className={`check fas fa-bars color-dark desktop icon-close`} />
                                    </span>
                                </li>
                                <li className="nav-item title">
                                    <span id="page-title" className="fs-20 color-primary f_semibold text-uppercase d-none d-md-inline-block">{_title}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="nav-right">
                            <ul className="nav">
                                <li className="nav-item language dropdown content-center">
                                    <a className="nav-link dropdown-toggle p-0 color-dark text-uppercase" href="" id="navbardrop" data-toggle="dropdown">
                                        <span className="language-title color-dark fs-14 f_regular">{lang}</span>
                                        <img src={icon_lang} alt="" />
                                    </a>
                                    <div className="dropdown-menu p-0 dropdown-menu-language">
                                        <OptionLanguage
                                            onChangeValue={this.handleChangeLanguage}
                                            languages={this.props.languages}
                                        />
                                    </div>
                                </li>
                                {/* <li className="nav-item icon-headset fs-24 content-center">
                                <a href="#" className="color-dark" title="Contact">
                                    <span className="fas fa-headset"/>
                                </a>
                            </li>
                            <li className="nav-item icon-bell fs-24  content-center">
                                <a href="#" className="color-dark" title="Notification">
                                    <span className="far fa-bell"/>
                                    <span className="badge badge-success small .f_regular fs-12">23</span>
                                </a>
                            </li> */}
                                {isLogin === true ?
                                    <li className="nav-item avatar content-center">
                                        <a className="avater-inner nav-link dropdown-toggle p-0" id="navbardrop"
                                            data-toggle="dropdown">
                                            {/* <img className="dst" src={HPC_LOGO} alt=""/>
                                            <img className="mb" src={FAV_ICON} alt=""/> */}
                                            <span>{language === 'JP' ? user_name?.split(' ')?.reverse()?.join(' ') : user_name}</span>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right p-0">
                                            {/* <a href="#" className="dropdown-item">
                                            <span className="icon setting fas fa-cog"/>
                                            <span className="color-dark fs-14">Setting</span>
                                        </a>
                                        <a href="#" className="dropdown-item">
                                            <span className="icon setting far fa-user"/>
                                            <span className="color-dark fs-14">Profile</span>
                                        </a>
                                        <a href="#" className="dropdown-item">
                                            <span className="icon setting far fa-envelope"/>
                                            <span className="color-dark fs-14">Message</span>
                                        </a> */}
                                            <a onClick={this.logOut}
                                                className="dropdown-item">
                                                <span className="icon setting fas fa-power-off" />
                                                <span className="color-dark fs-14">&nbsp;Logout</span>
                                            </a>
                                        </div>
                                    </li> :
                                    <li></li>
                                }
                            </ul>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isLogin: state.LoginReducer.isLogin,
    isFullSideBar: state.NextStepReducer.isFullSideBar,
    title: state.NextStepReducer.title,
    isAdmin: state.LoginReducer.isAdmin,
    isLogin: state.LoginReducer.isLogin,
    currentStep: state.NextStepReducer.currentStep,
    language: state.NextStepReducer.language,
    isLoadingNextStep: state.NextStepReducer.isLoadingNextStep,
    languages: state.NextStepReducer.languages
})

const mapDispatchToProps = dispatch => ({
    onChangeLanguage: (language) => dispatch(changeLanguage(language)),
    toggleFullSideBar: () => dispatch(toggleIsFullSidebar()),
    getLanguage: () => dispatch(getLanguage()),
    clearEstimate: () => dispatch(clearEstimate())
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
